import Colors from '../../palette/Colors';

/**
 * `Accordion` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/accordion-details/}
 */
const MuiAccordionDetails = Object.freeze({
  styleOverrides: {
    root: {
      backgroundColor: Colors.GreyMain,
      paddingBottom: 10,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 10,
      position: 'relative',
      zIndex: 1,
    },
  },
});

export default MuiAccordionDetails;
