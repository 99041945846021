import {
  FETCH_CURRENT_SUBMISSION_SAGA,
  SET_CURRENT_SUBMISSION,
  SET_CURRENT_SUBMISSION_ID,
  ADD_SUBMISSION_ITEM_SAGA,
  EDIT_SUBMISSION_ITEM_SAGA,
  CREATE_SUBMISSION_CHECKOUT_SAGA,
  SET_SUBMISSION_CHECKOUT_ID,
  APPLY_SUBMISSION_PROMO_CODE_SAGA,
  UPDATE_SUBMISSION_DELIVERIES_SAGA,
  SET_SUBMISSION_TERMS,
  FETCH_SUBMISSION_TERMS_SAGA,
  SET_SUBMISSION_POTENTIAL_REWARDS,
  FETCH_SUBMISSION_POTENTIAL_REWARDS_SAGA,
  SET_SUBMISSION_FOCUS_ITEM_ID,
  CLEAR_SUBMISSION_FOCUS_ITEM_ID,
  SET_SUBMISSION_ERRORS,
} from 'utils/constants/submission';

/**
 * Action that trigger a saga to add currenly opened shopping experience to existed submission
 * or create a new submission.
 */
export const addSubmissionItemSaga = (redirect) => ({
  redirect,
  type: ADD_SUBMISSION_ITEM_SAGA,
});

/**
 * Action that trigger a saga to save currenly opened shopping experience with existed submission items.
 */
export const editSubmissionItemSaga = (redirect) => ({
  redirect,
  type: EDIT_SUBMISSION_ITEM_SAGA,
});

export const setSubmissionErrors = (payload) => ({
  payload,
  type: SET_SUBMISSION_ERRORS,
});

export const fetchCurrentSubmissionSaga = (submissionId) => ({
  submissionId,
  type: FETCH_CURRENT_SUBMISSION_SAGA,
});

export const setCurrentSubmission = (payload) => ({
  payload,
  type: SET_CURRENT_SUBMISSION,
});

export const setCurrentSubmissionId = (payload) => ({
  payload,
  type: SET_CURRENT_SUBMISSION_ID,
});

export const createSubmissionCheckoutSaga = () => ({
  type: CREATE_SUBMISSION_CHECKOUT_SAGA,
});

export const setSubmissionCheckoutId = (payload) => ({
  payload,
  type: SET_SUBMISSION_CHECKOUT_ID,
});

export const applySubmissionPromoCodeSaga = (code) => ({
  code,
  type: APPLY_SUBMISSION_PROMO_CODE_SAGA,
});

export const updateSubmissionDeliveriesSaga = (gaEvent) => ({
  gaEvent,
  type: UPDATE_SUBMISSION_DELIVERIES_SAGA,
});

export const fetchSubmissionTerms = () => ({
  type: FETCH_SUBMISSION_TERMS_SAGA,
});

export const setSubmissionTerms = (payload) => ({
  payload,
  type: SET_SUBMISSION_TERMS,
});

export const setSubmissionPotentialRewards = (payload) => ({
  payload,
  type: SET_SUBMISSION_POTENTIAL_REWARDS,
});

export const fetchSubmissionPotentialRewardsSaga = () => ({
  type: FETCH_SUBMISSION_POTENTIAL_REWARDS_SAGA,
});

export const setSubmissionFocusItemId = (payload) => ({
  payload,
  type: SET_SUBMISSION_FOCUS_ITEM_ID,
});

export const clearSubmissionFocusItemId = () => ({
  type: CLEAR_SUBMISSION_FOCUS_ITEM_ID,
});
