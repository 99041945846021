import { SET_CURRENT_SHOPPING_EXPERIENCE_EXTERNAL_ID,
  SET_CURRENT_SHOPPING_EXPERIENCE_PRODUCT_ID,
  SET_CURRENT_SHOPPING_EXPERIENCE_CATEGORY,
  SET_CURRENT_SHOPPING_EXPERIENCE_CURRENT_STEP,
  CLEAR_CURRENT_SHOPPING_EXPERIENCE } from 'utils/constants';

export const setCurrentShoppingExperienceExternalId = (payload) => ({
  payload,
  type: SET_CURRENT_SHOPPING_EXPERIENCE_EXTERNAL_ID,
});

export const setCurrentShoppingExperienceProductId = (payload) => ({
  payload,
  type: SET_CURRENT_SHOPPING_EXPERIENCE_PRODUCT_ID,
});

export const setCurrentShoppingExperienceCategory = (payload) => ({
  payload,
  type: SET_CURRENT_SHOPPING_EXPERIENCE_CATEGORY,
});

export const setCurrentShoppingExperienceCurrentStep = (payload) => ({
  payload,
  type: SET_CURRENT_SHOPPING_EXPERIENCE_CURRENT_STEP,
});

export const clearCurrentShoppingExperience = () => ({
  type: CLEAR_CURRENT_SHOPPING_EXPERIENCE,
});
