import { call, put, takeEvery, select } from 'redux-saga/effects';

import { setGoogleAnalyticsCheckoutEvent } from 'src/app/actions/googleAnalyticsActions';
import { setLoadingById } from 'src/app/actions/loadingActions';
import { setSubmissionCheckoutId } from 'src/app/actions/submissionActions';
import { selectSubmissionId } from 'src/app/selectors/submissionSelectors';
import { createSubmissionCheckout } from 'src/services/submissionsApi';
import { EVENT_BEGIN_CHECKOUT } from 'utils/constants/googleAnalytics';
import { CREATE_SUBMISSION_CHECKOUT_LOADING, CREATE_SUBMISSION_CHECKOUT_SAGA } from 'utils/constants/submission';

/**
 * Saga to create submission checkout.
 * This saga is required for Billing page. It shouldn't be called earlier.
 */
export function* makeFetch() {
  try {
    yield put(setLoadingById(CREATE_SUBMISSION_CHECKOUT_LOADING, true));
    const submissionId = yield select(selectSubmissionId);
    const { data } = yield call(createSubmissionCheckout, submissionId);

    const { checkoutId } = data;

    yield put(setSubmissionCheckoutId(checkoutId));
    yield put(setGoogleAnalyticsCheckoutEvent(EVENT_BEGIN_CHECKOUT));
  } catch (error) {
    // TODO: add error handling
  } finally {
    yield put(setLoadingById(CREATE_SUBMISSION_CHECKOUT_LOADING, false));
  }
}

export default function* watchFetch() {
  yield takeEvery(CREATE_SUBMISSION_CHECKOUT_SAGA, makeFetch);
}
