import { themeWithBreakpoints } from '../breakpoints';

/**
 * `IconButton` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/icon-button/}
 */
const MuiIconButton = Object.freeze({
  defaultProps: {
    color: 'primary',
    size: 'small',
  },
  styleOverrides: {
    root: {
      '& svg': {
        height: '1em',
        width: '1em',
      },
    },
    sizeLarge: {
      fontSize: 34,
    },
    sizeMedium: {
      fontSize: 28,
      [themeWithBreakpoints.breakpoints.down('lg')]: {
        fontSize: 19,
      },
    },
    sizeSmall: {
      fontSize: 21,
    },
  },
});

export default MuiIconButton;
