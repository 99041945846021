import Colors from '../../palette/Colors';

/**
 * `OutlinedInput` component theme overrides.
 *  `OutlinedInput` is a part of `TextField` composition with `variant="outlined"`.
 *
 * @see {@link https://mui.com/material-ui/api/outlined-input/}
 */
const MuiOutlinedInput = Object.freeze({
  styleOverrides: {
    adornedStart: {
      '& .MuiInputBase-input': {
        backgroundColor: Colors.White,
        borderLeftStyle: 'solid',
        borderLeftWidth: 1,
      },
      '&.Mui-error': {
        '& .MuiInputBase-input': {
          borderLeftColor: Colors.RedMain,
          color: Colors.RedMain,
        },
      },
      '&.Mui-focused .MuiInputBase-input': {
        borderLeftWidth: 2,
        marginLeft: -1,
      },
      '&.MuiInputBase-colorPrimary': {
        '& .MuiInputBase-input': {
          borderLeftColor: Colors.CyanBlueLight1,
          color: Colors.CyanBlueMain,
        },
        '&.Mui-focused': {
          '& .MuiInputBase-input': {
            borderLeftColor: Colors.PurpleMain,
          },
          // It's the only way to target an icon inside a Select component with .Mui-focused modificator
          '& .MuiSelect-icon': {
            borderColor: Colors.PurpleMain,
            color: Colors.PurpleMain,
          },
        },
        '&:hover .MuiInputBase-input': {
          borderLeftColor: Colors.PurpleMain,
        },
      },
      '&.MuiInputBase-colorSecondary': {
        '& .MuiInputBase-input': {
          borderLeftColor: Colors.CyanBlueDark,
        },
      },
      '&:-webkit-autofill': {
        borderBottomLeftRadius: 'initial',
        borderTopLeftRadius: 'initial',
      },
      backgroundColor: Colors.CyanBlueLight2,
    },
    colorSecondary: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.CyanBlueMain,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.CyanBlueDark,
      },
      color: Colors.CyanBlueMain,
    },
    input: {
      paddingBottom: 20,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 20,
    },
    multiline: {
      borderRadius: 30,
    },
    root: {
      '& .MuiOutlinedInput-input': {
        '&::placeholder': {
          color: Colors.CyanBlueMain,
        },
        color: Colors.CyanBlueMain,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.CyanBlueLight1,
        paddingLeft: 24,
        paddingRight: 24,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: Colors.PurpleMain,
      },
      borderRadius: 1000,
      color: Colors.PurpleMain,
    },
  },
});

export default MuiOutlinedInput;
