import { call, put, select, takeEvery, cancel } from 'redux-saga/effects';

import { removeFeedback, setFeedback } from 'src/app/actions/feedbackActions';
import { setGoogleAnalyticsCheckoutEvent } from 'src/app/actions/googleAnalyticsActions';
import { setLoadingById } from 'src/app/actions/loadingActions';
import { setCurrentSubmission } from 'src/app/actions/submissionActions';
import { selectSubmissionId } from 'src/app/selectors/submissionSelectors';
import { applySubmissionPromoCode, fetchSubmission } from 'src/services/submissionsApi';
import { COUPON_COOKIE_NAME } from 'utils/constants';
import { FEEDBACK_LEVEL_ERROR,
  FEEDBACK_LEVEL_SUCCESS,
  SUBMISSION_PROMOTION_FEEDBACK_ID } from 'utils/constants/feedback';
import { EVENT_APPLY_PROMOTION } from 'utils/constants/googleAnalytics';
import { APPLY_SUBMISSION_PROMO_CODE_LOADING, APPLY_SUBMISSION_PROMO_CODE_SAGA } from 'utils/constants/submission';
import { setCookies } from 'utils/cookies';

/**
 * Saga to apply a promo code to an existed submission.
 * * If promo code is applied successfully, it makes an additional request to get current submission data.
 * Then it updates submission general information only, due to only charges data should be updated.
 * * If promo code isn't applied or error occured, update a feedback message.
 * @param {Object} action - redux action
 * @param {string} action.type - APPLY_SUBMISSION_PROMO_CODE_SAGA
 * @param {string} action.code - promo code
 */
export function* makeFetch(action) {
  try {
    yield put(removeFeedback(SUBMISSION_PROMOTION_FEEDBACK_ID));
    yield put(setLoadingById(APPLY_SUBMISSION_PROMO_CODE_LOADING, true));
    const { code } = action;

    if (!code) {
      yield put(setFeedback(SUBMISSION_PROMOTION_FEEDBACK_ID, FEEDBACK_LEVEL_ERROR, 'Please enter promo code'));
      yield cancel();
    }
    const submissionId = yield select(selectSubmissionId);
    const { data: promotion } = yield call(applySubmissionPromoCode, submissionId, code);

    if (promotion && promotion.applicable) {
      const { data: submission } = yield call(fetchSubmission, submissionId);
      yield put(setCurrentSubmission(submission)); // Only charges and general information are required to be updated.

      yield put(setFeedback(SUBMISSION_PROMOTION_FEEDBACK_ID, FEEDBACK_LEVEL_SUCCESS, promotion.message));
      yield put(setGoogleAnalyticsCheckoutEvent(EVENT_APPLY_PROMOTION, null, { coupon: code }));
      yield call(setCookies, COUPON_COOKIE_NAME, code);
    } else {
      yield put(setFeedback(SUBMISSION_PROMOTION_FEEDBACK_ID, FEEDBACK_LEVEL_ERROR, promotion.message));
    }
  } catch (error) {
    const message = error.response?.data?.errors?.errors[0] || '';

    yield put(setFeedback(SUBMISSION_PROMOTION_FEEDBACK_ID, FEEDBACK_LEVEL_ERROR, message));
  } finally {
    yield put(setLoadingById(APPLY_SUBMISSION_PROMO_CODE_LOADING, false));
  }
}

export default function* watchFetch() {
  yield takeEvery(APPLY_SUBMISSION_PROMO_CODE_SAGA, makeFetch);
}
