import { call, put, takeLatest } from 'redux-saga/effects';

import { setCategoriesData } from 'src/app/actions/customizationPhysicalActions';
import { getAllCategories } from 'src/services/customizationApi';
import { GET_ALL_CATEGORIES_SAGA } from 'utils/constants';

function* makeFetch() {
  try {
    const response = yield call(getAllCategories);
    yield put(setCategoriesData(response.data._embedded.items));
  } catch (e) {
    // TODO: Add error handling
  } finally {
    // TODO: Add loading
  }
}

export default function* watchGetAllCategories() {
  yield takeLatest(GET_ALL_CATEGORIES_SAGA, makeFetch);
}
