import axios from 'axios';

class AxiosService {
  #instance;

  #config;

  constructor() {
    this.#config = {
      baseURL: window.location.origin,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    this.#instance = axios.create(this.#config);
  }

  #getRequestConfig(customConfig) {
    if (!customConfig) return this.#config;
    return {
      ...this.#config,
      ...customConfig,
      headers: {
        ...this.#config.headers,
        ...customConfig.headers,
      },
    };
  }

  get(url, config) {
    const requestConfig = this.#getRequestConfig(config);
    return this.#instance.get(url, requestConfig);
  }

  post(url, data, config) {
    const requestConfig = this.#getRequestConfig(config);
    return this.#instance.post(url, data, requestConfig);
  }

  put(url, data, config) {
    const requestConfig = this.#getRequestConfig(config);
    return this.#instance.put(url, data, requestConfig);
  }

  delete(url, config) {
    const requestConfig = this.#getRequestConfig(config);
    return this.#instance.delete(url, requestConfig);
  }
}

export default AxiosService;
