import Colors from '../../palette/Colors';

/**
 * `Card` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/card/}
 */
const MuiCard = Object.freeze({
  styleOverrides: {
    root: {
      backgroundColor: Colors.CyanBlueLight2,
      borderColor: Colors.GreyMain,
      borderRadius: 12,
      borderStyle: 'solid',
      borderWidth: 1,
      boxShadow: 'none',
    },
  },
});

export default MuiCard;
