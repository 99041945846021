/* eslint-disable sort-keys */
import Colors from '../palette/Colors';
import fonts from '../typography/fonts';

/**
 * `Alert` component theme overrides.
 *
 * @see {@link https://mui.com/joy-ui/api/alert/}
 */
const MuiAlert = Object.freeze({
  defaultProps: {
    role: 'status',
    variant: 'outlined',
    'aria-live': 'off',
  },
  styleOverrides: {
    root: {
      ...fonts.body1,
      alignItems: 'center',
      borderRadius: 0,
      paddingLeft: 19,
      paddingRight: 19,
      paddingTop: 11,
      paddingBottom: 11,
      '& .MuiAlert-icon': {
        fontSize: '34px',
      },
    },
    outlinedInfo: {
      borderColor: Colors.CyanBlueDark2,
      color: Colors.CyanBlueDark2,
      '& .MuiAlert-icon': {
        color: Colors.CyanBlueDark2,
      },
    },
    outlinedError: {
      borderColor: Colors.RedMain,
      color: Colors.RedMain,
      '& .MuiAlert-icon': {
        color: Colors.RedMain,
      },
    },
  },
});

export default MuiAlert;
