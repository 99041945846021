import {
  GET_ALL_CATEGORIES_SAGA,
  GET_STANDARD_GREETING_CARDS_SAGA,
  SET_CATEGORIES_DATA,
  SET_STANDARD_GREETING_CARDS_CATEGORY_DATA,
  SET_STANDARD_GREETING_CARDS_DATA,
  SET_STANDARD_GREETING_CARDS_SEARCH_DATA,
  CREATE_GREETING_CARD_DESIGN_SAGA,
  GET_GREETING_CARD_DETAILS_SAGA,
} from 'utils/constants';

export const getAllCategoriesSaga = () => ({
  type: GET_ALL_CATEGORIES_SAGA,
});

export const setCategoriesData = (payload) => ({
  payload,
  type: SET_CATEGORIES_DATA,
});

export const getStandardGreetingCardsSaga = (payload = {}) => ({
  payload,
  type: GET_STANDARD_GREETING_CARDS_SAGA,
});

export const setStandardGreetingCardsData = (payload) => ({
  payload,
  type: SET_STANDARD_GREETING_CARDS_DATA,
});

export const setStandardGreetingCardsCategoryData = (payload) => ({
  payload,
  type: SET_STANDARD_GREETING_CARDS_CATEGORY_DATA,
});

export const setStandardGreetingCardsSearchData = (payload) => ({
  payload,
  type: SET_STANDARD_GREETING_CARDS_SEARCH_DATA,
});

export const createGreetingCardDesign = (payload) => ({
  payload,
  type: CREATE_GREETING_CARD_DESIGN_SAGA,
});

export const getGreetingCardDetails = (payload) => ({
  payload,
  type: GET_GREETING_CARD_DETAILS_SAGA,
});
