import Colors from '../palette/Colors';

/**
 * `TableCell` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/table-cell/}
 */
const MuiTableCell = Object.freeze({
  defaultProps: {
    align: 'left',
    size: 'small',
  },
  styleOverrides: {
    head: {
      backgroundColor: Colors.GreyLight,
    },
    root: {
      borderColor: Colors.GreyMain,
      borderStyle: 'solid',
      borderWidth: 1,
      padding: 4,
    },
  },
});

export default MuiTableCell;
