/**
 * `Radio` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/radio/}
 */
const MuiRadio = Object.freeze({
  styleOverrides: {
    root: {
      '& .MuiSvgIcon-fontSizeMedium': {
        fontSize: 40,
      },
    },
  },
});

export default MuiRadio;
