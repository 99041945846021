import Colors from '../../palette/Colors';

/**
 * `StepConnector` component theme overrides/
 *
 * @see {@link https://mui.com/material-ui/api/step-connector/}
 */
const MuiStepConnector = Object.freeze({
  styleOverrides: {
    line: {
      borderColor: Colors.GreyDark,
      borderTopWidth: 3,
    },
  },
});

export default MuiStepConnector;
