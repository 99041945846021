import { select, takeEvery } from 'redux-saga/effects';

import { selectCurrentShoppingExperienceCategory } from 'src/app/selectors/currentShoppingExperienceSelectors';
import { selectCustomerDataDenomination,
  selectCustomerDataNetwork,
  selectCustomerDataObject,
  selectCustomerDataQuantity } from 'src/app/selectors/customerDataSelectors';
import { selectShoppingExperience } from 'src/app/selectors/shoppingExperiencesSelectors';
import { CURRENCY,
  CUSTOMER_DATA_FIELDS_DELIVERYDATE,
  GOOGLE_ANALYTICS_VIEW_ITEM_PDP,
  CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL,
  CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE,
  PDP_BRANDS } from 'src/utils/constants';
import { getDeliveryDate, getDeliveryMethods,
  getRelatedProductList } from 'src/utils/googleAnalytics';

/**
 * @param action redux action
 * @param action.type GOOGLE_ANALYTICS_VIEW_ITEM_PDP
 * @param action.eventType - view_item, add_to_cart
 * @param action.actionType - On click of read_more, read_less, select price, view_fees should trigger view_item
 * reference link https://developers.google.com/analytics/devguides/collection/ga4/reference/events
*/
function* makeFetch(action) {
  const { actionType, eventType, payload } = action;

  const category = yield select(selectCurrentShoppingExperienceCategory);
  const network = yield select(selectCustomerDataNetwork);
  const product = yield select(selectShoppingExperience);
  const quantity = yield select(selectCustomerDataQuantity);
  const selectPrice = yield select(selectCustomerDataDenomination);
  const price = selectPrice || product.min_load;
  const customerData = yield select(selectCustomerDataObject);
  const deliveryDate = customerData?.[CUSTOMER_DATA_FIELDS_DELIVERYDATE];

  const items = [
    {
      brand_id: product.id,
      index: 0,
      item_brand: product.brand_name
        ? product.brand_name
        : product.name,
      item_category: PDP_BRANDS,
      item_id: product?.upc || product?.upcs[network],
      item_name: product.name || product.display_name,
      item_variant: network ? `${category}_${network}` : category,
      price,
      quantity,
      delivery_date: deliveryDate ? getDeliveryDate(deliveryDate) : 'instant',
      ...(customerData
        && (customerData?.[CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL]
          || customerData?.[CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE]) && {
        ...getDeliveryMethods(
          customerData?.[CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL],
          customerData?.[CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE],
        ),
      }),
      ...getRelatedProductList(),
    },
  ];

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    ecommerce: {
      currency: CURRENCY,
      items,
      value: (price * quantity),
    },
    event: eventType,
    ...(actionType && { action_type: actionType }),
    ...(payload && { ...payload }),
  });
}

export default function* watchFetch() {
  yield takeEvery(GOOGLE_ANALYTICS_VIEW_ITEM_PDP, makeFetch);
}
