import Colors from '../../palette/Colors';

/**
 * `Dialog` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/dialog/}
 */
const MuiDialog = Object.freeze({
  styleOverrides: {
    paper: {
      borderColor: Colors.CyanBlueLight1,
      borderRadius: 32,
      borderStyle: 'solid',
      borderWidth: 1,
      maxWidth: 680,
    },
    paperFullScreen: {
      borderRadius: 0,
    },
  },
});

export default MuiDialog;
