import { createSelector } from 'reselect';

import { selectCurrentShoppingExperienceId } from './currentShoppingExperienceSelectors';

const selectCustomizationPhysicalOptionsDataState = (state) => state.get('customizationPhysicalOptions');
export const selectCustomizationOptions = createSelector(
  [
    selectCustomizationPhysicalOptionsDataState,
    selectCurrentShoppingExperienceId,
  ],
  (customizationOptions, shoppingExperienceId) => customizationOptions
    .getIn(['byId', shoppingExperienceId])?.toObject() || {},
);

export const selectCustomizationOptionsById = createSelector(
  [
    selectCustomizationPhysicalOptionsDataState,
    (_, optionsId) => optionsId,
  ],
  (customizationOptions, optionsId) => customizationOptions
    .getIn(['byId', optionsId])
    ?.toObject(),
);
