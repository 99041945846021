import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchShoppingExperienceStandardMessages } from '../../../services/shoppingExperienceApi';
import { FETCH_SHOPPING_EXPERIENCE_STANDARD_MESSAGES } from '../../../utils/constants';
import { setShoppingExperienceStandardMessages } from '../../actions/dictionariesActions';

/**
 * An object that returns `fetchShoppingExperienceStandardMessages` call.
 * @typedef {Object} StandardMessages
 * @property {number} limit
 * @property {number} page
 * @property {number} pages
 * @property {number} total
 * @property {Object} _embedded
 * @property {Array.<string>} _embedded.items
 */

/**
 * Saga to fetch shopping experience standard messages.
 * FE expects BE to return @type {StandardMessages}.
 * @todo Throw and error if `data._embedded.items` does not exist;
 * @todo Add loading actions;
 * @todo Add handling error actions;
 */
function* makeFetch() {
  try {
    const { data } = yield call(fetchShoppingExperienceStandardMessages);

    yield put(setShoppingExperienceStandardMessages(data._embedded.items));
  } catch (error) {
    // TODO: add error handling
  }
}

export default function* watchFetch() {
  yield takeEvery(FETCH_SHOPPING_EXPERIENCE_STANDARD_MESSAGES, makeFetch);
}
