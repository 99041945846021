import fonts from './fonts';

/**
 * Object of the `TypographyOptions`, used by MUI theme.
 *
 * @type TypographyOptions
 * @see {@link https://mui.com/material-ui/customization/typography/}
 */
const typography = Object.freeze({
  fontFamily: '"SofiaPro", "sofia-pro", "Arial", "sans-serif"',
  fontWeightRegular: 400,
  htmlFontSize: 16,
  ...fonts,
});

export default typography;
