/* eslint-disable max-len */

export const SET_LOADING_BY_ID = '@@gcfront/SET_LOADING_BY_ID';
export const APP_SPINNER_LOADING = 'APP_SPINNER_LOADING';

// Shopping experience actions
export const SET_CURRENT_SHOPPING_EXPERIENCE_EXTERNAL_ID = '@@gcfront/SET_CURRENT_SHOPPING_EXPERIENCE_EXTERNAL_ID';
export const SET_CURRENT_SHOPPING_EXPERIENCE_PRODUCT_ID = '@@gcfront/SET_CURRENT_SHOPPING_EXPERIENCE_PRODUCT_ID';
export const SET_CURRENT_SHOPPING_EXPERIENCE_CATEGORY = '@@gcfront/SET_CURRENT_SHOPPING_EXPERIENCE_CATEGORY';
export const SET_CURRENT_SHOPPING_EXPERIENCE_CURRENT_STEP = '@@gcfront/SET_CURRENT_SHOPPING_EXPERIENCE_CURRENT_STEP';
export const CLEAR_CURRENT_SHOPPING_EXPERIENCE = '@@gcfront/CLEAR_CURRENT_SHOPPING_EXPERIENCE';
export const FETCH_SHOPPING_EXPERIENCE_SAGA = '@@gcfront/FETCH_SHOPPING_EXPERIENCE_SAGA';
export const ADD_SHOPPING_EXPERIENCE = '@@gcfront/ADD_SHOPPING_EXPERIENCE';
export const REMOVE_SHOPPING_EXPERIENCE = '@@gcfront/REMOVE_SHOPPING_EXPERIENCE';
export const CLEAR_SHOPPING_EXPERIENCES = '@@gcfront/CLEAR_SHOPPING_EXPERIENCES';
export const FETCH_SHOPPING_EXPERIENCE_STANDARD_MESSAGES = '@@gcfront/FETCH_SHOPPING_EXPERIENCE_STANDARD_MESSAGES';
export const SET_SHOPPING_EXPERIENCE_STANDARD_MESSAGES = '@@gcfront/SET_SHOPPING_EXPERIENCE_STANDARD_MESSAGES';
export const CLEAR_SHOPPING_EXPERIENCE_STANDARD_MESSAGES = '@@gcfront/CLEAR_SHOPPING_EXPERIENCE_STANDARD_MESSAGES';
export const SET_SHOPPING_EXPERIENCE_DISPLAY_IMAGE = '@@gcfront/SET_SHOPPING_EXPERIENCE_DISPLAY_IMAGE';
// Shopping experience loading Ids
export const FETCH_SHOPPING_EXPERIENCE_LOADING = 'FETCH_SHOPPING_EXPERIENCE_LOADING';
export const FETCH_STANDARD_GREETING_CARDS_LOADING = 'FETCH_STANDARD_GREETING_CARDS_LOADING';
export const FETCH_UPLOAD_CARD_IMAGE_LOADING = 'FETCH_UPLOAD_CARD_IMAGE_LOADING';

// Customer data actions
export const ADD_CUSTOMER_DATA = '@@gcfront/ADD_CUSTOMER_DATA';
export const REMOVE_CUSTOMER_DATA = '@@gcfront/REMOVE_CUSTOMER_DATA';
export const CLEAR_CUSTOMER_DATA = '@@gcfront/CLEAR_CUSTOMER_DATA';
export const SET_CUSTOMER_DATA_BY_KEY = '@@gcfront/SET_CUSTOMER_DATA_BY_KEY';
export const SEND_CUSTOMER_DATA_UPLOADED_IMAGE = '@@gcfront/SEND_CUSTOMER_DATA_UPLOADED_IMAGE';
export const SET_CUSTOMER_DATA_UPLOADED_IMAGE = '@@gcfront/SET_CUSTOMER_DATA_UPLOADED_IMAGE';

// Steps actions
export const SET_STEPS = '@@gcfront/SET_STEPS';
export const NEXT_STEP = '@@gcfront/NEXT_STEP';
export const PREV_STEP = '@@gcfront/PREV_STEP';
export const CLEAR_STEPS = '@@gcfront/CLEAR_STEPS';
// Shopping experience steps constants
export const NEXT_STEP_ACTION_ADD_CART = 'NEXT_STEP_ACTION_ADD_CART';
export const NEXT_STEP_ACTION_EDIT_CART = 'NEXT_STEP_ACTION_EDIT_CART';
export const NEXT_STEP_ACTION_CUSTOMIZATION_VIRTUAL = 'NEXT_STEP_ACTION_CUSTOMIZATION_VIRTUAL';
export const NEXT_STEP_ACTION_CUSTOMIZATION_OL_PHYSICAL = 'NEXT_STEP_ACTION_CUSTOMIZATION_OL_PHYSICAL';
export const CURRENT_STEP_NAME_CREATE_PDP = 'CURRENT_STEP_NAME_CREATE_PDP';
export const CURRENT_STEP_NAME_EDIT_PDP = 'CURRENT_STEP_NAME_EDIT_PDP';
export const CURRENT_STEP_NAME_CREATE_CUSTOMIZATION = 'CURRENT_STEP_NAME_CREATE_CUSTOMIZATION';
export const CURRENT_STEP_NAME_EDIT_CUSTOMIZATION = 'CURRENT_STEP_NAME_EDIT_CUSTOMIZATION';

export const GOOGLE_ANALYTICS_VIEW_ITEM_PDP = '@@gcfront/GOOGLE_ANALYTICS_VIEW_ITEM_PDP';
export const GOOGLE_ANALYTICS_CART = '@@gcfront/GOOGLE_ANALYTICS_CART';
export const GOOGLE_ANALYTICS_CHECKOUT = '@@gcfront/GOOGLE_ANALYTICS_CHECKOUT';

// Customer data fields
export const CUSTOMER_DATA_FIELDS_QUANTITY = 'quantity';
export const CUSTOMER_DATA_FIELDS_DENOMINATION = 'denomination';
export const CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME = 'name';
export const CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL = 'recipient_email';
export const CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE = 'recipient_phone_number';

export const CUSTOMER_DELIVERY_METHOD = 'delivery_method';
export const DELIVERY_METHOD_EMAIL = 'delivery_method_email';
export const DELIVERY_METHOD_PHONE = 'delivery_method_phone';

export const CUSTOMER_DATA_FIELDS_MESSAGE = 'message';
export const CUSTOMER_DATA_FIELDS_PANEL3_TEXT = 'panel3_text';
export const CUSTOMER_DATA_FIELDS_DELIVERYDATE = 'delivery_date';
export const CUSTOMER_DATA_FIELDS_NETWORK = 'network';
export const CUSTOMER_DATA_FIELDS_EMBOSS = 'emboss_message';
export const CUSTOMER_DATA_FIELDS_DESIGN_ID = 'design';
export const CUSTOMER_DATA_FIELDS_DESIGN_URL = 'designUrl';
export const CUSTOMER_DATA_FIELDS_DESIGN_VIDEO_SOURCES = 'video_sources';
export const CUSTOMER_DATA_FIELDS_DESIGN_VIDEO_TRACKS = 'video_tracks';
export const CUSTOMER_DATA_FIELDS_DESIGN_TYPE = 'design_type';
export const CUSTOMER_DATA_FIELDS_CARRIER = 'carrier';
export const CUSTOMER_DATA_FIELDS_FROM = 'from';

//Andor product new base URL
export const NEW_BASE_URL = '/us/en/catalog/product-details';

// Customer data fields value
export const CUSTOMER_DATA_VALUES_UPLOAD_DESIGN_TYPE = 'custom_image';

export const NETWORK_TYPE_VISA = 'visa';
export const NETWORK_TYPE_MASTERCARD = 'mastercard';
export const NETWORK_TYPE_DISCOVER = 'discover';
export const NETWORK_TYPE_AMEX = 'american express';

export const NETWORK_TYPE_MAPPER = {
  [NETWORK_TYPE_MASTERCARD]: 'https://gccdn.giftcards.com/overlays/unbranded-mastercard-bhn.png',
  [NETWORK_TYPE_VISA]: 'https://gccdn.giftcards.com/overlays/unbranded-visa-bhn.png',
};

export const PDP_BRANDS = 'Brands';

export const MASTERCARD_EGIFT_CARD = 'mastercard-egift-gift-card';
export const VISA_EGIFT_CARD = 'visa-egift-gift-card';
export const CURRENCY = 'USD';

export const EDIT_PAGE_PDP = 'pdp';
export const EDIT_PAGE_CUSTOMIZATION = 'customization';

// Customization
export const GET_ALL_CATEGORIES_SAGA = '@@gcfront/GET_ALL_CATEGORIES_SAGA';
export const SET_CATEGORIES_DATA = '@@gcfront/SET_CATEGORIES_DATA';
export const GET_STANDARD_GREETING_CARDS_SAGA = '@@gcfront/GET_STANDARD_GREETING_CARDS_SAGA';
export const SET_STANDARD_GREETING_CARDS_DATA = '@@gcfront/SET_STANDARD_GREETING_CARDS_DATA';
export const SET_STANDARD_GREETING_CARDS_CATEGORY_DATA = '@@gcfront/SET_STANDARD_GREETING_CARDS_CATEGORY_DATA';
export const SET_STANDARD_GREETING_CARDS_SEARCH_DATA = '@@gcfront/SET_STANDARD_GREETING_CARDS_SEARCH_DATA';
export const CREATE_GREETING_CARD_DESIGN_SAGA = '@@gcfront/CREATE_GREETING_CARD_DESIGN_SAGA';
export const GET_GREETING_CARD_DETAILS_SAGA = '@@gcfront/GET_GREETING_CARD_DETAILS_SAGA';

// Customization options
export const CLEAR_CUSTOMIZATION_PHYSICAL_OPTIONS_DATA = '@@gcfront/CLEAR_CUSTOMIZATION_PHYSICAL_OPTIONS_DATA';
export const ADD_CUSTOMIZATION_PHYSICAL_OPTIONS = '@@gcfront/ADD_CUSTOMIZATION_PHYSICAL_OPTIONS';
export const SET_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY = '@@gcfront/SET_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY';
export const REMOVE_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY = '@@gcfront/REMOVE_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY';

// promo code cookie name
export const COUPON_COOKIE_NAME = 'coupon';

// max cart quantity const
export const MAX_CART_QUANTITY = 50;

// localStorage
export const LS_KEY_STORE = 'store';
export const GA_LISTS_STORE = 'ga_cart_items_list';
export const GA_LIST_ID_STORE = 'item_list_id';
export const GA_LIST_NAME_STORE = 'item_list_name';

export const IMAGE_ASPECT_RATIO = 154 / 97;
export const PRODUCT_LIST_PAGE_SIZE = 24;
export const PRODUCT_LIST_CATEGORY_VIRTUAL = 'virtual';
export const PRODUCT_LIST_CATEGORY_BRAND = 'brand';
export const PRODUCT_LIST_CUSTOMIZE_YOUR_OWN_CARD = 'visa mastercard';

export const REGISTRATION_BENEFITS_LIST = [
  'Check out quicker with stored payments',
  'View order status and history',
  'Opt-in for early access and promotions',
  'Enroll for G-Money reward points',
];
