import Colors from '../palette/Colors';

/**
 * `Autocomplete` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/autocomplete/}
 */
const MuiOutlinedInput = Object.freeze({
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    colorSecondary: {
      color: Colors.CyanBlueMain,
    },
    input: {
      paddingBottom: 20,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 20,
    },
    option: {
      '&.Mui-focused': {
        backgroundColor: '#919191 !important',
      },
      '&[aria-selected="true"]': {
        backgroundColor: '#C46EC1 !important',
      },
    },
    popupIndicator: {
      '& > svg': {
        fontSize: 14,
      },
      borderColor: Colors.CyanBlueMain,
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: 1.5,
      color: Colors.CyanBlueMain,
      fontSize: 14,
      right: 11,
    },
    popupIndicatorOpen: {
      borderColor: Colors.PurpleMain,
      color: Colors.PurpleMain,
    },
    root: {
      '& input': { height: 30, paddingLeft: '14px !important' },
      borderRadius: 1000,
      color: Colors.PurpleMain,
    },
  },
});

export default MuiOutlinedInput;
