import Colors from '../../palette/Colors';

/**
 * `DialogActions` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/dialog-actions/}
 */
const MuiDialogActions = Object.freeze({
  styleOverrides: {
    root: {
      backgroundColor: Colors.GreyLight,
      boxShadow: '0px -15px 48px 0px #00000024',
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 24,
      paddingTop: 24,
    },
  },
});

export default MuiDialogActions;
