import { SET_FEEDBACK,
  REMOVE_FEEDBACK,
  CLEAR_FEEDBACKS } from 'utils/constants/feedback';

/**
 * An action to set some feedback from API response, that should be displayed on the UI.
 * @param {string} id - an identificator of a feedback object
 * @param {('FEEDBACK_LEVEL_ERROR'|'FEEDBACK_LEVEL_SUCCESS')} level - level of the feedback severity
 * @param {string} message - message to display on the UI
 * @returns {Object}
 */
export const setFeedback = (id, level, message) => ({
  id,
  level,
  message,
  type: SET_FEEDBACK,
});

/**
 * If feedback is no longer needed, remove it from the reducer.
 * @param {string} id - an identificator of a feedback object
 * @returns {Object}
 */
export const removeFeedback = (id) => ({
  id,
  type: REMOVE_FEEDBACK,
});

/**
 * Clear all the feedback, if there's no more responses to proceed, or user's leaving a page.
 * @returns {Object}
 */
export const clearFeedbacks = () => ({
  type: CLEAR_FEEDBACKS,
});
