import { call, put, takeLatest } from 'redux-saga/effects';

import { setVirtualDesignCategoriesData } from 'src/app/actions/customizationVirtualActions';
import { getAllDesignCategories } from 'src/services/customizationApi';
import LocalStorageService from 'src/services/localStorage';
import { GET_VIRTUAL_DESIGN_CATEGORIES_SAGA } from 'utils/constants/customization';

function* makeFetch() {
  try {
    const isPremium = !!parseInt(LocalStorageService.get('vggtg', false), 10);
    const response = yield call(getAllDesignCategories, {
      isPremium,
    });
    yield put(
      setVirtualDesignCategoriesData(
        response.data._embedded.items.filter(
          (item) => item.design_count,
        ),
      ),
    );
  } catch (e) {
    // TODO: Add error handling
  } finally {
    // TODO: Add loading
  }
}

export default function* watchGetVirtualDesignCategories() {
  yield takeLatest(GET_VIRTUAL_DESIGN_CATEGORIES_SAGA, makeFetch);
}
