import { call, put, select, takeLatest } from 'redux-saga/effects';

import { setCustomerDataByKey } from 'src/app/actions/customerDataActions';
import { setLoadingById } from 'src/app/actions/loadingActions';
import { nextStepSaga } from 'src/app/actions/stepsAction';
import { selectCurrentShoppingExperienceId } from 'src/app/selectors/currentShoppingExperienceSelectors';
import { selectCustomerDataCarrier } from 'src/app/selectors/customerDataSelectors';
import { createGreetingCardDesign } from 'src/services/customizationApi';
import { SAVE_SUBMISSION_ITEM_LOADING } from 'src/utils/constants/submission';
import { CREATE_GREETING_CARD_DESIGN_SAGA, CUSTOMER_DATA_FIELDS_CARRIER } from 'utils/constants';

function* makeFetch(action) {
  try {
    yield put(setLoadingById(SAVE_SUBMISSION_ITEM_LOADING, true)); // TODO: should be a different loading id
    const { payload } = action;

    const customerDataCarrier = yield select(selectCustomerDataCarrier);
    const currentShoppingExperienceId = yield select(selectCurrentShoppingExperienceId);
    const { data } = yield call(createGreetingCardDesign, payload);

    yield put(setCustomerDataByKey(currentShoppingExperienceId, CUSTOMER_DATA_FIELDS_CARRIER, {
      ...customerDataCarrier,
      customer_data: {
        ...customerDataCarrier.customer_data,
        panel3: data.id,
      },
    }));

    yield put(nextStepSaga());
  } catch (e) {
    // TODO: Add error handling
  }
}

export default function* watchCreateGreetingCardDesign() {
  yield takeLatest(CREATE_GREETING_CARD_DESIGN_SAGA, makeFetch);
}
