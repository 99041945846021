export const BILLING_CONTACT_DETAILS_EMAIL = 'email';
export const BILLING_CONTACT_DETAILS_PHONE = 'phone';
/** @deprecated */
export const BILLING_VERIFICATION = 'billing-verification';
export const BILLING_CARDHOLDER_AGREEMENT = 'billing-cardholder-agreeement';
export const BILLING_RECEIVE_EMAILS = 'billing-receive-emails';
export const BILLING_STORED_PAYMENT_CVV = 'billing-stored-payment-cvv';
export const BILLING_COMPLETE_ORDER_BUTTON = 'billing-complete-order';

export const INIT_BILLING_PAGE_SAGA = '@@gcfront/INIT_BILLING_PAGE_SAGA';
export const PROCEED_COMPLETE_ORDER_PAYMENT_PLUS = '@@gcfront/PROCEED_COMPLETE_ORDER_PAYMENT_PLUS';
export const SET_PAYMENT_METHOD = '@@gcfront/SET_PAYMENT_METHOD';
export const SET_PAYMENT_PLUS_VALUES = '@@gcfront/SET_PAYMENT_PLUS_VALUES';
export const VALIDATE_AUTHORIZATION_HMAC_SAGA = '@@gcfront/VALIDATE_AUTHORIZATION_HMAC_SAGA';
export const CREATE_ORDER = '@@gcfront/CREATE_ORDER';
export const SET_ORDER_RESULT = '@@gcfront/SET_ORDER_RESULT';
export const BILLING_RECEIVE_EMAILS_UPDATES = '@@gcfront/BILLING_RECEIVE_EMAILS_UPDATES';

// Order loading ids
export const CREATE_ORDER_LOADING = 'CREATE_ORDER_LOADING'; // TODO: currrently unused. Remove if redundant

// Address autocomplete
export const FETCH_AUTOCOMPLETE_ADDRESSES_SAGA = '@@gcfront/FETCH_AUTOCOMPLETE_ADDRESSES_SAGA';
export const SET_AUTOCOMPLETE_ADDRESSES = '@@gcfront/SET_AUTOCOMPLETE_ADDRESSES';
export const CLEAR_AUTOCOMPLETE_ADDRESSES = '@@gcfront/CLEAR_AUTOCOMPLETE_ADDRESSES';

export const FETCH_PHYSICAL_DELIVERY_METHODS_SAGA = '@@gcfront/FETCH_PHYSICAL_DELIVERY_METHODS_SAGA';
export const SET_PHYSICAL_DELIVERY_METHODS = '@@gcfront/SET_PHYSICAL_DELIVERY_METHODS';
export const CLEAR_PHYSICAL_DELIVERY_METHODS = '@@gcfront/CLEAR_PHYSICAL_DELIVERY_METHODS';
export const FETCH_PHYSICAL_DELIVERY_METHODS_LOADING = 'FETCH_PHYSICAL_DELIVERY_METHODS_LOADING';

// US states as array
export const states = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE',
  'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA',
  'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

// Payment methods
export const PAYMENT_PLUS_METHOD_PAYPAL = 'PAYPAL';
export const PAYMENT_PLUS_METHOD_GOOGLE = 'GOOGLE_PAY';
export const PAYMENT_PLUS_METHOD_APPLE_PAY = 'APPLE_PAY';
export const PAYMENT_PLUS_METHOD_ZIP_PAY = 'ZIP_PAY';

export const PAYMENT_TYPE_REWARDS = 'reward_points';
export const PAYMENT_TYPE_SAVED_CREDIT_CARD = 'saved_credit_card';

export const PAYMENT_PLUS_AUTH_ERROR_MSG = 'The payment was declined. You can try again, or use a different '
    + 'payment method. Some credit cards do not allow gift card purchases, including Visa, '
    + 'Mastercard, or American Express gift cards. Contact your card provider for support. ';

export const BILLING_ZERO_ORDER_MSG = "Sorry, you can't check out with an order value of $0.00. "
  + 'Please adjust your order by adding more items or removing discount codes.';
