import { SET_CURRENT_SUBMISSION_DELIVERIES,
  SET_SUBMISSION_DELIVERY_OPTIONS_DATA_BY_KEY,
  SET_SUBMISSION_DELIVERY_METHOD_BY_KEY } from 'utils/constants/submission';

export const setCurrentSubmissionDeliveries = (payload) => ({
  payload,
  type: SET_CURRENT_SUBMISSION_DELIVERIES,
});

export const setSubmissionDeliveryOptionsDataByKey = (deliveryId, key, value) => ({
  deliveryId,
  key,
  type: SET_SUBMISSION_DELIVERY_OPTIONS_DATA_BY_KEY,
  value,
});

export const setSubmissionDeliveryMethodByKey = (deliveryId, value) => ({
  deliveryId,
  type: SET_SUBMISSION_DELIVERY_METHOD_BY_KEY,
  value,
});
