import Colors from '../palette/Colors';

/**
 * `Paper` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/paper/}
 */
const MuiPaper = Object.freeze({
  styleOverrides: {
    elevation1: {
      boxShadow: '0px -2px 24px -14px #00000080',
    },
    root: {
      background: Colors.White,
      border: '1px solid rgba(0, 0, 0, 0.05)',
    },
    rounded: {
      borderRadius: 8,
    },
  },
});

export default MuiPaper;
