import Colors from '../palette/Colors';

/**
 * `Select` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/react-select/}
 */
const MuiSelect = Object.freeze({
  defaultProps: {
    variant: 'outlined',
  },
  styleOverrides: {
    icon: {
      borderColor: Colors.CyanBlueMain,
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: 1.5,
      color: Colors.CyanBlueMain,
      fontSize: 20,
      right: 17,
    },
    iconOpen: {
      borderColor: Colors.PurpleMain,
      color: Colors.PurpleMain,
    },
    select: {
      '&.MuiInputBase-input': {
        alignItems: 'center',
        color: Colors.CyanBlueMain,
        display: 'flex',
        paddingRight: 42,
        whiteSpace: 'unset',
      },
      '&:hover': {
        '&~ .MuiSelect-icon': {
          borderColor: Colors.PurpleMain,
          color: Colors.PurpleMain,
        },
      },
    },
  },
});

export default MuiSelect;
