// Event names
export const EVENT_VIEW_ITEM = 'view_item';
export const EVENT_VIEW_ITEM_LIST = 'view_item_list';
export const EVENT_SELECT_ITEM = 'select_item';
export const EVENT_ADD_TO_CART = 'add_to_cart';
export const EVENT_CARD_CUSTOMIZATION = 'card_customization';
export const EVENT_VIEW_TO_CART = 'view_cart';
export const EVENT_REMOVE_FROM_CART = 'remove_from_cart';
export const EVENT_DUPLICATE_CART_ITEM = 'duplicate_cart_item';
export const EVENT_BEGIN_CHECKOUT = 'begin_checkout';
export const EVENT_PAYMENT_METHOD = 'add_payment_info';
export const EVENT_APPLY_PROMOTION = 'apply_promotion';
export const EVENT_WITH_GMONEY = 'with_gmoney';
export const EVENT_WITHOUT_GMONEY = 'without_gmoney';
export const EVENT_ADD_SHIPPING_INFO = 'add_shipping_info';
export const EVENT_CONFIRM_PURCHASE = 'purchased';
export const EVENT_PURCHASE_BEFORE_SUBMIT = 'purchase_before_submit';
export const EVENT_PURCHASE_AUTH_RESPONSE = 'purchase_auth_response';
export const EVENT_PURCHASE_VALIDATE_AUTH_HMAC = 'purchase_validate_auth_hmac';
export const EVENT_PURCHASE_FAILED = 'failed_purchase'; // purchase_failed
export const EVENT_UPLOAD_IMAGE = 'upload_image';
export const EVENT_UPLOAD_DESIGN = 'upload_design';
export const EVENT_SIGN_UP = 'sign_up';
export const EVENT_LOGIN = 'login';

// Action name
export const ACTION_FEES_OPEN = 'view_fees_open';
export const ACTION_FEES_CLOSE = 'view_fees_close';
export const ACTION_SELECT_PRICE = 'select_price';
export const ACTION_READ_MORE = 'read_more';
export const ACTION_READ_LESS = 'read_less';
export const ACTION_UPLOAD_IMAGE = 'upload';
export const ACTION_UPDATE_IMAGE = 'update';
export const ACTION_SWITCH_NETWORK = 'switch_to_';

// Generic Message
export const VALIDATION_FAILED = 'Validation Failed';
export const NOT_APPLICABLE = 'Not Applicable';

// Other constants
export const COUNTRY_CODE = 'US';

export const ITEM_LIST_ID_EGIFT_CARDS = 'egift-cards';
export const ITEM_LIST_NAME_EGIFT_CARDS = 'eGift Cards';

export const ITEM_LIST_ID_BUY_GIFT_CARDS = 'buy-gift-cards';
export const ITEM_LIST_NAME_BUY_GIFT_CARDS = 'Buy Gift Cards';
