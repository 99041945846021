import React, { useCallback, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { setCustomerDataByKey } from 'src/app/actions/customerDataActions';
import { fetchShoppingExperienceSaga } from 'src/app/actions/shoppingExperiencesActions';
import { selectCurrentShoppingExperienceId } from 'src/app/selectors/currentShoppingExperienceSelectors';
import { selectRecipientInfoFullName, selectRecipientInfoMessage } from 'src/app/selectors/customerDataSelectors';
import { selectIsLoadingById } from 'src/app/selectors/loadingSelectors';
import { selectShoppingExperience } from 'src/app/selectors/shoppingExperiencesSelectors';
import CardImage from 'src/components/common/CardImage/CardImage';
import InputWithDebounce from 'src/components/common/InputWithDebounce';
import CardFeesPopover from 'src/components/common/PriceWidget/CardFeesPopover';
import { SAVE_SUBMISSION_ITEM_LOADING } from 'src/utils/constants/submission';
import { currencyFormatter } from 'src/utils/formatterUtils';
import { CUSTOMER_DATA_FIELDS_MESSAGE,
  CUSTOMER_DATA_FIELDS_DENOMINATION,
  CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME,
  FETCH_SHOPPING_EXPERIENCE_LOADING } from 'utils/constants';
import { getFormControllerError } from 'utils/form';
import { onKeydownDisableSpecialChars, onPasteRemoveSpecialChars } from 'utils/keydown';

import QuickBuyDeliveryMethod from './QuickBuyDeliveryMethod';
import useQuickBuyForm from './useQuickBuyForm';

const MESSAGE_MAX_LENGTH = 350;

export default function QuickBuyForm({ cartQuantity, denomination, imgAlt, imgSrc, shoppingExperienceId }) {
  const [cardFeesAnchorEl, setCardFeesAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const experienceCategory = 'virtual';
  const experienceNetwork = 'visa';

  const fullName = useSelector(selectRecipientInfoFullName);
  const message = useSelector(selectRecipientInfoMessage);
  const isLoadingSubmission = useSelector((state) => selectIsLoadingById(state, SAVE_SUBMISSION_ITEM_LOADING));
  const isLoadingExperience = useSelector((state) => selectIsLoadingById(state, FETCH_SHOPPING_EXPERIENCE_LOADING));
  const currentShoppingExperienceId = useSelector(selectCurrentShoppingExperienceId);

  const handleSetFullName = useCallback((value) => {
    dispatch(setCustomerDataByKey(currentShoppingExperienceId, CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME, value));
  }, [currentShoppingExperienceId, dispatch]);

  const handleSetMessage = useCallback((value) => {
    dispatch(setCustomerDataByKey(currentShoppingExperienceId, CUSTOMER_DATA_FIELDS_MESSAGE, value));
  }, [currentShoppingExperienceId, dispatch]);

  const shoppingExperience = useSelector(selectShoppingExperience);

  const { form, fullNameController, messageController, messageError, onSubmit } = useQuickBuyForm({
    cartQuantity,
    denomination,
    experienceCategory,
    experienceNetwork,
    shoppingExperience,
  });

  const handleFeesPopoverClick = (event) => {
    setCardFeesAnchorEl(event.currentTarget);
    // TODO: Send GA event?
  };

  const handleCloseFeesPopover = () => {
    setCardFeesAnchorEl(null);
    // TODO: Send GA event?
  };

  useEffect(() => {
    dispatch(fetchShoppingExperienceSaga(experienceCategory, shoppingExperienceId, experienceNetwork));
  }, [dispatch, shoppingExperienceId]);

  useEffect(() => {
    if (currentShoppingExperienceId) {
      dispatch(setCustomerDataByKey(
        currentShoppingExperienceId,
        CUSTOMER_DATA_FIELDS_DENOMINATION,
        Number(denomination),
      ));
    }
  }, [dispatch, currentShoppingExperienceId, denomination]);

  const error = getFormControllerError(fullNameController);
  const hasError = !!error;

  return (
    <form
      className="w-full md:w-[744px] grid gap-6"
      noValidate
      onSubmit={onSubmit}
    >
      <Box className="flex flex-col md:flex-row gap-6">
        <CardImage
          alt={imgAlt}
          imageClassName="shadow-Shadow !w-[326px] !h-[206px] md:!w-[297px] md:!h-[194px] mx-auto md:mx-0"
          src={imgSrc}
        />

        <Box className="flex flex-col gap-4">
          <Typography
            className="text-left !text-PurpleDark !text-[24px] md:!text-[30px]"
            fontWeight={700}
            variant="h4"
          >
            {shoppingExperience?.brand_name}
          </Typography>
          <Typography className="flex items-end gap-2" fontWeight={700} variant="body">
            <span className="text-xl">Card value</span>
            <span className="text-2xl">{currencyFormatter.format(denomination)}</span>
          </Typography>
          <Box className="flex flex-col mt-auto">
            <Button
              className="!p-0 !justify-start !font-normal !text-[20px]"
              color="primary"
              onClick={handleFeesPopoverClick}
              size="medium"
              variant="text"
            >
              View fees
            </Button>
            <Button
              className="!p-0 !justify-start !font-normal !text-[20px]"
              color="primary"
              href="https://www.giftcards.com/legal"
              size="medium"
              target="_blank"
              variant="text"
            >
              Terms of use
            </Button>
            <Button
              className="!p-0 !justify-start !font-normal !text-[20px]"
              color="primary"
              href="https://www.giftcards.com/bhn-olp-07675022697"
              size="medium"
              target="_blank"
              variant="text"
            >
              Cardholder agreement
            </Button>
          </Box>
        </Box>
      </Box>

      <Box className="grid gap-4">
        <Typography
          className="!text-left !text-[20px] !font-bold"
          fontSize={20}
          fontWeight={800}
        >
          Recipient
        </Typography>
        <InputWithDebounce
          className="md:w-[520px]"
          controller={fullNameController}
          FormHelperTextProps={{
            classes: {
              root: hasError ? 'not-italic' : 'italic',
            },
          }}
          fullWidth
          helperText="e.g. Mike Smith"
          id="pdp-recipient-info-fullName"
          inputProps={{
            maxLength: 20,
          }}
          label="Recipient full name"
          onChange={handleSetFullName}
          onKeyDown={onKeydownDisableSpecialChars}
          onPaste={onPasteRemoveSpecialChars}
          outerValue={fullName}
        />
      </Box>

      <Box className="grid gap-4 max-w-[520px]">
        <QuickBuyDeliveryMethod control={form.control} />
      </Box>

      <Box className="grid gap-4">
        <Typography
          className="!text-left !text-[20px] !font-bold"
          fontSize={20}
          fontWeight={800}
        >
          Message
        </Typography>
        <InputWithDebounce
          className="md:w-[520px]"
          controller={messageController}
          FormHelperTextProps={{
            className: messageError ? 'text-left' : 'text-right',
          }}
          fullWidth
          helperText={`${MESSAGE_MAX_LENGTH} characters maximum`}
          id="pdp-recipient-info-message"
          inputProps={{
            maxLength: MESSAGE_MAX_LENGTH,
          }}
          label="Message (optional)"
          maxRows={6}
          minRows={6}
          multiline
          onChange={handleSetMessage}
          outerValue={message}
        />
      </Box>

      <Box className="flex justify-center">
        <LoadingButton
          color="primary"
          loading={isLoadingExperience || isLoadingSubmission}
          type="submit"
          variant="contained"
        >
          Proceed to payment
        </LoadingButton>
      </Box>

      <CardFeesPopover
        anchorEl={cardFeesAnchorEl}
        category={experienceCategory}
        onClose={handleCloseFeesPopover}
        open={Boolean(cardFeesAnchorEl)}
        shoppingExperienceId={shoppingExperienceId}
      />
    </form>
  );
}

QuickBuyForm.propTypes = {
  cartQuantity: PropTypes.string.isRequired,
  denomination: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  shoppingExperienceId: PropTypes.string.isRequired,
};
