import { Map, List } from 'immutable';

import { DEFAULT_CATEGORY,
  STANDARD_CARRIER } from 'src/utils/constants/customization';
import { SET_STANDARD_GREETING_CARDS_DATA,
  SET_STANDARD_GREETING_CARDS_CATEGORY_DATA,
  SET_STANDARD_GREETING_CARDS_SEARCH_DATA } from 'utils/constants';

function getVirtualSlides(length) {
  return Array
    .from({ length })
    .map((_, index) => ({ id: index, name: '' }));
}

const initialState = Map({
  category: DEFAULT_CATEGORY,
  search: '',
  standardGreetingCardsData: Map({
    cards: List(getVirtualSlides(6)),
    limit: 6,
    pages: 0,
  }),
});

/**
 * `customization` reducer is about to store data for of Customization page.
 * * `categories` - a list of categories of cards;
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_STANDARD_GREETING_CARDS_DATA: {
      const { payload: { _embedded, limit, page, pages } } = action;

      const virtual = getVirtualSlides(pages * (limit + 1));

      virtual.splice((page - 1) * (limit + 1), 0, ...[STANDARD_CARRIER].concat(_embedded.items));

      return state.set('standardGreetingCardsData', Map({
        cards: List(virtual),
        limit,
        pages,
      }));
    }
    case SET_STANDARD_GREETING_CARDS_CATEGORY_DATA: {
      const { payload } = action;

      return state.set('category', payload);
    }
    case SET_STANDARD_GREETING_CARDS_SEARCH_DATA: {
      const { payload } = action;

      return state.set('search', payload);
    }
    default:
      return state;
  }
}

export default reducer;
