import React, { memo, useLayoutEffect, useRef, useState } from 'react';

import { Box, Skeleton } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { NETWORK_TYPE_MAPPER,
  NETWORK_TYPE_VISA,
  NETWORK_TYPE_MASTERCARD } from 'src/utils/constants';

const CARD_IMAGE_HEIGHT = 336;
const CARD_IMAGE_WIDTH = 525;

const CARD_IMAGE_CANVAS_FONT = 'bold 18px sans-serif';
const CARD_IMAGE_CANVAS_FILL_STYLE = 'white';
const CARD_IMAGE_CANVAS_STROKE_STYLE = 'black';
const CARD_IMAGE_CANVAS_LINE_WIDTH = 1.5;

/**
 * Component renders card image.
 * This component will be removed in the next iteration, if product factory model is integrated.
 *
 * @type ReactComponent
 */
function CardImage({
  alt,
  className,
  embossData,
  imageClassName,
  src,
}) {
  const [isLoading, setIsLoading] = useState(true);

  const networkCanvasRef = useRef(null);
  const userDataCanvasRef = useRef(null);

  useLayoutEffect(() => {
    const image = new Image();

    image.addEventListener('load', () => {
      setIsLoading(false);
    });

    image.src = src;
  }, [src]);

  useLayoutEffect(() => {
    const networkOverlay = NETWORK_TYPE_MAPPER[embossData.network];
    if (!networkOverlay) return;

    const canvas = networkCanvasRef.current;

    if (!canvas || isLoading) return;

    const context = canvas.getContext('2d');
    context.clearRect(0, 0, CARD_IMAGE_WIDTH, CARD_IMAGE_HEIGHT);

    context.font = CARD_IMAGE_CANVAS_FONT;
    context.fillStyle = CARD_IMAGE_CANVAS_FILL_STYLE;
    context.strokeStyle = CARD_IMAGE_CANVAS_STROKE_STYLE;
    context.lineWidth = CARD_IMAGE_CANVAS_LINE_WIDTH;

    const overlay = new Image();

    const today = new Date();
    const currentMonth = (`0${today.getMonth() + 1}`).slice(-2);
    const year = parseInt(String(today.getFullYear()).slice(-2), 10) + 7;

    overlay.addEventListener('load', () => {
      context.drawImage(overlay, 0, 0, CARD_IMAGE_WIDTH, CARD_IMAGE_HEIGHT);
      context.fillText(`${currentMonth}/${year}`, 250, 260, 100);
      context.strokeText(`${currentMonth}/${year}`, 250, 260, 100);
    });

    overlay.src = networkOverlay;
  }, [embossData.network, isLoading]);

  useLayoutEffect(() => {
    const canvas = userDataCanvasRef.current;

    if (!canvas || isLoading) return;

    const context = canvas.getContext('2d');
    context.clearRect(0, 0, CARD_IMAGE_WIDTH, CARD_IMAGE_HEIGHT);

    context.font = CARD_IMAGE_CANVAS_FONT;
    context.fillStyle = CARD_IMAGE_CANVAS_FILL_STYLE;
    context.strokeStyle = CARD_IMAGE_CANVAS_STROKE_STYLE;
    context.lineWidth = CARD_IMAGE_CANVAS_LINE_WIDTH;

    if (embossData.embossMessage) {
      const content = embossData.embossMessage.toUpperCase().split('').join(String.fromCharCode(8201));
      context.fillText(content, 50, 305, 400);
      context.strokeText(content, 50, 305, 400);
    }

    if (embossData.recipientFullName) {
      const content = embossData.recipientFullName.toUpperCase().split('').join(String.fromCharCode(8201));
      context.fillText(content, 50, 285, 400);
      context.strokeText(content, 50, 285, 400);
    }
  }, [embossData.embossMessage, embossData.recipientFullName, isLoading]);

  return (
    <Box className={classNames('relative mx-auto md:mx-0 h-fit w-fit', className)}>
      {isLoading ? (
        <Skeleton
          className={classNames('rounded-md w-[340px] h-[215px]', imageClassName)}
          variant="rectangular"
        />
      ) : (
        <>
          <img
            alt={alt}
            className={classNames('rounded-md w-[340px] h-[215px]', imageClassName)}
            height={CARD_IMAGE_HEIGHT}
            src={src}
            width={CARD_IMAGE_WIDTH}
          />
          {embossData.network && (
            <canvas
              ref={networkCanvasRef}
              className="w-full h-full absolute left-0"
              height={CARD_IMAGE_HEIGHT}
              width={CARD_IMAGE_WIDTH}
            />
          )}
          {(embossData.embossMessage || embossData.recipientFullName) && (
            <canvas
              ref={userDataCanvasRef}
              className="w-full h-full absolute left-0"
              height={CARD_IMAGE_HEIGHT}
              width={CARD_IMAGE_WIDTH}
            />
          )}
        </>
      )}
    </Box>
  );
}

CardImage.defaultProps = {
  alt: '',
  className: '',
  embossData: {},
  imageClassName: '',
  src: '',
};

CardImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  embossData: PropTypes.shape({
    embossMessage: PropTypes.string,
    network: PropTypes.oneOf([NETWORK_TYPE_VISA, NETWORK_TYPE_MASTERCARD]),
    recipientFullName: PropTypes.string,
  }),
  imageClassName: PropTypes.string,
  src: PropTypes.string,
};

export default memo(CardImage);
