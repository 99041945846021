import { createTheme } from '@mui/material/styles';

import { themeWithBreakpoints } from './breakpoints';
import components from './components';
import palette from './palette';
import typography from './typography';

/**
 * MUI main theme object.
 *
 * @see {@link https://mui.com/material-ui/customization/theming/}
 */
const theme = createTheme(themeWithBreakpoints, {
  components,
  palette,
  typography,
});

export default theme;
