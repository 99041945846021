import Colors from '../palette/Colors';

/**
 * `Paper` component theme overrides.
 *
 * @see {@link https://mui.com/x/api/date-pickers/pickers-day/}
 */
const MuiPickersDay = Object.freeze({
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        borderColor: Colors.PurpleMain,
        borderStyle: 'solid',
        borderWidth: 1,
      },
      '&.MuiPickersDay-today': {
        borderColor: Colors.GreyDark,
        borderStyle: 'solid',
        borderWidth: 1,
      },
      '&:hover': {
        borderColor: Colors.GreyDark,
        borderStyle: 'solid',
        borderWidth: 1,
      },
    },
  },
});

export default MuiPickersDay;
