import { REG_EXP_NO_SPECIAL_CHARACTERS, REG_EXP_EMAIL_CHARACTERS, REG_EXP_CARRIER_MESSAGE, REG_EXP_CARRIER_FROM, REG_EXP_QUANTITY } from 'utils/validate/regExps';

export function onKeydownDisableSpecialChars(event) {
    // allow numbers, alphabetic characters and space
    if (!REG_EXP_NO_SPECIAL_CHARACTERS.test(event.key)) {
        event.preventDefault();
    }
}

export function onKeydownValidateEmail(event) {
    // allow numbers, alphabetic characters and space
    if (!REG_EXP_EMAIL_CHARACTERS.test(event.key)) {
        event.preventDefault();
    }
}

export function onKeyDownValidateCarrierMessage(event) {
    if (!REG_EXP_CARRIER_MESSAGE.test(event.key)) {
        event.preventDefault();
    }
}

export function onKeyDownValidateCarrierFrom(event) {
    if (!REG_EXP_CARRIER_FROM.test(event.key)) {
        event.preventDefault();
    }
}

export function onKeyDownValidateQuantity(event) {
    if (!REG_EXP_QUANTITY.test(event.key)) {
        event.preventDefault();
    }
}

function sanitizeChar(regex) {
    return (char) => {
        if (regex.test(char)) {
            return char;
        }
        return '';
    }
}

function handlePaste(onChange, sanitizer) {
    return (event) => {
        event.preventDefault();
        const text = (event.clipboardData || window.clipboardData).getData('text');
        const transformedText = text.split('').map(sanitizer).join('').trim();
        onChange(transformedText);
    }
}

export function onPasteRemoveSpecialChars(onChange) {
    return handlePaste(onChange, sanitizeChar(REG_EXP_NO_SPECIAL_CHARACTERS));
}

export function onPasteValidateEmail(onChange) {
    return handlePaste(onChange, sanitizeChar(REG_EXP_EMAIL_CHARACTERS));
}

export function onPasteRemoveCarrierMessageSpecialChars(onChange) {
    return handlePaste(onChange, sanitizeChar(REG_EXP_CARRIER_MESSAGE));
}

export function onPasteRemoveCarrierFromSpecialChars(onChange) {
    return handlePaste(onChange, sanitizeChar(REG_EXP_CARRIER_FROM));
}