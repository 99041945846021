import LocalStorageService from 'src/services/localStorage/LocalStorageService';
import { GA_LISTS_STORE,
  GA_LIST_ID_STORE,
  GA_LIST_NAME_STORE,
  PDP_BRANDS,
  PRODUCT_LIST_CUSTOMIZE_YOUR_OWN_CARD } from 'src/utils/constants';

export function sendDataLayerEventData(data) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push(data);
}

export function getDeliveryMethods(email = false, phone = false) {
  return {
    send_by_email: !!email,
    send_by_phone: !!phone,
  };
}

export function getRelatedProductList() {
  const listId = LocalStorageService.get(GA_LIST_ID_STORE);
  const listName = LocalStorageService.get(GA_LIST_NAME_STORE);
  if (listId && listName) {
    return {
      item_list_id: listId,
      item_list_name: listName,
    };
  }
  return null;
}

export function getRelatedProductListById(externalId) {
  const lists = LocalStorageService.get(GA_LISTS_STORE);
  if (lists && lists[externalId]) {
    return {
      item_list_id: lists[externalId].listId,
      item_list_name: lists[externalId].listName,
    };
  }
  return null;
}

export function getDeliveryDate(delivery_date){
  const date = new Date(delivery_date);
  return date.toLocaleDateString("en-US");
}

export function sendRelatedProducts(eventType, data) {
  const items = data.items.map((item, index) => ({
    brand_id: item.brandId,
    index,
    item_brand: item.brand,
    item_category: PDP_BRANDS,
    item_id: item.itemId,
    item_list_id: item.listId,
    item_list_name: item.listName,
    item_name: item.title,
    item_variant: item.itemVariant,
    price: item.price,
    quantity: item.quantity,
  }));

  sendDataLayerEventData({
    ecommerce: {
      item_list_id: data.listId,
      item_list_name: data.listName,
      items,
    },
    event: eventType,
  });
}

export function sendPLPVirtualEvents(eventType, data) {
  const items = data.items.map((item, index) => ({
    brand_id: item.slug,
    index,
    item_brand: item.brandName,
    item_category: item.category,
    item_id: item.id,
    item_list_id: data.listId,
    item_list_name: data.listName,
    item_name: item.displayName,
  }));

  sendDataLayerEventData({
    ecommerce: {
      item_list_id: data.listId,
      item_list_name: data.listName,
      items,
    },
    event: eventType,
  });
}

export function sendPLPBrandEvents(eventType, data) {
  const items = data.items.reduce((res, item, index) => {
    if (item.name === PRODUCT_LIST_CUSTOMIZE_YOUR_OWN_CARD) return res;
    return [
      ...res,
      {
        brand_id: item.merchant.slug,
        index,
        item_brand: item.brandName,
        item_category: item.category[1] ? item.category[1] : item.category[0],
        item_id: item.id,
        item_list_id: data.listId,
        item_list_name: data.listName,
        item_name: item.name,
      },
    ];
  }, []);

  sendDataLayerEventData({
    ecommerce: {
      item_list_id: data.listId,
      item_list_name: data.listName,
      items,
    },
    event: eventType,
  });
}

export function sendSearchEvent(value) {
  sendDataLayerEventData({
    search_term: value,
    event: 'search',
  });
}

export function sendFilterEvent(value) {
  sendDataLayerEventData({
    content_type: 'category_filter',
    content_id: value || 'all',
    event: 'select_content',
  });
}

export function saveGACartItemsList(input) {
  const { cartItems, listId, listName } = input;
  const newLists = cartItems.reduce((_result, value) => ({ [value.external_id]: { listId, listName } }), {});
  const existingLists = LocalStorageService.get(GA_LISTS_STORE) || {};
  LocalStorageService.set(GA_LISTS_STORE, { ...existingLists, ...newLists });
}

export function addGACartItemsList(input) {
  const listId = LocalStorageService.get(GA_LIST_ID_STORE) ?? '';
  const listName = LocalStorageService.get(GA_LIST_NAME_STORE) ?? '';
  if (listId && listName) {
    const lists = {
      ...input,
      listId,
      listName,
    };
    saveGACartItemsList(lists);
  }
}

export function updateGACartItemsList(input) {
  const list = getRelatedProductListById(input.externalId);
  if (list) {
    const lists = {
      ...input,
      listId: list.item_list_id,
      listName: list.item_list_name,
    };
    saveGACartItemsList(lists);
  }
}

export function removeGACartItemList(externalId) {
  const lists = LocalStorageService.get(GA_LISTS_STORE);
  if (lists && lists[externalId]) {
    delete lists[externalId];
    LocalStorageService.set(GA_LISTS_STORE, lists);
  }
}

export function clearGACartItemList() {
  LocalStorageService.set(GA_LISTS_STORE, '');
}
