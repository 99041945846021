import { createSelector } from 'reselect';

export const selectStandardGreetingCards = createSelector(
  (state) => state.getIn(['customizationPhysical', 'standardGreetingCardsData', 'cards']),
  (state) => state,
);

export const selectStandardGreetingCardsPages = createSelector(
  (state) => state.getIn(['customizationPhysical', 'standardGreetingCardsData', 'pages']),
  (state) => state,
);

export const selectStandardGreetingCardsLimit = createSelector(
  (state) => state.getIn(['customizationPhysical', 'standardGreetingCardsData', 'limit']),
  (state) => state,
);

export const selectStandardGreetingCardsCategory = createSelector(
  (state) => state.getIn(['customizationPhysical', 'category']),
  (state) => state,
);

export const selectStandardGreetingCardsSearch = createSelector(
  (state) => state.getIn(['customizationPhysical', 'search']),
  (state) => state,
);

export const selectGreetingCardDesignFont = createSelector(
  (state) => state.getIn(['customizationPhysical', 'font']),
  (state) => state,
);

export const selectGreetingCardDesignColor = createSelector(
  (state) => state.getIn(['customizationPhysical', 'color']),
  (state) => state,
);

export const selectGreetingCardDesignSize = createSelector(
  (state) => state.getIn(['customizationPhysical', 'size']),
  (state) => state,
);

export const selectGreetingCardDesignMessage = createSelector(
  (state) => state.getIn(['customizationPhysical', 'message']),
  (state) => state,
);
