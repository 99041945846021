/* eslint-disable class-methods-use-this, max-len */
import get from 'lodash-es/get';

import { CAC_ROUTE } from 'src/routing/routes';
import { STANDARD_CARRIER } from 'src/utils/constants/customization';
import {
  CURRENT_STEP_NAME_CREATE_CUSTOMIZATION,
  CURRENT_STEP_NAME_CREATE_PDP,
  CUSTOMER_DATA_FIELDS_CARRIER,
  CUSTOMER_DATA_FIELDS_DESIGN_ID,
  CUSTOMER_DATA_FIELDS_DENOMINATION,
  CUSTOMER_DATA_FIELDS_DESIGN_URL,
  CUSTOMER_DATA_FIELDS_EMBOSS,
  CUSTOMER_DATA_FIELDS_NETWORK,
  CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME,
  NEXT_STEP_ACTION_ADD_CART,
  NEXT_STEP_ACTION_CUSTOMIZATION_OL_PHYSICAL,
  CUSTOMER_DATA_FIELDS_FROM,
  CUSTOMER_DATA_FIELDS_MESSAGE,
  CUSTOMER_DATA_FIELDS_PANEL3_TEXT,
} from 'utils/constants';

import BaseInterface from '../Base';

class CACInterface extends BaseInterface {
  showBreadcrumbs = false;

  constructor(category, product, network, additionalDescription) {
    super(category, product, additionalDescription);

    this.network = network;
    this.isMultipleNetworkExperience = true;
  }

  validateFieldsOrder = [
    CUSTOMER_DATA_FIELDS_DENOMINATION,
    CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME,
    CUSTOMER_DATA_FIELDS_EMBOSS,
    CUSTOMER_DATA_FIELDS_FROM,
    CUSTOMER_DATA_FIELDS_MESSAGE,
    CUSTOMER_DATA_FIELDS_PANEL3_TEXT,
  ];

  backendValidationConfig = {
    buildPayload: (formData) => {
      const carrier = formData.carrier ? this.getCartItemData(formData)?.customer_data?.carrier : {
        customer_data: {},
        experience: {
          category: 'paper-carrier',
          id: 'standard',
        },
      };

      return {
        items: [{
          customer_data: {
            carrier,
            denomination: formData[CUSTOMER_DATA_FIELDS_DENOMINATION],
            design: formData[CUSTOMER_DATA_FIELDS_DESIGN_ID],
            emboss_message: formData[CUSTOMER_DATA_FIELDS_EMBOSS],
            name: formData[CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME] || formData.name,
            network: this.network,
          },
          experience: {
            category: this.getProductCategory(),
            id: this.getProductId(),
          },
        }],
      };
    },
    parseError: (error) => ({
      [CUSTOMER_DATA_FIELDS_DENOMINATION]:
            get(error, 'items.children[0].children.customer_data.children.denomination.errors[0]'),
      [CUSTOMER_DATA_FIELDS_DESIGN_ID]:
            get(error, 'items.children[0].children.customer_data.children.design.errors[0]'),
      [CUSTOMER_DATA_FIELDS_EMBOSS]:
            get(error, 'items.children[0].children.customer_data.children.emboss_message.errors[0]'),
      [CUSTOMER_DATA_FIELDS_FROM]:
        get(
          error,
          'items.children[0].children.customer_data.children.carrier.children.customer_data.children.from.errors[0]',
        ),
      [CUSTOMER_DATA_FIELDS_MESSAGE]:
        get(
          error,
          'items.children[0].children.customer_data.children.carrier.children.customer_data.children.message.errors[0]',
        ),
      [CUSTOMER_DATA_FIELDS_NETWORK]:
            get(error, 'items.children[0].children.customer_data.children.network.errors[0]'),
      [CUSTOMER_DATA_FIELDS_PANEL3_TEXT]:
        get(
          error,
          'items.children[0].children.customer_data.children.carrier.children.customer_data.children.panel3_text.errors[0]',
        ),
      [CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME]:
        get(error, 'items.children[0].children.customer_data.children.name.errors[0]'),
    }),
  };

  getProductName() {
    return this.product?.name;
  }

  getProductDisplayTitle() {
    return 'Personalize your card';
  }

  getProductNetwork() {
    return this.network;
  }

  getRawCustomerData() {
    const baseData = this.rawCustomerData;
    const network = this.getProductNetwork();

    return {
      ...baseData,
      [CUSTOMER_DATA_FIELDS_CARRIER]: {
        customer_data: {},
        experience: {
          category: 'paper-carrier',
          id: 'standard',
          panel1: STANDARD_CARRIER.panel1,
        },
      },
      [CUSTOMER_DATA_FIELDS_DESIGN_ID]: '',
      [CUSTOMER_DATA_FIELDS_DESIGN_URL]: '',
      [CUSTOMER_DATA_FIELDS_EMBOSS]: '',
      [CUSTOMER_DATA_FIELDS_NETWORK]: network,
      [CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME]: '', // recipient name
    };
  }

  getProductUrl(page) {
    const id = this.getProductId();
    const category = this.getProductCategory();
    const network = this.getProductNetwork();

    const pathname = page ? `${CAC_ROUTE}/${page}` : CAC_ROUTE;
    const hash = `!/${category}/${id}/${network}`;

    return { hash, pathname };
  }

  getSteps() {
    return [
      {
        name: CURRENT_STEP_NAME_CREATE_PDP,
        nextStepAction: NEXT_STEP_ACTION_CUSTOMIZATION_OL_PHYSICAL,
        nextStepActionTitle: 'Next step',
      },
      {
        name: CURRENT_STEP_NAME_CREATE_CUSTOMIZATION,
        nextStepAction: NEXT_STEP_ACTION_ADD_CART,
        nextStepActionTitle: 'Add to cart',
      },
    ];
  }

  /**
   * Method to generate an object of cart item to create new submission.
   * Current method is valid for `brand` category.
   * @param {Object} customerData - customer data object should include denomination, quantity, etc.
   * @param {string} externalId - unique id of a currently proceeded shopping experience and customer data
   * @returns {CartItem[]}
   */
  getCartItemData({
    carrier,
    denomination,
    design,
    emboss_message: embossMessage,
    messageDesignId,
    name,
    network,
    quantity,
  }, externalId) {
    const id = this.getProductId();
    const category = this.getProductCategory();

    const panel3Text = carrier.customer_data.panel3_text;
    const panel3 = panel3Text ? carrier.customer_data.panel3 : '';

    const customerData = carrier.experience.id === STANDARD_CARRIER.id
      ? { from: carrier.customer_data.from,
        message: carrier.customer_data.message } : {
        panel3,
        panel3_text: panel3Text,
      };

    return {
      customer_data: {
        carrier: {
          customer_data: customerData,
          experience: {
            category: carrier.experience.category,
            id: carrier.experience.id,
          },
        },
        denomination,
        design,
        emboss_message: embossMessage,
        messageDesignId,
        name,
        network,
      },
      experience: { category, id },
      external_id: externalId,
      quantity,
    };
  }
}

export default CACInterface;
