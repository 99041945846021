export const GET_VIRTUAL_DESIGN_CATEGORIES_SAGA = '@@gcfront/GET_VIRTUAL_DESIGN_CATEGORIES_SAGA';
export const SET_VIRTUAL_DESIGN_CATEGORIES_DATA = '@@gcfront/SET_VIRTUAL_DESIGN_CATEGORIES_DATA';
export const SET_ALL_DESIGN_ITEMS_DATA = '@@gcfront/SET_ALL_DESIGN_ITEMS_DATA';
export const SET_VIRTUAL_DESIGN_CATEGORY_DATA = '@@gcfront/SET_VIRTUAL_DESIGN_CATEGORY_DATA';
export const GET_VIRTUAL_DESIGN_SAGA = '@@gcfront/GET_VIRTUAL_DESIGN_SAGA';
export const GET_VIRTUAL_DESIGNS_SAGA = '@@gcfront/GET_VIRTUAL_DESIGNS_SAGA';
export const SET_VIRTUAL_DESIGNS_DATA = '@@gcfront/SET_VIRTUAL_DESIGNS_DATA';
export const SET_VIRTUAL_DESIGN_DATA = '@@gcfront/SET_VIRTUAL_DESIGN_DATA';
export const SET_VIRTUAL_DESIGN_PAGE_DATA = '@@gcfront/SET_VIRTUAL_DESIGN_PAGE_DATA';

// Customization loading ids
// TODO: currrently unused. Remove if redundant
export const FETCH_VIRTUAL_DESIGN_LOADING = 'FETCH_VIRTUAL_DESIGN_LOADING';

export const STANDARD_CARRIER = {
  amount: 'Free',
  id: 'standard',
  name: 'Standard Carrier',
  panel1: { alt_text: 'Standard carrier',
    preview: { url: 'https://gccdn.giftcards.com/greeting/carrier-preview-bhn.jpg' } },
  src: 'https://gccdn.giftcards.com/greeting/carrier-preview-bhn.jpg',
};

export const DEFAULT_CATEGORY = { id: 0, name: 'Show All', value: 0 };
export const VIRTUAL_DESIGN_VIDEO_TYPE = 'standard_video';

export const PANEL_3_TEXT_MAX_LENGTH = 350;
export const MESSAGE_MAX_LENGTH = 150;

export const DEFAULT_GREETING_CARD_FONT = 'Tahoma, Arial, cursive';
export const DEFAULT_GREETNIG_CARD_COLOR = '015ab2';
export const DEFAULT_GREETING_CARD_SIZE = '20px';

export const PANEL1 = 'panel1';
export const PANEL2 = 'panel2';
export const PANEL3 = 'panel3';

// eslint-disable-next-line max-len
export const TOO_LONG_GREETING_MESSAGE = 'Your entire message may not be printed. Check the preview and adjust the message length or font/size if needed.';

export const GREETING_MESSAGE_COLOR_OPTIONS = [
  { code: '000000', msgClass: 'black-msg', name: 'Black', selectClass: 'black-bg' },
  { code: 'e32d6f', msgClass: 'pink-msg', name: 'Pink', selectClass: 'pink-bg' },
  { code: '87388f', msgClass: 'purple-msg', name: 'Purple', selectClass: 'purple-bg' },
  { code: DEFAULT_GREETNIG_CARD_COLOR, msgClass: 'dark-blue-msg', name: 'Dark Blue', selectClass: 'dark-blue-bg' },
  { code: '1493d1', msgClass: 'light-blue-msg', name: 'Light Blue', selectClass: 'light-blue-bg' },
  { code: '00785c', msgClass: 'dark-green-msg', name: 'Dark Green', selectClass: 'dark-green-bg' },
  { code: '5baf3c', msgClass: 'light-green-msg', name: 'Light Green', selectClass: 'light-green-bg' },
  { code: 'ff6d1d', msgClass: 'orange-msg', name: 'Orange', selectClass: 'orange-bg' },
  { code: 'e62337', msgClass: 'red-msg', name: 'Red', selectClass: 'red-bg' },
];

export const GREETING_FONT_OPTIONS = [
  { family: 'Lucida Handwriting, Dancing Script, Corsiva, cursive', msgClass: 'lucida', name: 'Lucida Handwriting' },
  { family: 'Finger Paint, cursive', msgClass: 'finger-paint', name: 'Finger Paint' },
  { family: 'Comic Sans MS, Handlee, cursive', msgClass: 'comic-sans', name: 'Comic Sans MS' },
  { family: 'Monotype Corsiva, Italianno, cursive', msgClass: 'monotype', name: 'Monotype Corsiva' },
  { family: DEFAULT_GREETING_CARD_FONT, msgClass: 'tahoma', name: 'Tahoma' },
  { family: 'Verdana, sans-serif', msgClass: 'verdana', name: 'Verdana' },
];

export const GREETING_FONT_SIZE_OPTIONS = [
  { id: 'radio_xsmall', label: 'A', name: 'Extra Small', size: '14px' },
  { id: 'radio_small', label: 'A', name: 'Small', size: '16px' },
  { id: 'radio_medium', label: 'A', name: 'Medium', size: DEFAULT_GREETING_CARD_SIZE },
  { id: 'radio_large', label: 'A', name: 'Large', size: '22px' },
  { id: 'radio_xlarge', label: 'A', name: 'Extra Large', size: '24px' },
];
