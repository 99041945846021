import { GOOGLE_ANALYTICS_VIEW_ITEM_PDP,
  GOOGLE_ANALYTICS_CART,
  GOOGLE_ANALYTICS_CHECKOUT } from 'src/utils/constants';

export const setGoogleAnalyticsPdpEvent = (eventType, actionType, payload) => ({
  actionType,
  eventType,
  payload,
  type: GOOGLE_ANALYTICS_VIEW_ITEM_PDP,
});

export const setGoogleAnalyticsCartEvent = (eventType, actionType, payload) => ({
  actionType,
  eventType,
  payload,
  type: GOOGLE_ANALYTICS_CART,
});

export const setGoogleAnalyticsCheckoutEvent = (eventType, actionType, payload) => ({
  actionType,
  eventType,
  payload,
  type: GOOGLE_ANALYTICS_CHECKOUT,
});
