import { call, select, takeEvery, put, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { clearCurrentShoppingExperience } from 'src/app/actions/currentShoppingExperienceActions';
import { clearCustomerData } from 'src/app/actions/customerDataActions';
import { setLoadingById } from 'src/app/actions/loadingActions';
import { clearShoppingExperience } from 'src/app/actions/shoppingExperiencesActions';
import { setCurrentSubmission, setSubmissionFocusItemId } from 'src/app/actions/submissionActions';
import { setCurrentSubmissionItems } from 'src/app/actions/submissionItemsActions';
import { selectCurrentShoppingExperienceCategory,
  selectCurrentShoppingExperienceExternalId } from 'src/app/selectors/currentShoppingExperienceSelectors';
import { selectCustomerDataCarrier, selectCustomerDataObject,
  selectCustomerDataQuantity } from 'src/app/selectors/customerDataSelectors';
import { selectShoppingExperience } from 'src/app/selectors/shoppingExperiencesSelectors';
import { push } from 'src/routing/routing';
import productFactory from 'src/services/productFactory';
import { updateSubmissionItem } from 'src/services/submissionsApi';
import { updateGACartItemsList } from 'src/utils/googleAnalytics';
import { EDIT_SUBMISSION_ITEM_SAGA, SAVE_SUBMISSION_ITEM_LOADING } from 'utils/constants/submission';
import { getCookieValue } from 'utils/cookies';

import { addSubmissionItemToStore } from './fetchCurrentSubmission';

/**
 * Saga to edit submission item.
 * It operates with a current shopping experience and current submission.
 * @todo Add handling error actions
 */
function* makeFetch(action) {
  try {
    yield put(setLoadingById(SAVE_SUBMISSION_ITEM_LOADING, true));
    // Step 1. Retrieve shopping experience and customer data from redux store.
    const externalId = yield select(selectCurrentShoppingExperienceExternalId);
    const experienceCategory = yield select(selectCurrentShoppingExperienceCategory);
    const experienceData = yield select(selectShoppingExperience);
    const customerData = yield select(selectCustomerDataObject);
    const customerDataQuantity = yield select(selectCustomerDataQuantity);
    const carrier = yield select(selectCustomerDataCarrier);
    const submissionId = yield call(getCookieValue, 'submission_id');

    // Step 2. Prepare shopping experience and customer data to add it to submission.
    const productInterface = yield call(productFactory, experienceCategory, experienceData);

    const items = yield all(Array.from({ length: customerDataQuantity }, (_, i) => call(
      [productInterface, productInterface.getCartItemData],
      { ...customerData, carrier, quantity: 1 },
      i === 0 ? externalId : uuidv4(),
    )));

    const cartToken = yield call(getCookieValue, 'gc_cart_token');

    const submissionData = {
      deliveries: [],
      items: [...items],
      riskified_beacon_token: cartToken,
    };

    updateGACartItemsList({ cartItems: submissionData.items, externalId });

    const { data } = yield call(
      updateSubmissionItem,
      submissionId,
      submissionData,
    );

    /**
     * Step 3.
     * * Clear the existing data (shoppingExperiences, customerData, etc.).
     * * Set up the updated submission data, that will be used on cart page.
     * * Redirect user on shopping cart page.
     */
    if (data) {
      yield put(clearCurrentShoppingExperience());
      yield put(clearShoppingExperience());
      yield put(clearCustomerData());

      yield put(setCurrentSubmission(data));
      yield put(setCurrentSubmissionItems(data));
      yield put(setSubmissionFocusItemId(data));

      yield all(data._embedded.items.map((item) => call(addSubmissionItemToStore, item)));

      if (action && action.redirect) {
        yield call(action.redirect);
      } else {
        yield call(push, '/shopping-cart');
      }
    }
  } catch (error) {
    // TODO: add error handling
  } finally {
    yield put(setLoadingById(SAVE_SUBMISSION_ITEM_LOADING, false));
  }
}

export default function* watchFetch() {
  yield takeEvery(EDIT_SUBMISSION_ITEM_SAGA, makeFetch);
}
