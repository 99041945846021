import { EDIT_CURRENT_SUBMISSION_ITEM_SAGA,
  SET_CURRENT_SUBMISSION_ITEMS,
  DUPLICATE_CURRENT_SUBMISSION_ITEM_SAGA,
  REMOVE_CURRENT_SUBMISSION_ITEM_SAGA } from 'utils/constants/submission';

export const setCurrentSubmissionItems = (payload) => ({
  payload,
  type: SET_CURRENT_SUBMISSION_ITEMS,
});

export const editCurrentSubmissionItemSaga = (externalId, page) => ({
  externalId,
  page,
  type: EDIT_CURRENT_SUBMISSION_ITEM_SAGA,
});

export const duplicateCurrentSubmissionItemSaga = (externalId) => ({
  externalId,
  type: DUPLICATE_CURRENT_SUBMISSION_ITEM_SAGA,
});

export const removeCurrentSubmissionItemSaga = (externalId) => ({
  externalId,
  type: REMOVE_CURRENT_SUBMISSION_ITEM_SAGA,
});
