import Colors from '../palette/Colors';

/**
 * `Button` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/button/}
 */
const MuiButton = Object.freeze({
  defaultProps: {
    color: 'primary',
    size: 'large',
    variant: 'contained',
  },
  styleOverrides: {
    contained: {},
    outlined: {},
    outlinedPrimary: {
      '&:hover': {
        borderWidth: 2,
      },
      borderColor: Colors.PurpleLight,
      borderWidth: 2,
      color: Colors.PurpleDark,
    },
    outlinedSecondary: {
      '&:hover': {
        borderColor: Colors.PurpleMain,
        borderWidth: 1,
      },
      backgroundColor: Colors.CyanBlueLight2,
      borderColor: Colors.CyanBlueMain,
      borderWidth: 1,
    },
    root: {
      // fontFamily: 'inherit',
      borderRadius: 1000,
      textDecoration: 'none',
      textTransform: 'none',
    },
    sizeLarge: {
      fontSize: 20,
      fontWeight: 800,
      lineHeight: '23px',
      minHeight: 62,
      paddingBottom: 17,
      paddingLeft: 47,
      paddingRight: 47,
      paddingTop: 17,
    },
    sizeMedium: {
      fontSize: 15,
      fontWeight: 800,
      lineHeight: '17px',
      minHeight: 29,
      paddingBottom: 6,
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 6,
    },
    sizeSmall: {
      fontSize: 12,
      fontWeight: 700,
      height: 24,
      lineHeight: '13.8px',
      minHeight: 24,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
    },
    startIcon: {
      '&.MuiButton-iconSizeLarge>svg:nth-of-type(1)': {
        fontSize: 33,
      },
    },
    textPrimary: {
      color: Colors.PurpleMain,
    },
    textSizeMedium: {
      fontSize: 16,
    },
  },
});

export default MuiButton;
