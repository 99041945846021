/* eslint global-require: 0 */
/* eslint consistent-return: 0 */
// Check if polyfills are required
export default function loadPolyfills() {
    const fillIntl = () => new Promise((resolve) => {
        if (window.Intl) {
            return resolve();
        }

        require.ensure([], () => {
            require('intl');
            resolve();
        }, 'Intl');
    });

    const fillUrlSearchParms = () => new Promise((resolve) => {
        if (window.URLSearchParams) return resolve();

        require.ensure([], () => {
            window.URLSearchParams = require('@ungap/url-search-params');
            return resolve();
        }, 'URLSearchParams');
    });

    const fillRequestAnimationFrame = () => new Promise((resolve) => {
        if (window.requestAnimationFrame) return resolve();

        require.ensure([], () => {
            window.requestAnimationFrame = require('raf').polyfill();
            resolve();
        }, 'requestAnimationFrame');
    });

    return Promise.all([
        fillIntl(),
        fillUrlSearchParms(),
        fillRequestAnimationFrame()
    ]);
}
