/* eslint-disable react/prop-types */
import React from 'react';

import { StepIcon } from '@mui/material';

import Colors from '../../palette/Colors';
import fonts from '../../typography/fonts';

/**
 * `StepLabel` component theme overrides/
 *
 * @see {@link https://mui.com/material-ui/api/step-label/}
 */
const MuiStepLabel = Object.freeze({
  defaultProps: {
    StepIconComponent: ({ active, completed, ...rest }) => (
      <StepIcon
        {...rest}
        active={completed || active}
        completed={false}
      />
    ),
  },
  styleOverrides: {
    label: {
      ...fonts.h3,
      '&.Mui-active, &.Mui-completed': {
        color: Colors.PurpleDark,
      },
      color: Colors.GreyDark,
    },
    labelContainer: {
      paddingBottom: 4,
    },
  },
});

export default MuiStepLabel;
