import { get } from 'lodash-es';
import { createSelector } from 'reselect';

import {
  CUSTOMER_DATA_FIELDS_CARRIER,
  CUSTOMER_DATA_FIELDS_DELIVERYDATE,
  CUSTOMER_DATA_FIELDS_DENOMINATION,
  CUSTOMER_DATA_FIELDS_DESIGN_ID,
  CUSTOMER_DATA_FIELDS_DESIGN_URL,
  CUSTOMER_DATA_FIELDS_EMBOSS,
  CUSTOMER_DATA_FIELDS_MESSAGE,
  CUSTOMER_DATA_FIELDS_NETWORK,
  CUSTOMER_DATA_FIELDS_QUANTITY,
  CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL,
  CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME,
  CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE,
  CUSTOMER_DATA_FIELDS_DESIGN_TYPE,
  CUSTOMER_DATA_FIELDS_DESIGN_VIDEO_SOURCES,
  CUSTOMER_DATA_FIELDS_DESIGN_VIDEO_TRACKS,
} from 'utils/constants';

import { selectCurrentShoppingExperienceId } from './currentShoppingExperienceSelectors';

const selectCustomerDataState = (state) => state.get('customerData');
export const selectCustomerData = createSelector(
  [
    selectCustomerDataState,
    selectCurrentShoppingExperienceId,
  ],
  (customerData, shoppingExperienceId) => customerData.getIn(['byId', shoppingExperienceId]),
);

export const selectCustomerDataById = createSelector(
  [
    selectCustomerDataState,
    (_, customerDataId) => customerDataId,
  ],
  (customerData, customerDataId) => customerData
    .getIn(['byId', customerDataId])
    ?.toObject(),
);

export const selectCustomerDataIdExists = createSelector(
  [
    selectCustomerDataState,
    (_, customerDataId) => customerDataId,
  ],
  (customerData, customerDataId) => customerData.hasIn(['byId', customerDataId]),
);

export const selectCustomerDataObject = createSelector(
  selectCustomerData,
  (data) => data?.toObject(),
);

export const selectCustomerDataQuantity = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_QUANTITY),
);

export const selectCustomerDataDenomination = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_DENOMINATION),
);

export const selectRecipientInfoFullName = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME),
);

export const selectRecipientInfoEmail = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL),
);

export const selectRecipientInfoPhone = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE),
);

export const selectRecipientInfoMessage = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_MESSAGE),
);

export const selectDeliveryDate = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_DELIVERYDATE),
);

export const selectCustomerDataNetwork = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_NETWORK),
);

export const selectCustomerDataEmbossMessage = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_EMBOSS),
);

export const selectCustomerDataDesignId = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_DESIGN_ID),
);

export const selectCustomerDataDesignType = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_DESIGN_TYPE),
);

export const selectCustomerDataDesignLink = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_DESIGN_URL),
);

export const selectCustomerDataDesignVideoSources = createSelector(
  selectCustomerData,
  (data) => data
    ?.get(CUSTOMER_DATA_FIELDS_DESIGN_VIDEO_SOURCES)
    ?.map((item) => item.toObject())
    .toArray(),
);

export const selectCustomerDataDesignVideoTracks = createSelector(
  selectCustomerData,
  (data) => data?.get(CUSTOMER_DATA_FIELDS_DESIGN_VIDEO_TRACKS)?.toArray(),
);

export const selectCustomerDataJSObject = createSelector(
  selectCustomerData,
  (data) => data?.toJS(),
);

export const selectCustomerDataCarrier = createSelector(
  selectCustomerDataJSObject,
  (data) => get(data, CUSTOMER_DATA_FIELDS_CARRIER, {}),
);
