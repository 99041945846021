import React, { memo } from 'react';

import MailCheck from 'react-mailcheck';

import InputWithDebounce from 'src/components/common/InputWithDebounce';
import { onKeydownValidateEmail, onPasteValidateEmail } from 'utils/keydown';
// TODO: We will have to reconsider using a different library.
//  This is one used on production currently but it is pretty old and we might search for a modern alternative
//  There are deprecated warnings in the console

// eslint-disable-next-line react/prop-types
function InputEmailWithSuggestion({ inputProps = {}, inputRef, onChange, outerValue, ...props }) {
  return (
    <MailCheck email={outerValue || ''}>
      {(suggestion) => (
        <InputWithDebounce
          {...props}
          FormHelperTextProps={{
            onClick() {
              if (suggestion) {
                onChange(suggestion.full);
              }
            },
          }}
          helperText={suggestion ? `Did you mean "${suggestion.full}"?` : ''}
          inputProps={{ inputMode: 'email', ...inputProps }}
          inputRef={inputRef}
          onChange={onChange}
          onKeyDown={onKeydownValidateEmail}
          onPaste={onPasteValidateEmail}
          outerValue={outerValue}
        />
      )}
    </MailCheck>
  );
}

export default memo(InputEmailWithSuggestion);
