import set from 'lodash-es/set';

class LocalStorageService {
  static add(path, value) {
    if (!Array.isArray(path)) {
      // eslint-disable-next-line no-console
      console.error('`path` should be an array');

      return;
    }

    const currentStore = this.get(path[0]) || {};
    set(currentStore, path.slice(1), value);

    this.set(path[0], currentStore);
  }

  static set(key, value) {
    if (!key) return;
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  }

  static get(key, otherwise) {
    const data = localStorage.getItem(key);

    if (data !== null) {
      try {
        const result = JSON.parse(data);
        return result;
      } catch (e) {
        return data;
      }
    }

    if (otherwise) return otherwise;

    return null;
  }

  static remove(key) {
    localStorage.removeItem(key);
  }
}

export default LocalStorageService;
