import { call, put, debounce, select } from 'redux-saga/effects';

import { setStandardGreetingCardsData } from 'src/app/actions/customizationPhysicalActions';
import { setLoadingById } from 'src/app/actions/loadingActions';
import { selectStandardGreetingCardsCategory } from 'src/app/selectors/customizationPhysicalSelectors';
import { getGreetingCards } from 'src/services/customizationApi';
import { DEFAULT_CATEGORY } from 'src/utils/constants/customization';
import { FETCH_STANDARD_GREETING_CARDS_LOADING, GET_STANDARD_GREETING_CARDS_SAGA } from 'utils/constants';

function* makeFetch(action) {
  try {
    yield put(setLoadingById(FETCH_STANDARD_GREETING_CARDS_LOADING, true));
    const category = yield select(selectStandardGreetingCardsCategory);

    const {
      payload: {
        categoryId,
        text,
        ...defaultPayload
      },
    } = action;
    const body = { ...defaultPayload };

    if ((category.value !== DEFAULT_CATEGORY.value) && !text) {
      body.categories = categoryId;
    } else if (text) {
      body.text = text;
    }

    const response = yield call(getGreetingCards, body);

    yield put(setStandardGreetingCardsData(response.data));
  } catch (e) {
    // TODO: Add error handling
  } finally {
    yield put(setLoadingById(FETCH_STANDARD_GREETING_CARDS_LOADING, false));
  }
}

export default function* watchGetStandardGreetingCard() {
  yield debounce(500, GET_STANDARD_GREETING_CARDS_SAGA, makeFetch);
}
