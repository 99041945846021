import {
  GET_VIRTUAL_DESIGN_CATEGORIES_SAGA,
  GET_VIRTUAL_DESIGNS_SAGA,
  GET_VIRTUAL_DESIGN_SAGA,
  SET_VIRTUAL_DESIGN_CATEGORY_DATA,
  SET_VIRTUAL_DESIGN_CATEGORIES_DATA,
  SET_VIRTUAL_DESIGNS_DATA,
  SET_VIRTUAL_DESIGN_DATA,
  SET_VIRTUAL_DESIGN_PAGE_DATA,
} from 'utils/constants/customization';

export const getVirtualDesignCategoriesSaga = () => ({
  type: GET_VIRTUAL_DESIGN_CATEGORIES_SAGA,
});

export const getVirtualDesignsSaga = (payload = {}) => ({
  payload,
  type: GET_VIRTUAL_DESIGNS_SAGA,
});

export const getVirtualDesignSaga = (payload = {}) => ({
  payload,
  type: GET_VIRTUAL_DESIGN_SAGA,
});

export const setVirtualDesignCategoriesData = (payload) => ({
  payload,
  type: SET_VIRTUAL_DESIGN_CATEGORIES_DATA,
});

export const setVirtualDesignsData = (payload) => ({
  payload,
  type: SET_VIRTUAL_DESIGNS_DATA,
});

export const setVirtualDesignData = (payload) => ({
  payload,
  type: SET_VIRTUAL_DESIGN_DATA,
});

export const setVirtualDesignCategoryData = (payload) => ({
  payload,
  type: SET_VIRTUAL_DESIGN_CATEGORY_DATA,
});

export const setVirtualDesignPageData = (payload) => ({
  payload,
  type: SET_VIRTUAL_DESIGN_PAGE_DATA,
});
