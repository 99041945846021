import { FETCH_SHOPPING_EXPERIENCE_SAGA,
  ADD_SHOPPING_EXPERIENCE,
  REMOVE_SHOPPING_EXPERIENCE,
  CLEAR_SHOPPING_EXPERIENCES,
  SET_SHOPPING_EXPERIENCE_DISPLAY_IMAGE } from 'utils/constants';

export const fetchShoppingExperienceSaga = (experienceCategory, experienceId, experienceNetwork) => ({
  experienceCategory,
  experienceId,
  experienceNetwork,
  type: FETCH_SHOPPING_EXPERIENCE_SAGA,
});

export const addShoppingExperience = (shoppingExperienceId, payload) => ({
  payload,
  shoppingExperienceId,
  type: ADD_SHOPPING_EXPERIENCE,
});

/**
 * TODO: remove if not required.
 */
export const removeShoppingExperience = (shoppingExperienceId) => ({
  shoppingExperienceId,
  type: REMOVE_SHOPPING_EXPERIENCE,
});

export const clearShoppingExperience = () => ({
  type: CLEAR_SHOPPING_EXPERIENCES,
});

export const setShoppingExperienceDisplayImage = (shoppingExperienceId, displayImage) => ({
  displayImage,
  shoppingExperienceId,
  type: SET_SHOPPING_EXPERIENCE_DISPLAY_IMAGE,
});
