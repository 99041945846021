import Colors from '../../palette/Colors';

/**
 * `AccordionSummary` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/accordion-summary/}
 */
const MuiAccordionSummary = Object.freeze({
  styleOverrides: {
    content: {
      marginBottom: 20,
      marginTop: 20,
    },
    expandIconWrapper: {
      color: Colors.PurpleMain,
      fontSize: 24,
    },
    root: {
      backgroundColor: 'transparent',
      padding: 0,
    },
  },
});

export default MuiAccordionSummary;
