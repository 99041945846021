import { alpha } from '@mui/material/styles';

import Colors from '../palette/Colors';
import fonts from '../typography/fonts';

/**
 * `Chip` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/chip/}
 */
const MuiChip = Object.freeze({
  defaultProps: {
    clickable: true,
    variant: 'outlined',
  },
  styleOverrides: {
    label: {
      ...fonts.body1,
      fontWeight: 800,
    },
    outlinedPrimary: {
      backgroundColor: alpha(Colors.PurpleMain, 0.1),
      borderWidth: 2,
    },
    outlinedSecondary: {
      backgroundColor: Colors.CyanBlueLight2,
      color: Colors.CyanBlueMain,
    },
    root: {
      '&:focus': {
        outline: 'none',
      },
      borderRadius: 29.5,
      height: 59,
      maxWidth: 78,
    },
  },
});

export default MuiChip;
