/**
 * `MuiListItemIcon` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/list-item-icon/}
 */
const MuiListItemIcon = Object.freeze({
  styleOverrides: {
    root: {
      color: 'inherit',
      minWidth: 'fit-content',
      paddingLeft: 1,
      paddingRight: 1,
      paddingTop: '6px',
    },
  },
});

export default MuiListItemIcon;
