import Colors from '../../palette/Colors';

/**
 * `InputBase` component theme overrides. `InputBase` is a part of `TextField` composition.
 *
 * @see {@link https://mui.com/material-ui/api/input-base/}
 */
const MuiInputBase = Object.freeze({
  styleOverrides: {
    input: {
      '&[type="number"]': {
        MozAppearance: 'textfield',
      },
      '&[type="number"]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&[type="number"]::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '16px',
    },
    root: {
      '&.Mui-error': {
        color: Colors.RedMain,
      },
    },
  },
});

export default MuiInputBase;
