export const APP_START_BRANDS = '@@gcfront/APP_START_BRANDS';
export const WORKSHEET_OPEN = '@@gcfront/WOKSHEET_OPEN';
export const WORKSHEET_CLOSE = '@@gcfront/WORKSHEET_CLOSE';

export const slideoutOpen = () => ({
    type: WORKSHEET_OPEN
});

export const slideoutClose = () => ({
    type: WORKSHEET_CLOSE
});
