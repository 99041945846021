import { call, put, debounce, select } from 'redux-saga/effects';

import { setVirtualDesignsData } from 'src/app/actions/customizationVirtualActions';
import { setLoadingById } from 'src/app/actions/loadingActions';
import { selectVirtualDesignCategory } from 'src/app/selectors/customizationVirtualSelectors';
import { getVirtualDesigns } from 'src/services/customizationApi';
import LocalStorageService from 'src/services/localStorage';
import { FETCH_STANDARD_GREETING_CARDS_LOADING } from 'src/utils/constants';
import { GET_VIRTUAL_DESIGNS_SAGA, VIRTUAL_DESIGN_VIDEO_TYPE } from 'utils/constants/customization';

function* makeFetch(action) {
  try {
    const { payload: { limit, page } } = action;
    yield put(setLoadingById(FETCH_STANDARD_GREETING_CARDS_LOADING, true));
    const categoryId = yield select(selectVirtualDesignCategory);
    const isPremium = !!parseInt(LocalStorageService.get('vggtg', false), 10);
    const response = yield call(getVirtualDesigns, {
      categoryId,
      isPremium,
      limit,
      page,
    });
    response.data._embedded.items = response.data._embedded.items.filter(
      (item) => item.type === VIRTUAL_DESIGN_VIDEO_TYPE,
    );
    response.data.pages = categoryId && response.data.total > limit
      ? Math.ceil(response.data.total / limit)
      : response.data.pages;
    yield put(setVirtualDesignsData(response.data));
  } catch (e) {
    // TODO: Add error handling
  } finally {
    yield put(setLoadingById(FETCH_STANDARD_GREETING_CARDS_LOADING, false));
  }
}

export default function* watchGetVirtualDesigns() {
  yield debounce(500, GET_VIRTUAL_DESIGNS_SAGA, makeFetch);
}
