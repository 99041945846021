/**
 * `ListItem` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/list-item/}
 */
const MuiListItem = Object.freeze({
  defaultProps: {
    disableGutters: true,
    disablePadding: true,
  },
});

export default MuiListItem;
