import { Map, List } from 'immutable';

import { ADD_CUSTOMER_DATA,
  REMOVE_CUSTOMER_DATA,
  CLEAR_CUSTOMER_DATA,
  SET_CUSTOMER_DATA_BY_KEY } from '../../utils/constants';

const initialState = Map({
  allIds: List([]),
  byId: Map({}),
});

/**
 * `customerData` reducer is about to store data of user inputs (e.g. denomination, quantity, etc.).
 * Data stored in object by id. Each id refers to both customer data and shopping experience.
 *
 * When new customer data is added (see `ADD_CUSTOMER_DATA` case), make sure to pass on default object.
 * Default object varies depending on shopping experience category.
 * Use `roductFactory` service to generate default customer data object.
 *
 * Data stored in `allIds` and `byId` approach:
 * * `allIds` stores all incoming ids in `category/id` shape;
 * * `byId` stores different customer data by id;
 *
 * @see {@link https://redux.js.org/usage/structuring-reducers/normalizing-state-shape} for more details
 * about normalized state.
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CUSTOMER_DATA: {
      const { customerData, customerDataId } = action;

      const allIds = state.get('allIds').toSet().add(customerDataId);

      return state
        .setIn(['byId', customerDataId], Map({
          ...customerData,
          denomination: parseFloat(customerData.denomination),
        }))
        .set('allIds', allIds.toList());
    }
    case REMOVE_CUSTOMER_DATA: {
      const { customerDataId, key } = action;

      return state
        .removeIn(['byId', customerDataId, key]);
    }
    case SET_CUSTOMER_DATA_BY_KEY: {
      const { customerDataId, key, value } = action;

      return state
        .setIn(['byId', customerDataId, key], value);
    }
    case CLEAR_CUSTOMER_DATA:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
