import React from 'react';

import { SvgIcon } from '@mui/material';
import { PropTypes } from 'prop-types';

function CloseIcon(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M15.8736 3.88996C15.5307 3.5471 15.0165 3.5471 14.6736 3.88996L10.1307 8.43281L5.58788
          3.88996C5.24502 3.5471 4.73074 3.5471 4.38788 3.88996C4.04502 4.23281 4.04502 4.7471
          4.38788 5.08996L8.93074 9.63281L4.38788 14.1757C4.04502 14.5185 4.04502 15.0328 4.38788
          15.3757C4.55931 15.5471 4.73074 15.6328 4.98788 15.6328C5.24502 15.6328 5.41645 15.5471
          5.58788 15.3757L10.1307 10.8328L14.6736 15.3757C14.845 15.5471 15.1022 15.6328 15.2736
          15.6328C15.445 15.6328 15.7022 15.5471 15.8736 15.3757C16.2165 15.0328 16.2165 14.5185
          15.8736 14.1757L11.3307 9.63281L15.8736 5.08996C16.2165 4.7471 16.2165 4.23281 15.8736 3.88996Z"
        fill="white"
      />
      <path d="M2 2L18.2635 18.2635" stroke="currentColor" strokeLinecap="round" strokeWidth="3" />
      <path d="M18.2634 2L1.99997 18.2635" stroke="currentColor" strokeLinecap="round" strokeWidth="3" />
    </SvgIcon>
  );
}

CloseIcon.propTypes = {
  titleAccess: PropTypes.string,
};

CloseIcon.defaultProps = {
  titleAccess: 'Close',
};

export default CloseIcon;
