import Colors from '../../palette/Colors';

/**
 * `CardContent` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/card-content/}
 */
const MuiCardContent = Object.freeze({
  styleOverrides: {
    root: {
      backgroundColor: Colors.White,
      paddingBottom: 8,
      paddingTop: 8,
    },
  },
});

export default MuiCardContent;
