import { Map, List, fromJS } from 'immutable';

import {
  SET_SUBMISSION_TERMS,
  SET_CURRENT_SUBMISSION,
  SET_CURRENT_SUBMISSION_ID,
  SET_SUBMISSION_CHECKOUT_ID,
  SET_SUBMISSION_POTENTIAL_REWARDS,
  SET_SUBMISSION_FOCUS_ITEM_ID,
  CLEAR_SUBMISSION_FOCUS_ITEM_ID,
  SET_SUBMISSION_ERRORS,
} from 'utils/constants/submission';

const initialState = Map({
  chargeTotal: 0,
  chargeTotals: Map({}),
  charges: List([]),
  checkoutId: '',
  focusItemId: '',
  id: '',
  potentialReward: 0,
  /**
   * TODO: no need in this field. Only one submission error should be displayed at the same time.
   * Use feedback reducer.
   */
  submissionErrors: List([]),
  terms: '',
});

/**
 * `submission` reducer replaces the old ones: `cart` and `submission` reducers.
 * It holds general information about the current submission and references to:
 * * shopping experience;
 * * customer data;
 *
 * In data structure `externalId` (or `external_id` from a raw object)
 * is used to populate data on `customerData` and `shoppingExperiences`.
 * Subsequently, it's dedicated to retrieve a proper `customerData` or `shoppingExperiences`
 * from the corresponding reducer.
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_SUBMISSION: {
      const { payload } = action;
      const { _embedded, id } = payload;

      return state
        .set('id', id)
        .set('chargeTotal', _embedded.charge_total)
        .set('chargeTotals', Map(_embedded.charge_totals))
        .set('charges', fromJS(_embedded.charges))
        .set('submissionErrors', fromJS(_embedded.submission_errors))
        .set('totalCartItemsCount', _embedded.item_count);
    }
    case SET_SUBMISSION_ERRORS: {
      const { payload } = action;

      return state.set('submissionErrors', List(payload));
    }
    case SET_CURRENT_SUBMISSION_ID:
      return state.set('id', action.payload);
    case SET_SUBMISSION_CHECKOUT_ID:
      return state.set('checkoutId', action.payload);
    case SET_SUBMISSION_TERMS:
      return state.set('terms', action.payload);
    case SET_SUBMISSION_POTENTIAL_REWARDS: {
      const { payload } = action;
      return state.set('potentialReward', payload.total);
    }
    case SET_SUBMISSION_FOCUS_ITEM_ID: {
      const { payload } = action;
      const { items } = payload._embedded;
      const maxDateModified = Math.max(...items.map(
        (element) => (new Date(element?.date_modified).getTime()),
      ));

      const maxItem = items.find((x) => (new Date(x?.date_modified).getTime() === maxDateModified));

      return state.set('focusItemId', maxItem?.external_id);
    }
    case CLEAR_SUBMISSION_FOCUS_ITEM_ID: {
      return state.set('focusItemId', '');
    }
    default:
      return state;
  }
}

export default reducer;
