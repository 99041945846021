import React, { useEffect, memo, useState } from 'react';

import {
  Box,
  Button,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setGoogleAnalyticsPdpEvent } from 'src/app/actions/googleAnalyticsActions';
import { CATEGORY_CAC,
  CATEGORY_PREDESIGN } from 'src/brands/card_purchase/cart/item/experienceCategories';
import { fetchFeesData } from 'src/services/shoppingExperienceApi';
import { ACTION_FEES_OPEN, ACTION_FEES_CLOSE } from 'src/utils/constants/googleAnalytics';

import CloseIcon from '../../icons/CloseIcon';

function CardFeesPopover({ category, hasError, hasFees, shoppingExperienceId }) {
  const [feesData, setFeesData] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const open = Boolean(popoverAnchorEl);

  const dispatch = useDispatch();

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
    dispatch(setGoogleAnalyticsPdpEvent(ACTION_FEES_OPEN)); // Dispatch GA open event
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    dispatch(setGoogleAnalyticsPdpEvent(ACTION_FEES_CLOSE)); // Dispatch GA close event
  };

  const mapDataToPriceRangesAndFees = (data) => data
    .filter((item) => [CATEGORY_PREDESIGN, CATEGORY_CAC].includes(item.experience_category.name))
    .map((item, index, feeItems) => {
      if (index >= feeItems.length - 2) {
        return null;
      }

      let priceRange;

      if (index === 0) {
        const nextThresholdMinusOneCent = (feeItems[index + 1].threshold - 0.01).toFixed(2);
        priceRange = `$10.00 - $${nextThresholdMinusOneCent}`;
      } else if (index === feeItems.length - 3) {
        priceRange = `$${item.threshold.toFixed(2)} - $250`;
      } else {
        const nextThresholdMinusOneCent = (feeItems[index + 1].threshold - 0.01).toFixed(2);
        priceRange = `$${item.threshold.toFixed(2)} - $${nextThresholdMinusOneCent}`;
      }

      return {
        fee: item.amount.toFixed(2),
        priceRange,
      };
    })
    .filter((item) => item);

  useEffect(() => {
    const cleanedExperienceId = shoppingExperienceId.replace(/^.+\//, '');
    fetchFeesData(category, cleanedExperienceId)
      .then((response) => {
        if ([CATEGORY_PREDESIGN, CATEGORY_CAC].includes(category)) {
          const mappedData = mapDataToPriceRangesAndFees(response.data);
          setFeesData(mappedData);
        } else {
          setFeesData(response.data);
        }
      });
  }, [category, shoppingExperienceId]);

  const isAcceptableCategory = category === CATEGORY_CAC || category === CATEGORY_PREDESIGN;

  const renderTableRows = () => {
    if (category === CATEGORY_CAC) {
      return feesData.map((fee, index) => (
        <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'grey.100' : 'transparent' }}>
          <TableCell align="left">{fee.priceRange}</TableCell>
          <TableCell align="left">
            $
            {(parseFloat(fee.fee) - 1).toFixed(2)}
          </TableCell>
          <TableCell align="left">$1.00</TableCell>
          <TableCell align="left">
            $
            {fee.fee}
          </TableCell>
        </TableRow>
      ));
    }
    if (category === CATEGORY_PREDESIGN) {
      return feesData.map((fee, index) => (
        <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'grey.100' : 'transparent' }}>
          <TableCell align="left">{fee.priceRange}</TableCell>
          <TableCell align="left">
            $
            {fee.fee}
          </TableCell>
        </TableRow>
      ));
    }
    return feesData.map((fee, index) => (
      <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? 'grey.100' : 'transparent' }}>
        <TableCell align="left">$10.00 - $250   </TableCell>
        <TableCell align="left">
          $
          {fee.amount}
        </TableCell>
      </TableRow>
    ));
  };

  return (isAcceptableCategory || hasFees) && (
    <>
      <Button
        aria-describedby="card-fees-popover"
        className="p-0 font-normal text-[13px] underline"
        disabled={hasError}
        onClick={handlePopoverOpen}
        size="medium"
        variant="text"
      >
        View fees
      </Button>
      {feesData && (
        <Popover
          anchorEl={popoverAnchorEl}
          onClose={handlePopoverClose}
          open={open}
        >
          <Box className="flex flex-row justify-between">
            <Typography variant="h3">Card fees</Typography>
            <IconButton className="p-2.5" onClick={handlePopoverClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className="p-2.5">
            {category === CATEGORY_CAC && (
              <Typography className="pb-2.5" variant="h5">Customized Visa or Mastercard</Typography>
            )}
            <Table mt={5}>
              <TableHead>
                <TableRow>
                  {category === CATEGORY_CAC ? (
                    <>
                      <TableCell align="left">
                        <Typography fontWeight="bold">Card value</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography fontWeight="bold">Card fee</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography fontWeight="bold">Customization fee</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography fontWeight="bold">Total fee</Typography>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell align="left">
                        <Typography fontWeight="bold">Card value</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography fontWeight="bold">Fee per card</Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {renderTableRows()}
              </TableBody>
            </Table>
          </Box>
        </Popover>
      )}
    </>
  );
}

CardFeesPopover.propTypes = {
  category: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  hasFees: PropTypes.bool,
  shoppingExperienceId: PropTypes.string.isRequired,
};

CardFeesPopover.defaultProps = {
  hasError: false,
  hasFees: false,
};

export default memo(CardFeesPopover);
