import React from 'react';

import { createRoot } from 'react-dom/client';

import loadPolyfills from './app/polyfills';
import QuickBuyApp from './quickBuy/QuickBuyApp';
import './index.css';

const run = async () => {
  const mountNode = document.getElementById('quick-buy-app-root');
  let root;

  function unmountQuickBuyApp() {
    root.unmount();
  }

  function mountQuickBuyApp() {
    root = createRoot(mountNode);
    root.render(<QuickBuyApp />);
  }

  window.unmountQuickBuyApp = unmountQuickBuyApp;
  window.mountQuickBuyApp = mountQuickBuyApp;
};

loadPolyfills().then(() => {
  run();
});
