import { Map } from 'immutable';

import { LOCATION_CHANGE } from 'src/routing/routing';
import { APP_SPINNER_LOADING, SET_LOADING_BY_ID } from 'utils/constants';

const initialState = Map({});

/**
 * `loading` reducer is about to store data for different sagas.
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_BY_ID: {
      const { id, isLoading } = action;

      return state.set(id, isLoading);
    }
    case LOCATION_CHANGE: {
      /**
       * TODO: verify the usage of LOCATION_CHANGE and APP_START_BRANDS actions. Probably the redundant functionality.
       * According to the old codebase, it stops the global spinner
       */
      return state.set(APP_SPINNER_LOADING, false);
    }
    default:
      return state;
  }
}

export default reducer;
