import { alpha } from '@mui/material/styles';

import Colors from '../palette/Colors';

/**
 * `ToggleButton` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/toggle-button/}
 */
const MuiToggleButton = Object.freeze({
  defaultProps: {},
  styleOverrides: {
    root: {
      '&.MuiToggleButton-primary': {
        '&.Mui-selected': {
          '&:hover': {
            backgroundColor: alpha(Colors.PurpleDark, 0.8),
          },
          backgroundColor: Colors.PurpleDark,
          color: Colors.White,
        },
        borderColor: Colors.PurpleDark,
      },
      '&.MuiToggleButton-secondary': {
        '&.Mui-selected': {
          '&:hover': {
            backgroundColor: alpha(Colors.CyanBlueMain, 0.8),
          },
          backgroundColor: Colors.CyanBlueMain,
          color: Colors.White,
        },
        borderColor: Colors.CyanBlueMain,
      },
      borderRadius: 'inherit',
      columnGap: 10,
      textTransform: 'none',
    },
    sizeLarge: {
      fontSize: 20,
      fontWeight: 800,
      lineHeight: '21px',
      paddingBottom: 18,
      paddingTop: 18,
    },
  },
});

export default MuiToggleButton;
