import { themeWithBreakpoints } from '../../breakpoints';
import Colors from '../../palette/Colors';

/**
 * `CardActions` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/card-actions/}
 */
const MuiCardActions = Object.freeze({
  styleOverrides: {
    root: {
      alignItems: 'center',
      backgroundColor: Colors.White,
      justifyContent: 'space-between',
      paddingLeft: 16,
      [themeWithBreakpoints.breakpoints.down('lg')]: {
        flexDirection: 'column',
        paddingTop: 0,
      },
    },
  },
});

export default MuiCardActions;
