import { call, put, select, takeEvery } from 'redux-saga/effects';

import { setLoadingById } from 'src/app/actions/loadingActions';

import { sendCustomerDataUploadedImage } from '../../../services/shoppingExperienceApi';
import { CUSTOMER_DATA_FIELDS_DESIGN_ID,
  CUSTOMER_DATA_FIELDS_DESIGN_URL,
  CUSTOMER_DATA_FIELDS_DESIGN_TYPE,
  CUSTOMER_DATA_VALUES_UPLOAD_DESIGN_TYPE,
  FETCH_UPLOAD_CARD_IMAGE_LOADING,
  SEND_CUSTOMER_DATA_UPLOADED_IMAGE } from '../../../utils/constants';
import { setCustomerDataByKey } from '../../actions/customerDataActions';
import { selectCurrentShoppingExperienceId } from '../../selectors/currentShoppingExperienceSelectors';

/**
 * Saga to send user custom image.
 * @param action redux action
 * @param action.type SEND_CUSTOMER_DATA_UPLOADED_IMAGE
 * @param action.source - image adress in base64 format
 * @todo Add loading actions
 * @todo Add handling error actions
 */
function* makeFetch(action) {
  try {
    yield put(setLoadingById(FETCH_UPLOAD_CARD_IMAGE_LOADING, true));
    const { source } = action;
    const customerDataId = yield select(selectCurrentShoppingExperienceId);

    const { data } = yield call(sendCustomerDataUploadedImage, source);

    yield put(setCustomerDataByKey(customerDataId, CUSTOMER_DATA_FIELDS_DESIGN_ID, data?.id));
    yield put(setCustomerDataByKey(customerDataId, CUSTOMER_DATA_FIELDS_DESIGN_URL, data?._links?.preview?.href));
    yield put(setCustomerDataByKey(
      customerDataId,
      CUSTOMER_DATA_FIELDS_DESIGN_TYPE,
      CUSTOMER_DATA_VALUES_UPLOAD_DESIGN_TYPE,
    ));
  } catch (error) {
    // TODO: add error handling
  } finally {
    yield put(setLoadingById(FETCH_UPLOAD_CARD_IMAGE_LOADING, false));
  }
}

export default function* watchFetch() {
  yield takeEvery(SEND_CUSTOMER_DATA_UPLOADED_IMAGE, makeFetch);
}
