import MuiAccordion from './MuiAccordion/MuiAccordion';
import MuiAccordionDetails from './MuiAccordion/MuiAccordionDetails';
import MuiAccordionSummary from './MuiAccordion/MuiAccordionSummary';
import MuiAlert from './MuiAlert';
import MuiAutocomplete from './MuiAutocomplete';
import MuiButton from './MuiButton';
import MuiCard from './MuiCard/MuiCard';
import MuiCardActions from './MuiCard/MuiCardActions';
import MuiCardContent from './MuiCard/MuiCardContent';
import MuiCardMedia from './MuiCard/MuiCardMedia';
import MuiChip from './MuiChip';
import MuiDialog from './MuiDialog/MuiDialog';
import MuiDialogActions from './MuiDialog/MuiDialogActions';
import MuiDialogContent from './MuiDialog/MuiDialogContent';
import MuiDialogTitle from './MuiDialog/MuiDialogTitle';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiIconButton from './MuiIconButton';
import MuiLink from './MuiLink';
import MuiList from './MuiList/MuiList';
import MuiListItem from './MuiList/MuiListItem';
import MuiListItemIcon from './MuiList/MuiListItemIcon';
import MuiPaper from './MuiPaper';
import MuiPickersDay from './MuiPickersDay';
import MuiPopover from './MuiPopover';
import MuiRadio from './MuiRadio';
import MuiSelect from './MuiSelect';
import MuiStepConnector from './MuiStepper/MuiStepConnector';
import MuiStepIcon from './MuiStepper/MuiStepIcon';
import MuiStepLabel from './MuiStepper/MuiStepLabel';
import MuiStepper from './MuiStepper/MuiStepper';
import MuiTableCell from './MuiTableCell';
import MuiInputBase from './MuiTextField/MuiInputBase';
import MuiInputLabel from './MuiTextField/MuiInputLabel';
import MuiOutlinedInput from './MuiTextField/MuiOutlinedInput';
import MuiToggleButton from './MuiToggleButton';
import MuiToggleButtonGroup from './MuiToggleButtonGroup';
import MuiTypography from './MuiTypography';

/**
 * Object of the `ThemeComponents`, used by MUI `theme`.
 *
 * @type ThemeComponents
 * @see {@link https://mui.com/material-ui/customization/theme-components/}
 */
const components = Object.freeze({
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiAlert,
  MuiAutocomplete,
  MuiButton,
  MuiCard,
  MuiCardActions,
  MuiCardContent,
  MuiCardMedia,
  MuiChip,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiFormControlLabel,
  MuiIconButton,
  MuiInputBase,
  MuiInputLabel,
  MuiLink,
  MuiList,
  MuiListItem,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiPaper,
  MuiPickersDay,
  MuiPopover,
  MuiRadio,
  MuiSelect,
  MuiStepConnector,
  MuiStepIcon,
  MuiStepLabel,
  MuiStepper,
  MuiTableCell,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiTypography,
});

export default components;
