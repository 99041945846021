import React from 'react';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';

import appStore from 'src/app/appStore';
import theme from 'src/theme';

import QuickBuyForm from './QuickBuyForm';

function QuickBuyApp() {
  const appRoot = document.querySelector('#quick-buy-app-root');

  return (
    <Provider store={appStore}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <QuickBuyForm
            cartQuantity={window.cartQuantity}
            denomination={appRoot.dataset.denomination}
            imgAlt={appRoot.dataset.imgalt}
            imgSrc={appRoot.dataset.imgsrc}
            shoppingExperienceId={appRoot.dataset.shoppingexperienceid}
          />
        </StyledEngineProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default QuickBuyApp;
