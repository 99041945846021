import { combineReducers } from 'redux-immutable';

import currentShoppingExperience from './currentShoppingExperienceReducer';
import customerData from './customerDataReducer';
import customizationPhysicalOptions from './customizationPhysicalOptionsReducer';
import customizationPhysical from './customizationPhysicalReducer';
import customizationVirtual from './customizationVirtualReducer';
import dictionaries from './dictionariesReducer';
import feedback from './feedbackReducer';
import lastAction from './lastActionReducer';
import loading from './loadingReducer';
import shoppingExperiences from './shoppingExperiencesReducer';
import steps from './stepsReducer';
import submissionItems from './submissionItemsReducer';
import submission from './submissionReducer';

const staticReducers = {
  currentShoppingExperience,
  customerData,
  customizationPhysical,
  customizationPhysicalOptions,
  customizationVirtual,
  dictionaries,
  feedback,
  lastAction,
  loading,
  shoppingExperiences,
  steps,
  submission,
  submissionItems,
};

export function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}
