/* eslint-disable class-methods-use-this */
import get from 'lodash-es/get';

import { CATEGORY_BRAND } from 'src/brands/card_purchase/cart/item/experienceCategories';
import { CURRENT_STEP_NAME_CREATE_PDP,
  CUSTOMER_DATA_FIELDS_DENOMINATION,
  NEXT_STEP_ACTION_ADD_CART } from 'utils/constants';

import BaseInterface from '../Base';

class BrandCardInterface extends BaseInterface {
  validateFieldsOrder = [
    CUSTOMER_DATA_FIELDS_DENOMINATION,
  ];

  backendValidationConfig = {
    buildPayload: (formData) => ({
      items: [{
        customer_data: {
          denomination: formData[CUSTOMER_DATA_FIELDS_DENOMINATION],
        },
        experience: {
          category: CATEGORY_BRAND,
          id: this.product.id,
        },
      }],
    }),
    parseError: (error) => ({
      [CUSTOMER_DATA_FIELDS_DENOMINATION]:
        get(error, 'items.children[0].children.customer_data.children.denomination.errors[0]'),
    }),
  }

  constructor(category, product, additionalDescription) {
    super(category, product, additionalDescription);

    this.isMultipleCategoryExperience = Boolean(this.product?._embedded?.merchant?._links?.virtual_experience);
  }

  getSteps() {
    return [
      {
        name: CURRENT_STEP_NAME_CREATE_PDP,
        nextStepAction: NEXT_STEP_ACTION_ADD_CART,
        nextStepActionTitle: 'Add to cart',
      },
    ];
  }
}

export default BrandCardInterface;
