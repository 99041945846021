import { createSelector } from 'reselect';

export const selectCurrentShoppingExperienceId = createSelector(
  (state) => state.getIn(['currentShoppingExperience', 'shoppingExperienceId']),
  (shoppingExperienceId) => shoppingExperienceId,
);

export const selectCurrentShoppingExperienceExternalId = createSelector(
  (state) => state.getIn(['currentShoppingExperience', 'externalId']),
  (externalId) => externalId,
);

export const selectCurrentShoppingExperienceProductId = createSelector(
  (state) => state.getIn(['currentShoppingExperience', 'productId']),
  (productId) => productId,
);

export const selectCurrentShoppingExperienceCurrentStep = createSelector(
  (state) => state.getIn(['currentShoppingExperience', 'currentStep']),
  (currentStep) => currentStep,
);

export const selectCurrentShoppingExperienceCategory = createSelector(
  (state) => state.getIn(['currentShoppingExperience', 'category']),
  (category) => category,
);
