import {LOCATION_CHANGE} from "../routing/routing";
import {call, select, takeLatest} from 'redux-saga/effects';
import {APP_START_BRANDS, WORKSHEET_CLOSE, WORKSHEET_OPEN} from "./appActions";
import {BASE_ROUTE, EDIT_ROUTE} from "../routing/routes";
import { selectSubmissionTotalCartItemsCount } from 'src/app/selectors/submissionSelectors';
import { SET_CURRENT_SUBMISSION } from 'utils/constants/submission';
import { NEW_BASE_URL } from "src/utils/constants";

const productPath = window ? window.productPath : '';

function* setElementDisplay(id, display) {
    const element = document.getElementById(id);
    if (!element) {
        return;
    }

    element.style.display = display;
}

function* pageTransitions(event) {
    // Handle changing the content outside of React after we are fully in the react experience. I reserve the right to do
    // ajax stuff so it's staying a generator
    // @FUTURE: Move everything that touches the world outside of react in a file and tightly control how it's accessed
    const showSEO = (event.type === APP_START_BRANDS && BASE_ROUTE(productPath) !== EDIT_ROUTE) || (event.type == LOCATION_CHANGE && (event?.location?.pathname == BASE_ROUTE(productPath) || event?.location?.pathname == NEW_BASE_URL + BASE_ROUTE(productPath)));
    showSEO ? yield call(setElementDisplay, 'seo-content', 'block') : yield call(setElementDisplay, 'seo-content', 'none');
}

/**
 * updateCart function outside of react js, in PDP/cart pages is not ready immediately
 * global function should handle try catch the block avoid errors
 * TODO: Consider other approach later
*/
function* updateCartIconQuantity() {
    try {
        let cartQuantity = yield select(selectSubmissionTotalCartItemsCount);
        cartQuantity = cartQuantity ?? 0;
        window.updateCartIconVisibility('react', cartQuantity);
    } catch(err) {}
}

export function* watchPageTransitions() {
    yield takeLatest([LOCATION_CHANGE, APP_START_BRANDS], pageTransitions);
}

export function *watchWorksheetOpen() {
    yield takeLatest(WORKSHEET_OPEN, function*() { yield call(setElementDisplay, 'footer', 'hide') });
}

export function *watchWorksheetClose() {
    yield takeLatest(WORKSHEET_CLOSE, function*() { yield call(setElementDisplay, 'footer', 'block')});
}

export function *watchPushTracking() {
    yield takeLatest([APP_START_BRANDS], () => window && window.dataLayer && window.dataLayer.push({ "event": "ciq_mobile_flow_test" }));
}

export function* watchUpdateCart() {
    yield takeLatest([
            SET_CURRENT_SUBMISSION,
        ],
        updateCartIconQuantity
    )
}
