import { ADD_CUSTOMIZATION_PHYSICAL_OPTIONS,
  SET_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY,
  CLEAR_CUSTOMIZATION_PHYSICAL_OPTIONS_DATA,
  REMOVE_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY } from 'src/utils/constants';

export const addCustomizationOptionsData = (optionsId, options) => ({
  options,
  optionsId,
  type: ADD_CUSTOMIZATION_PHYSICAL_OPTIONS,
});

export const setCustomizationOptionsByKey = (optionsId, key, value) => ({
  key,
  optionsId,
  type: SET_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY,
  value,
});

export const removeCustomizationOptionsByKey = (optionsId) => ({
  optionsId,
  type: REMOVE_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY,
});

export const clearCustomizationOptions = () => ({
  type: CLEAR_CUSTOMIZATION_PHYSICAL_OPTIONS_DATA,
});
