import React, { memo, useCallback, useEffect, useState } from 'react';

import { TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import { getFormControllerError, DEBOUNCE_WAIT_TIMER } from 'utils/form';

/**
 * `InputWithDebounce` component encapsulate part of repeatable logic.
 * @type ReactComponent
 */
function InputWithDebounce({
  controller, helperText, inputProps, onChange, onPaste, outerValue, ...rest
}) {
  const [localValue, setLocalValue] = useState(outerValue);

  useEffect(() => {
    setLocalValue(outerValue);
    controller?.field.onChange(outerValue);
    // We want change the local value only if outerValue change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outerValue]);

  const debouncedOnChange = debounce(onChange, DEBOUNCE_WAIT_TIMER);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebounceOnChange = useCallback(debouncedOnChange, [onChange]);

  const handleChangeValue = useCallback((event) => {
    const { value } = event.target;
    setLocalValue(value);
    controller?.field.onChange(value);
    handleDebounceOnChange(value);
  }, [controller, handleDebounceOnChange]);

  const error = getFormControllerError(controller);
  const hasError = !!error;

  return (
    <TextField
      error={hasError}
      onBlur={controller?.field.onBlur}
      onChange={handleChangeValue}
      value={localValue}
      variant="outlined"
      {...rest}
      helperText={error || helperText}
      inputProps={{
        'aria-invalid': !!hasError,
        ...inputProps,
      }}
      inputRef={controller?.field.ref}
      onPaste={onPaste(onChange)}
    />
  );
}

InputWithDebounce.defaultProps = {
  controller: null,
  helperText: '',
  inputProps: {},
  onChange: () => {},
  onPaste: () => () => {},
  outerValue: '',
};

InputWithDebounce.propTypes = {
  controller: PropTypes.object,
  helperText: PropTypes.string,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  outerValue: PropTypes.string,
};

export default memo(InputWithDebounce);
