export const CATEGORY_BRAND = 'brand';
export const CATEGORY_VIRTUAL = 'virtual';
export const CATEGORY_CAC = 'create-a-card';
export const CATEGORY_PREDESIGN = 'predesign';

export const isBrand = (category) => category === CATEGORY_BRAND;

export const isVirtual = (category) => category === CATEGORY_VIRTUAL;
export const isCreateaCard = (category) => category === CATEGORY_CAC;
export const isPredesign = (category) => category === CATEGORY_PREDESIGN;
export const getExperienceCategory = (item) => item?.experience?.category;
export const isPhysicalOpenLoop = (category) => isPredesign(category) || isCreateaCard(category);
