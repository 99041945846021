import { call, put, select, takeEvery } from 'redux-saga/effects';

import { setCurrentShoppingExperienceCurrentStep } from 'src/app/actions/currentShoppingExperienceActions';
import { setGoogleAnalyticsPdpEvent } from 'src/app/actions/googleAnalyticsActions';
import { addSubmissionItemSaga, editSubmissionItemSaga } from 'src/app/actions/submissionActions';
import { selectCurrentShoppingExperienceCurrentStep,
  selectCurrentShoppingExperienceCategory } from 'src/app/selectors/currentShoppingExperienceSelectors';
import { selectCustomerDataNetwork } from 'src/app/selectors/customerDataSelectors';
import { selectShoppingExperience } from 'src/app/selectors/shoppingExperiencesSelectors';
import { selectNextStepActionById } from 'src/app/selectors/stepsSelectors';
import { CUSTOMIZATION_ROUTE } from 'src/routing/routes';
import { push } from 'src/routing/routing';
import productFactory from 'src/services/productFactory';
import { EVENT_ADD_TO_CART, EVENT_CARD_CUSTOMIZATION } from 'src/utils/constants/googleAnalytics';
import { NEXT_STEP,
  NEXT_STEP_ACTION_ADD_CART,
  NEXT_STEP_ACTION_EDIT_CART,
  NEXT_STEP_ACTION_CUSTOMIZATION_VIRTUAL,
  NEXT_STEP_ACTION_CUSTOMIZATION_OL_PHYSICAL } from 'utils/constants';

/**
 * Helper saga to get user to the next step of the shopping experience.
 * @todo Add handling error actions;
 */
function* makeNextStep() {
  try {
    const currentStep = yield select(selectCurrentShoppingExperienceCurrentStep);
    const nextStepAction = yield select((state) => selectNextStepActionById(state, currentStep));

    const network = yield select(selectCustomerDataNetwork);
    const shoppingExperience = yield select(selectShoppingExperience);
    const shoppingCategory = yield select(selectCurrentShoppingExperienceCategory);

    const productInterface = yield call(productFactory, shoppingCategory, shoppingExperience, network);
    const productUrl = yield call([productInterface, productInterface.getProductUrl], CUSTOMIZATION_ROUTE);

    switch (nextStepAction) {
      case NEXT_STEP_ACTION_CUSTOMIZATION_OL_PHYSICAL: {
        yield put(setCurrentShoppingExperienceCurrentStep(currentStep + 1));
        yield put(setGoogleAnalyticsPdpEvent(EVENT_CARD_CUSTOMIZATION));
        yield call(push, productUrl);
        break;
      }
      case NEXT_STEP_ACTION_CUSTOMIZATION_VIRTUAL: {
        yield put(setCurrentShoppingExperienceCurrentStep(currentStep + 1));
        yield put(setGoogleAnalyticsPdpEvent(EVENT_CARD_CUSTOMIZATION));
        yield call(push, productUrl);
        break;
      }
      case NEXT_STEP_ACTION_ADD_CART:
        yield put(addSubmissionItemSaga());
        yield put(setGoogleAnalyticsPdpEvent(EVENT_ADD_TO_CART));
        break;
      case NEXT_STEP_ACTION_EDIT_CART:
        yield put(editSubmissionItemSaga());
        break;
      default:
        // TODO: add some error exception
        break;
    }
  } catch (error) {
    // TODO: add error handling
  }
}

export default function* watchNextStep() {
  yield takeEvery(NEXT_STEP, makeNextStep);
}
