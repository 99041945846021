import Colors from '../../palette/Colors';
import fonts from '../../typography/fonts';

/**
 * `DialogTitle` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/dialog-title/}
 */
const MuiDialogTitle = Object.freeze({
  styleOverrides: {
    root: {
      color: Colors.PurpleDark,
      display: 'flex',
      marginBottom: 0,
      ...fonts.h4,
    },
  },
});

export default MuiDialogTitle;
