/**
 * `List` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/list/}
 */
const MuiList = Object.freeze({
  defaultProps: {
    dense: true,
  },
});

export default MuiList;
