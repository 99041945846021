/* eslint-disable sort-keys */
import { createTheme } from '@mui/material/styles';

import breakpointsConfig from '../../../themeConfig/breakpoints.json';

/**
 * Object of the `BreakpointsOptions`, used by MUI theme.
 * @type BreakpointsOptions
 * @see {@link https://mui.com/material-ui/customization/breakpoints/}
 */
const breakpoints = Object.freeze({
  values: Object.freeze(breakpointsConfig),
});

/**
 * Predefined `theme` with a custom object of the `MUI` breakpoints.
 * This `theme` object will be inserted in the main `theme` object.
 * Also it may be used by other `theme` components (e.g. see typography object of the main `theme`).
 * It's required due to other `theme` components cannot access breakpoints otherwise.
 *
 */
export const themeWithBreakpoints = createTheme({
  breakpoints,
});
