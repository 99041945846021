import axiosService from './axiosService';

/**
 * Api call to get a general info about shopping experience
 * @param {string} category - category type
 * @param {string} id - product id
 * @returns {Promise}
 */
export const fetchShoppingExperience = (category, id) => (
  axiosService.get(`/api/shopping-experiences/categories/${category}/experiences/${id}`)
);

/**
 * Api call to retreive a list of standard massages and save them in dictionary reducer
 * @returns {Promise}
 */
export const fetchShoppingExperienceStandardMessages = () => (
  axiosService.get('/api/shopping-experiences/standard-messages')
);

/**
 * Api call is about to send user custom image.
 * Common usage on create-a-card PDP page.
 * @param {string} source - image adress in base64 format
 * @returns {Promise}
 */
export const sendCustomerDataUploadedImage = (source) => (
  axiosService.post('/api/images', { source })
);

/**
 * API call to retrieve the fee data for a specific category and a specific experience ID.
 * @param {string} category - The category type
 * @param {string} id - experience ID
 * @returns {Promise}
 */
export const fetchFeesData = async (category, id) => {
  let url;
  if (['predesign', 'create-a-card', 'incentive-predesign'].includes(category)) {
    url = `/api/shopping-experiences/categories/${category}/fee`;
  } else {
    url = `/api/shopping-experiences/categories/${category}/experiences/${id}/fee`;
  }

  const response = await axiosService.get(url);
  return response;
};

/**
 * API call to retrieve shipping fees data.
 * @returns {Promise}
 */
export const fetchShippingFees = () => (
  axiosService.get('/shipping-fees')
);
