import { Map } from 'immutable';

import { SET_FEEDBACK,
  REMOVE_FEEDBACK,
  CLEAR_FEEDBACKS } from 'utils/constants/feedback';

const initialState = Map({});

/**
 * `feedback` reducer stores data of different API responses.
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FEEDBACK: {
      const { id, level, message } = action;

      return state
        .setIn([id, 'level'], level)
        .setIn([id, 'message'], message);
    }
    case REMOVE_FEEDBACK: {
      const { id } = action;

      return state.delete(id);
    }
    case CLEAR_FEEDBACKS:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
