import { createBrowserHistory } from 'history';

/**
 * The routing module provides storefront specific routing data and functions.
 *
 * react-router-dom: https://www.npmjs.com/package/react-router-dom
 * react-router Docs: https://reacttraining.com/react-router/core/guides/quick-start
 */

export const LOCATION_CHANGE = '@@gcfront/routing/routing/LOCATION_CHANGE';

/**
 * Action creator for route changes.
 *
 * We attach a callback to the location change listener of the history and
 * dispatch a matching action when called.
 */
export const changeLocation = (location, action) => ({
  action,
  location,
  type: LOCATION_CHANGE,
});

/**
 * Create the history object.
 *
 * This history should be treated as a singleton. All users of router
 * history should import from this file.
 *
 * history is imported as a dependency of react-router-dom.
 * History Module: https://github.com/ReactTraining/history
 * Info: https://reacttraining.com/react-router/core/api/history
 */
const browserHistory = createBrowserHistory({ basename: '' });
browserHistory.replace({
  hash: window.location.hash,
  pathname: window.location.pathname,
  search: window.location.search,
});

/**
 * Callback to register with history location change listener.
 *
 * This callback will be fired whenever there is a location change reported
 * by the router history. When fired we dispatch a LOCATION_CHANGE action
 * with the provided arguments.
 */
export const createLocationChangeActionDispatcher = (store) => (
  browserHistory.listen((location, action) => (
    store.dispatch(changeLocation(location, action))))
);

export const history = browserHistory;

export const { replace } = browserHistory;

export const { push } = browserHistory;

export const { goBack } = browserHistory;

export const homePageRedirect = () => {
  window.location.href = '/';
};

export const rewardsProfilePageRedirect = () => {
  window.location.href = '/member/rewards#!/';
};

export const memberProfilePageRedirect = () => {
  window.location.href = '/member';
};
