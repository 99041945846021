export const SET_FEEDBACK = '@@gcfront/SET_FEEDBACK';
export const REMOVE_FEEDBACK = '@@gcfront/REMOVE_FEEDBACK';
export const CLEAR_FEEDBACKS = '@@gcfront/CLEAR_FEEDBACKS';

export const FEEDBACK_LEVEL_ERROR = 'error';
export const FEEDBACK_LEVEL_SUCCESS = 'success';

export const SUBMISSION_PROMOTION_FEEDBACK_ID = 'SUBMISSION_PROMOTION_FEEDBACK_ID';
export const SUBMISSION_POTENTIAL_REWARDS_FEEDBACK_ID = 'SUBMISSION_POTENTIAL_REWARDS_FEEDBACK_ID';

export const ADDRESS_VALIDATION_FEEDBACK_ID = 'ADDRESS_VALIDATION_FEEDBACK_ID';

export const PAYMENT_PLUS_AUTH_FEEDBACK_ID = 'PAYMENT_PLUS_AUTH_FEEDBACK_ID';

export const SUBMISSION_ITEMS_ERROR_ID = 'SUBMISSION_ITEMS_ERROR_ID';
