import { takeEvery, call, put, select, cancel } from 'redux-saga/effects';

import { setCurrentShoppingExperienceCategory,
  setCurrentShoppingExperienceProductId } from 'src/app/actions/currentShoppingExperienceActions';
import { addCustomerData } from 'src/app/actions/customerDataActions';
import { setLoadingById } from 'src/app/actions/loadingActions';
import { addShoppingExperience, fetchShoppingExperienceSaga } from 'src/app/actions/shoppingExperiencesActions';
import { selectCurrentShoppingExperienceProductId } from 'src/app/selectors/currentShoppingExperienceSelectors';
import { selectCustomerDataById, selectCustomerDataIdExists } from 'src/app/selectors/customerDataSelectors';
import { hasShoppingExperienceById } from 'src/app/selectors/shoppingExperiencesSelectors';
import { CATEGORY_BRAND,
  CATEGORY_VIRTUAL } from 'src/brands/card_purchase/cart/item/experienceCategories';
import productFactory from 'src/services/productFactory';
import { fetchShoppingExperience } from 'src/services/shoppingExperienceApi';
import { CUSTOMER_DATA_FIELDS_DENOMINATION,
  FETCH_SHOPPING_EXPERIENCE_LOADING,
  FETCH_SHOPPING_EXPERIENCE_SAGA } from 'utils/constants';
import { windowGetVariable } from 'utils/windowUtils';

/**
 * Helper function to add experience data and customer data to store.
 * It's a helper function used in multiple different places.
 * It stores data by combination of `experienceId` and `experienceCategory`,
 * due to some experiences exist in multiple categories.
 */
export function* addShoppingExperienceItem(experienceId, experienceCategory, experienceData, customerData) {
  const shoppingExperienceId = `${experienceCategory}/${experienceId}`;
  const isCustomerDataExists = yield select(selectCustomerDataIdExists, shoppingExperienceId);
  const customerDataObject = yield select(selectCustomerDataById, shoppingExperienceId);
  const denomination = Number(customerDataObject?.[CUSTOMER_DATA_FIELDS_DENOMINATION]).toFixed(2);
  const loadAmountImages = experienceData?.load_amount_images;

  const shoppingExperience = {
    ...experienceData,
    // Set display image based on selected denomination,
    // as BE returns default image
    ...loadAmountImages?.[denomination] ? { display_image: loadAmountImages[denomination] } : {},
  };

  yield put(addShoppingExperience(
    shoppingExperienceId,
    shoppingExperience,
  ));
  if (!isCustomerDataExists) {
    yield put(addCustomerData(shoppingExperienceId, customerData));
  }
}

/**
 * Saga to fetch shopping experience by category and id.
 * The result is stored in `shoppingExperience` by an predifined `shoppingExperienceId`.
 * `shoppingExperienceId` is a concat of a `category` and `id`.
 *
 * @param action redux action
 * @param action.type FETCH_SHOPPING_EXPERIENCE
 * @param action.category - shopping experience category, that should come from URL query param `experience_type`
 * @param action.id - shopping experience id that should come from URL query param `experience_id`
 * @todo Add handling error actions
 */
function* makeFetch(action) {
  try {
    const {
      experienceCategory,
      experienceId,
      experienceNetwork,
    } = action;

    // Step 1. Check shopping experience with such id exists.
    const isShoppingExperienceExist = yield select(hasShoppingExperienceById, `${experienceCategory}/${experienceId}`);
    if (isShoppingExperienceExist) yield cancel();

    yield put(setLoadingById(FETCH_SHOPPING_EXPERIENCE_LOADING, true));

    // Step 2. Fetch general information of the shopping experience.
    let data;
    if (experienceCategory === CATEGORY_VIRTUAL
      && windowGetVariable('virtualExperience')?.id === experienceId) {
      data = windowGetVariable('virtualExperience');
    } else if (experienceCategory === CATEGORY_BRAND
      && windowGetVariable('brandExperience')?.id === experienceId) {
      data = windowGetVariable('brandExperience');
    } else {
      const resp = yield call(fetchShoppingExperience, experienceCategory, experienceId);
      data = resp.data;
    }

    // Step 2. Fetch general information of the shopping experience.
    // const { data } = yield call(fetchShoppingExperience, experienceCategory, experienceId);

    // Step 3. Generate a raw customer data.
    const productInterface = yield call(productFactory, experienceCategory, data, experienceNetwork);
    const customerData = productInterface.getRawCustomerData();
    // Step 4. Store received experience and customer data.
    yield call(addShoppingExperienceItem, experienceId, experienceCategory, data, customerData);

    // Step 5. Check current experience id exists, if not then store it.
    const currentShoppingExperienceProductId = yield select(selectCurrentShoppingExperienceProductId);
    if (!currentShoppingExperienceProductId || currentShoppingExperienceProductId !== experienceId) {
      yield put(setCurrentShoppingExperienceProductId(experienceId));
      yield put(setCurrentShoppingExperienceCategory(experienceCategory));
    }

    // Step 7. Proceed additional fetch, if shopping experience includes multiple categories.
    if (experienceCategory === CATEGORY_VIRTUAL && data._embedded?.merchant?._links?.brand_experience) {
      yield put(fetchShoppingExperienceSaga(CATEGORY_BRAND, experienceId));
    } else if (experienceCategory === CATEGORY_BRAND && data._embedded?.merchant?._links?.virtual_experience) {
      yield put(fetchShoppingExperienceSaga(CATEGORY_VIRTUAL, experienceId));
    }
  } catch (error) {
    // TODO: add error handling
  } finally {
    yield put(setLoadingById(FETCH_SHOPPING_EXPERIENCE_LOADING, false));
  }
}

export default function* watchFetch() {
  yield takeEvery(FETCH_SHOPPING_EXPERIENCE_SAGA, makeFetch);
}
