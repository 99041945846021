import { themeWithBreakpoints } from '../../breakpoints';

/**
 * `CardMedia` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/card-media/}
 */
const MuiCardMedia = Object.freeze({
  styleOverrides: {
    root: {
      boxSizing: 'border-box',
      paddingBottom: 18,
      paddingLeft: 43,
      paddingRight: 43,
      paddingTop: 37,
      [themeWithBreakpoints.breakpoints.down('lg')]: {
        paddingBottom: 11,
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 28,
      },
    },
  },
});

export default MuiCardMedia;
