import { Map, List } from 'immutable';

import { CLEAR_CUSTOMIZATION_PHYSICAL_OPTIONS_DATA,
  ADD_CUSTOMIZATION_PHYSICAL_OPTIONS,
  SET_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY,
  REMOVE_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY } from 'utils/constants';

const initialState = Map({
  allIds: List([]),
  byId: Map({}),
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_CUSTOMIZATION_PHYSICAL_OPTIONS: {
      const { options, optionsId } = action;

      const allIds = state.get('allIds').toSet().add(optionsId);

      return state
        .setIn(['byId', optionsId], Map(options))
        .set('allIds', allIds.toList());
    }
    case SET_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY: {
      const { key, optionsId, value } = action;

      return state
        .setIn(['byId', optionsId, key], value);
    }
    case REMOVE_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY: {
      const { optionsId } = action;

      return state
        .removeIn(['byId', optionsId])
        .update('allIds', (items) => items
          .filter((item) => item !== optionsId));
    }
    case CLEAR_CUSTOMIZATION_PHYSICAL_OPTIONS_DATA:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
