export const DEBOUNCE_WAIT_TIMER = 500;

/**
* @link https://react-hook-form.com/api/usecontroller/
* @param controller { field: object, fieldState: object, formState: object }
* @param strict {boolean} - if set to true, helper will not check for isTouched & isSubmitted states
* @returns {string}
*/
export function getFormControllerError(controller, strict = false) {
  if (!controller) {
    return '';
  }

  const { fieldState: { error, isTouched }, formState: { isSubmitted } } = controller;

  if (error) {
    if (strict) {
      return error.message;
    }
    if (isTouched || isSubmitted) {
      return error.message;
    }
  }

  return '';
}

/**
 * TODO: Add description
 * Inspired by
 * @link https://stackoverflow.com/questions/69719627/how-to-focus-when-an-error-occurs-in-react-hook-form-controller/69723100#69723100
 * @returns {string}
 */
export function getFirstError(errors, validateFieldsOrder) {
  return Object
    .keys(errors)
    .sort((field1, field2) => {
      if (validateFieldsOrder.indexOf(field1) < validateFieldsOrder.indexOf(field2)) {
        return -1;
      }
      return 1;
    })
    .reduce((field, a) => (errors[field] ? field : a), null);
}
