import Colors from '../palette/Colors';

/**
 * `Popover` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/popover/}
 */
const MuiPopover = Object.freeze({
  styleOverrides: {
    paper: {
      borderColor: Colors.GreyMain,
      borderRadius: 20,
      borderStyle: 'solid',
      borderWidth: 1,
      padding: 25,
    },
  },
});

export default MuiPopover;
