import { get } from 'lodash-es';
import { call, select, takeEvery, put, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { clearCurrentShoppingExperience } from 'src/app/actions/currentShoppingExperienceActions';
import { clearCustomerData } from 'src/app/actions/customerDataActions';
import { addCustomizationOptionsData,
  removeCustomizationOptionsByKey } from 'src/app/actions/customizationPhysicalOption';
import { setLoadingById } from 'src/app/actions/loadingActions';
import { clearShoppingExperience } from 'src/app/actions/shoppingExperiencesActions';
import { setCurrentSubmission, setSubmissionErrors } from 'src/app/actions/submissionActions';
import { setCurrentSubmissionItems } from 'src/app/actions/submissionItemsActions';
import { selectCurrentShoppingExperienceCategory,
  selectCurrentShoppingExperienceId } from 'src/app/selectors/currentShoppingExperienceSelectors';
import { selectCustomerDataObject,
  selectCustomerDataQuantity } from 'src/app/selectors/customerDataSelectors';
import { selectCustomizationOptionsById } from 'src/app/selectors/customizationPhysicalOptions';
import { selectShoppingExperience } from 'src/app/selectors/shoppingExperiencesSelectors';
import { selectSubmissionId } from 'src/app/selectors/submissionSelectors';
import { CATEGORY_CAC, CATEGORY_PREDESIGN } from 'src/brands/card_purchase/cart/item/experienceCategories';
import { push } from 'src/routing/routing';
import productFactory from 'src/services/productFactory';
import { addSubmissionItem, createSubmission } from 'src/services/submissionsApi';
import { addGACartItemsList } from 'src/utils/googleAnalytics';
import { ADD_SUBMISSION_ITEM_SAGA, SAVE_SUBMISSION_ITEM_LOADING } from 'utils/constants/submission';
import { getCookieValue } from 'utils/cookies';
import { windowSetVariable, windowGetVariable } from 'utils/windowUtils';

import { addSubmissionItemToStore } from './fetchCurrentSubmission';

/**
 * Saga to add currently open experience and customer data to a cart.
 * It operates with a currentt shopping experience and current submission.
 * @todo Add handling error actions
 */
function* makeFetch(action) {
  try {
    yield put(setLoadingById(SAVE_SUBMISSION_ITEM_LOADING, true));
    // Step 1. Retrieve shopping experience and customer data from redux store.
    const submissionId = yield select(selectSubmissionId);
    const experienceCategory = yield select(selectCurrentShoppingExperienceCategory);
    const experienceData = yield select(selectShoppingExperience);
    const customerData = yield select(selectCustomerDataObject);
    const customerDataQuantity = yield select(selectCustomerDataQuantity);
    const submissionErrors = windowGetVariable('userMixTypesAlertPopupTexts');

    // Step 2. Prepare shopping experience and customer data to add it to submission.
    const productInterface = yield call(productFactory, experienceCategory, experienceData);

    const items = yield all(Array.from({ length: customerDataQuantity }, () => call(
      [productInterface, productInterface.getCartItemData],
      { ...customerData, quantity: 1 },
      uuidv4(),
    )));
    const cartToken = yield call(getCookieValue, 'gc_cart_token');

    const submissionData = {
      items,
      riskified_beacon_token: cartToken,
    };

    addGACartItemsList({ cartItems: submissionData.items });

    /**
     * Step 3.
     * * Add an item to an existed submission
     * * Create a new submission if there's no one
     */

    let response;
    if (submissionId && !submissionErrors.length) {
      response = yield call(addSubmissionItem, submissionId, submissionData);
    } else {
      response = yield call(createSubmission, submissionData);

      // TODO: Remove this once the page is refactored
      // This is a workaround for cart review page, to persist data on go back action
      windowSetVariable('submission_id', response.data.id);
    }

    const { data } = response;

    /**
     * Step 4.
     * * Clear the existing data (shoppingExperiences, customerData, etc.).
     * * Set up the updated submission data, that will be used on cart page.
     * * Redirect user on shopping cart page.
     */
    if (data) {
      const experienceCategoryId = yield select(selectCurrentShoppingExperienceId);
      const option = yield select(selectCustomizationOptionsById, experienceCategoryId);

      yield put(removeCustomizationOptionsByKey(experienceCategoryId));

      yield all(items
        .filter((item) => [CATEGORY_CAC, CATEGORY_PREDESIGN].includes(get(item, 'experience.category', null)))
        .map((item) => put(addCustomizationOptionsData(item.external_id, option))));

      yield put(clearCurrentShoppingExperience());

      yield put(clearShoppingExperience());
      yield put(clearCustomerData());

      yield put(setCurrentSubmission(data));
      yield put(setCurrentSubmissionItems(data));

      yield all(data._embedded.items.map((item) => call(addSubmissionItemToStore, item)));

      if (action && action.redirect) {
        yield call(action.redirect);
      } else {
        yield call(push, '/shopping-cart');
      }
    }
  } catch (error) {
    // TODO: add error handling
    if (error?.response?.data) {
      yield put(setSubmissionErrors(error.response.data.errors.children.items.errors));
    }
  } finally {
    yield put(setLoadingById(SAVE_SUBMISSION_ITEM_LOADING, false));
  }
}

export default function* watchFetch() {
  yield takeEvery(ADD_SUBMISSION_ITEM_SAGA, makeFetch);
}
