import Colors from './Colors';

/**
 * Object of the `PaletteOptions`, used by MUI `theme`.
 *
 * Default options are `primary` and `secondary`.
 * In case of Typescript usage, custom options (e.g. green, purple, cyanBlueLight, etc.) requires types declarations.
 * @type PaletteOptions
 * @see {@link https://mui.com/material-ui/customization/palette/}
 */
const palette = Object.freeze({
  cyanBlueLight: Object.freeze({
    contrastText: Colors.White,
    dark: Colors.CyanBlueLight1,
    dark1: Colors.CyanBlueDark1,
    light: Colors.CyanBlueLight3,
    main: Colors.CyanBlueLight2,
  }),
  error: Object.freeze({
    main: Colors.RedMain,
  }),
  green: Object.freeze({
    dark: Colors.GreenDark,
    main: Colors.GreenMain,
  }),
  grey: Object.freeze({
    dark: Colors.GreyDark,
    dark2: Colors.GreyDark2,
    light: Colors.GreyLight,
    light2: Colors.GreyLight2,
    main: Colors.GreyMain,
  }),
  primary: Object.freeze({
    contrastText: Colors.White,
    dark: Colors.PurpleDark,
    light: Colors.PurpleLight,
    main: Colors.PurpleMain,
  }),
  purple: Object.freeze({
    contrastText: Colors.White,
    dark: Colors.PurpleDark,
    light: Colors.PurpleLight,
    main: Colors.PurpleMain,
  }),
  purpleDark: Object.freeze({
    contrastText: Colors.White,
    dark: Colors.PurpleDark3,
    light: Colors.PurpleLight,
    main: Colors.PurpleDark,
  }),
  secondary: Object.freeze({
    contrastText: Colors.White,
    dark: Colors.CyanBlueDark,
    light: Colors.CyanBlueLight1,
    main: Colors.CyanBlueMain,
  }),
});

export default palette;
