import { alpha } from '@mui/material/styles';

import Colors from '../../palette/Colors';

/**
 * `Accordion` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/accordion/}
 */
const MuiAccordion = Object.freeze({
  defaultProps: {
    disableGutters: true,
    square: true,
  },
  styleOverrides: {
    root: {
      '&:before': {
        backgroundColor: alpha(Colors.BrownMain, 0.2),
        bottom: 0,
        content: '""',
        display: 'block',
        height: 1,
        left: '50%',
        position: 'absolute',
        transform: 'translateX(-50%)',
        width: '100%',
      },
      background: 'transparent',
      boxShadow: 'none',
    },
  },
});

export default MuiAccordion;
