import Colors from '../../palette/Colors';
import fonts from '../../typography/fonts';

/**
 * `DialogContent` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/dialog-content/}
 */
const MuiDialogContent = Object.freeze({
  styleOverrides: {
    root: {
      color: Colors.CyanBlueMain,
      ...fonts.body1,
    },
  },
});

export default MuiDialogContent;
