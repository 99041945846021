import { createSelector } from 'reselect';

export const selectSubmissionId = createSelector(
  (state) => state.getIn(['submission', 'id']),
  (id) => id,
);

export const selectSubmissionCheckoutId = createSelector(
  (state) => state.getIn(['submission', 'checkoutId']),
  (id) => id,
);

export const selectSubmissionChargeTotal = createSelector(
  (state) => state.getIn(['submission', 'chargeTotal']),
  (chargeTotal) => chargeTotal,
);

export const selectSubmissionChargeTotalsValueLoad = createSelector(
  (state) => state.getIn(['submission', 'chargeTotals', 'value_load'], 0),
  (valueLoad) => valueLoad,
);

export const selectSubmissionChargeTotalsProcessing = createSelector(
  (state) => state.getIn(['submission', 'chargeTotals', 'processing'], 0),
  (processing) => processing,
);

export const selectSubmissionChargeTotalsGreetingCardFee = createSelector(
  (state) => state.getIn(['submission', 'chargeTotals', 'greeting_card'], 0),
  (greetingCard) => greetingCard,
);

export const selectSubmissionChargeTotalsShipping = createSelector(
  (state) => state.getIn(['submission', 'chargeTotals', 'shipping'], 0),
  (shipping) => shipping,
);

export const selectSubmissionChargeTotalsDiscount = createSelector(
  (state) => state.getIn(['submission', 'chargeTotals', 'promo_code_discount'], 0),
  (discount) => discount,
);

export const selectSubmissionErrors = createSelector(
  (state) => state.getIn(['submission', 'submissionErrors']),
  (submissionErrors) => submissionErrors,
);

const selectSubmissionItemsChargesValueLoads = createSelector(
  (state) => state.getIn(['submission', 'charges']).filter((item) => item.get('name') === 'value_load'),
  (charges) => charges,
);

const selectSubmissionItemsChargesProcessing = createSelector(
  (state) => state.getIn(['submission', 'charges']).filter((item) => item.get('name') === 'processing'),
  (charges) => charges,
);

const selectSubmissionItemsChargesGreetingCard = createSelector(
  (state) => state.getIn(['submission', 'charges']).filter((item) => item.get('name') === 'greeting_card'),
  (charges) => charges,
);

export const selectSubmissionTotalCartItemsCount = createSelector(
  (state) => state.getIn(['submission', 'totalCartItemsCount'], 0),
  (totalCartItems) => totalCartItems,
);

export const selectSubmissionPotentialReward = createSelector(
  (state) => state.getIn(['submission', 'potentialReward'], 0),
  (potentialReward) => potentialReward,
);

// TODO: reconsider charges structure
export const selectSubmissionValueLoadById = createSelector(
  [
    selectSubmissionItemsChargesValueLoads,
    (_, id) => id,
  ],
  (charges, id) => charges.find((item) => item.get('ids').includes(id))?.get('amount') || 0,
);

export const selectSubmissionGreetingCardFeeById = createSelector(
  [
    selectSubmissionItemsChargesGreetingCard,
    (_, id) => id,
  ],
  (charges, id) => charges.find((item) => item.get('ids').includes(id))?.get('amount') || 0,
);

// TODO: reconsider charges structure
export const selectSubmissionFeeById = createSelector(
  [
    selectSubmissionItemsChargesProcessing,
    (_, id) => id,
  ],
  (charges, id) => charges.find((item) => item.get('ids').includes(id))?.get('amount') || 0,
);

export const selectOpenLoopTerms = createSelector(
  (state) => state.getIn(['submission', 'terms']),
  (terms) => terms,
);

export const selectSubmissionFocusItemId = createSelector(
  (state) => state.getIn(['submission', 'focusItemId'], ''),
  (value) => value,
);
