/* eslint-disable max-len  */
import axiosService from './axiosService';

export function getAllCategories() {
  return axiosService.get('/api/shopping-experiences/catalogs/default/categories/standard-greeting-card/categories');
}

export function getGreetingCards({ network, productId, shoppingExperienceCategory, ...params }) {
  const url = `/api/shopping-experiences/categories/${shoppingExperienceCategory}/experiences/${productId}/networks/${network}/available-greeting-cards`;

  return axiosService.get(
    url,
    { params },
  );
}

export function getAllDesignCategories({ isPremium }) {
  const premiumUrl = isPremium ? '/all' : '';
  return axiosService.get(`/api/shopping-experiences/design-categories${premiumUrl}`);
}

export function getVirtualDesigns({ categoryId, isPremium, limit, page }) {
  const premiumUrl = isPremium ? '1' : '0';
  const url = categoryId
    ? `/api/shopping-experiences/categories/virtual/design-categories/${categoryId}/designs/${premiumUrl}`
    : `/api/shopping-experiences/categories/virtual/designs?step=${premiumUrl}&limit=${limit}&page=${page}`;
  return axiosService.get(url);
}

export function getVirtualDesign({ designId }) {
  return axiosService.get(`/api/shopping-experiences/categories/virtual/designs/${designId}`);
}

export function createGreetingCardDesign(source) {
  return axiosService.post('/api/images', { source });
}

export function getGreetingCardDetails({ experienceId }) {
  const url = `/api/shopping-experiences/catalogs/default/categories/standard-greeting-card/experiences/${experienceId}`;

  return axiosService.get(url);
}
