/**
 * `Link` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/link/}
 */
const MuiLink = Object.freeze({
  defaultProps: {
    underline: 'hover',
  },
  styleOverrides: {
    root: {
      cursor: 'pointer',
    },
  },
});

export default MuiLink;
