export const HOME_PAGE_ROUTE = '/';
export const PREDESIGN_ROUTE = '/build';
export const PREDESIGN_ROUTE_SEO = '/build-a-card';
export const CAC_ROUTE = '/build-create-a-card';

export const EDIT_ROUTE = '/edit-gift-card';
export const CART_ROUTE = '/shopping-cart';
export const GIFT_CARD_ROUTE = '-gift-card';
export const CUSTOMIZATION_ROUTE = 'customization';

export const FEATURED_PRODUCTS_ROUTE = '/featured-products';

export const VIRTUAL_GIFT_CARDS_ROUTE = '/virtual-gift-cards';
export const BRAND_CARDS_ROUTE = '/buy-gift-cards';

export const BASE_ROUTE = (productPath) => (productPath === CART_ROUTE ? EDIT_ROUTE : productPath);

export const BILLING_ROUTE = '/checkout/billing';
export const COMPLETE_ROUTE = '/checkout/complete';

/** @deprecated */
export const SIGN_IN_ROUTE = '/checkout/sign-in';
/** @deprecated */
export const SAVED_PAYMENTS_ROUTE = '/checkout/saved-payments';
/** @deprecated */
export const CART_REVIEW_ROUTE = '/cart';
/** @deprecated */
export const LOGIN_ROUTE = '/login';

export const cartUrlTypes = {
  cart: 'shopping-cart',
  review: 'review',
  type: 'gcShoppingCartType',
};
