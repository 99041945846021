import { fromJS } from 'immutable';
import { call, put, debounce, select } from 'redux-saga/effects';

import { setCustomerDataByKey } from 'src/app/actions/customerDataActions';
import { setVirtualDesignData } from 'src/app/actions/customizationVirtualActions';
import { setLoadingById } from 'src/app/actions/loadingActions';
import { selectCurrentShoppingExperienceId } from 'src/app/selectors/currentShoppingExperienceSelectors';
import { getVirtualDesign } from 'src/services/customizationApi';
import { CUSTOMER_DATA_FIELDS_DESIGN_URL,
  CUSTOMER_DATA_FIELDS_DESIGN_VIDEO_SOURCES,
  CUSTOMER_DATA_FIELDS_DESIGN_VIDEO_TRACKS } from 'src/utils/constants';
import { FETCH_VIRTUAL_DESIGN_LOADING, GET_VIRTUAL_DESIGN_SAGA } from 'utils/constants/customization';

function* makeFetch(action) {
  try {
    yield put(setLoadingById(FETCH_VIRTUAL_DESIGN_LOADING, true));
    const { payload: { designId } } = action;
    const currentShoppingExperienceId = yield select(selectCurrentShoppingExperienceId);
    const { data } = yield call(getVirtualDesign, {
      designId,
    });
    yield put(setVirtualDesignData(data));
    yield put(
      setCustomerDataByKey(
        currentShoppingExperienceId,
        CUSTOMER_DATA_FIELDS_DESIGN_URL,
        data.fallback_image_url,
      ),
    );
    yield put(
      setCustomerDataByKey(
        currentShoppingExperienceId,
        CUSTOMER_DATA_FIELDS_DESIGN_VIDEO_SOURCES,
        fromJS(data.video_sources),
      ),
    );
    yield put(
      setCustomerDataByKey(
        currentShoppingExperienceId,
        CUSTOMER_DATA_FIELDS_DESIGN_VIDEO_TRACKS,
        fromJS(data.video_tracks),
      ),
    );
  } catch (e) {
    // TODO: Add error handling
  } finally {
    yield put(setLoadingById(FETCH_VIRTUAL_DESIGN_LOADING, false));
  }
}

export default function* watchGetVirtualDesign() {
  yield debounce(500, GET_VIRTUAL_DESIGN_SAGA, makeFetch);
}
