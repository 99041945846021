import { createSelector } from 'reselect';

export const selectNextStepActionById = createSelector(
  [
    (state) => state.getIn(['steps', 'byId']),
    (_, currentStep) => currentStep,
  ],
  (state, currentStep) => state.getIn([currentStep, 'nextStepAction']),
);

export const selectNextStepTitleById = createSelector(
  [
    (state) => state.getIn(['steps', 'byId']),
    (_, currentStep) => currentStep,
  ],
  (state, currentStep) => state.getIn([currentStep, 'nextStepActionTitle']),
);

/**
 * Is not required for the moment.
 * Check its usage on Customization page.
 * @deprecated
 */
export const selectStepNameById = createSelector(
  [
    (state) => state.getIn(['steps', 'byId']),
    (_, currentStep) => currentStep,
  ],
  (state, currentStep) => state.getIn([currentStep, 'name']),
);

export const selectSteps = createSelector(
  (state) => state.getIn(['steps', 'allIds']),
  (state) => state,
);
