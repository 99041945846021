import { Map } from 'immutable';

import { SET_CURRENT_SHOPPING_EXPERIENCE_EXTERNAL_ID,
  SET_CURRENT_SHOPPING_EXPERIENCE_PRODUCT_ID,
  SET_CURRENT_SHOPPING_EXPERIENCE_CATEGORY,
  SET_CURRENT_SHOPPING_EXPERIENCE_CURRENT_STEP,
  CLEAR_CURRENT_SHOPPING_EXPERIENCE } from 'utils/constants';

const initialState = Map({
  category: '',
  currentStep: 0,
  externalId: '',
  productId: '',
  shoppingExperienceId: null,
});

/**
 * `currentShoppingExperience` reducer is about to store data of currently opened shopping experience.
 * This reducer helps to access a proper `shoppingExperience` and `customerData` without passing an id to selectors.
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_SHOPPING_EXPERIENCE_EXTERNAL_ID: {
      const { payload } = action;
      return state.set('externalId', payload);
    }
    case SET_CURRENT_SHOPPING_EXPERIENCE_PRODUCT_ID: {
      const { payload } = action;
      const category = state.get('category');

      const shoppingExperienceId = category ? `${category}/${payload}` : null;

      return state
        .set('productId', payload)
        .set('shoppingExperienceId', shoppingExperienceId);
    }
    case SET_CURRENT_SHOPPING_EXPERIENCE_CATEGORY: {
      const { payload } = action;
      const productId = state.get('productId');

      const shoppingExperienceId = productId ? `${payload}/${productId}` : null;

      return state
        .set('category', payload)
        .set('shoppingExperienceId', shoppingExperienceId);
    }
    case SET_CURRENT_SHOPPING_EXPERIENCE_CURRENT_STEP: {
      const { payload } = action;
      return state.set('currentStep', payload);
    }
    case CLEAR_CURRENT_SHOPPING_EXPERIENCE: {
      return initialState;
    }
    default:
      return state;
  }
}

export default reducer;
