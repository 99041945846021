import { FETCH_SHOPPING_EXPERIENCE_STANDARD_MESSAGES,
  SET_SHOPPING_EXPERIENCE_STANDARD_MESSAGES,
  CLEAR_SHOPPING_EXPERIENCE_STANDARD_MESSAGES } from 'utils/constants';
import { SET_AUTOCOMPLETE_ADDRESSES,
  FETCH_PHYSICAL_DELIVERY_METHODS_SAGA,
  SET_PHYSICAL_DELIVERY_METHODS,
  CLEAR_PHYSICAL_DELIVERY_METHODS,
  FETCH_AUTOCOMPLETE_ADDRESSES_SAGA } from 'utils/constants/billing';

export const fetchShoppingExperienceStandardMessages = () => ({
  type: FETCH_SHOPPING_EXPERIENCE_STANDARD_MESSAGES,
});

export const setShoppingExperienceStandardMessages = (payload) => ({
  payload,
  type: SET_SHOPPING_EXPERIENCE_STANDARD_MESSAGES,
});

export const clearShoppingExperienceStandardMessages = () => ({
  type: CLEAR_SHOPPING_EXPERIENCE_STANDARD_MESSAGES,
});

export const fetchPhysicalDeliveryMethodsSaga = () => ({
  type: FETCH_PHYSICAL_DELIVERY_METHODS_SAGA,
});

export const setPhysicalDeliveryMethods = (payload) => ({
  payload,
  type: SET_PHYSICAL_DELIVERY_METHODS,
});

export const clearPhysicalDeliveryMethods = () => ({
  type: CLEAR_PHYSICAL_DELIVERY_METHODS,
});

export const fetchAutocompleteAddressesSaga = (address) => ({
  address,
  type: FETCH_AUTOCOMPLETE_ADDRESSES_SAGA,
});

export const setAutocompleteAddresses = (payload) => ({
  payload,
  type: SET_AUTOCOMPLETE_ADDRESSES,
});
