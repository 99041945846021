import { SET_STEPS,
  NEXT_STEP,
  PREV_STEP,
  CLEAR_STEPS } from '../../utils/constants';

export const setSteps = (payload) => ({
  payload,
  type: SET_STEPS,
});

export const nextStepSaga = () => ({
  type: NEXT_STEP,
});

export const prevStepSaga = () => ({
  type: PREV_STEP,
});

export const clearSteps = () => ({
  type: CLEAR_STEPS,
});
