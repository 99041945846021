/* eslint-disable sort-keys */
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { loadState, saveState } from 'src/utils/storeUtils';

import { createReducer } from './reducers';
import { createSagaInjector, rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware({
  // As per the raven-js docs, redux-saga does not allow Errors to bubble up for Raven to see them,
  //  so pass them manually for logging
  onError: window.Raven ? window.Raven.captureException : undefined,
});

// enhancers can be passed in to createStore for applying
// middleware and all kinds of things
const enhancers = compose(
  // Note: Our saga middleware can swallow events and prevent them from getting to our storage enhancer,
  //  so they should always be processed after our storage persistence
  applyMiddleware(sagaMiddleware),
  typeof window === 'object' && typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined'
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f, /* eslint id-length: 0 */ // eslint exception
);

function configureStore(initialState) {
  const store = createStore(createReducer(), initialState, enhancers);

  store.asyncReducers = {};

  store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  store.subscribe(() => {
    saveState(store);
  });

  return store;
}

const persistedStore = loadState();
const appStore = configureStore(persistedStore);

// Provide global alias for logging the state tree
// (only if index.html is expecting it, it will create a null global variable)
if (typeof window._getSanitizedState !== 'undefined') {
  window._getSanitizedState = () => { /* eslint no-undef: 0 */
    const state = appStore.getState(); /* eslint no-underscore-dangle: 0 */
    // Clear out any sensitive data from the state for logging purposes here
    return state.toJS();
  };
}

export default appStore;
