import React, { useCallback } from 'react';

import { Box,
  Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { setCustomerDataByKey } from 'src/app/actions/customerDataActions';
import { selectCurrentShoppingExperienceId } from 'src/app/selectors/currentShoppingExperienceSelectors';
import { selectRecipientInfoEmail } from 'src/app/selectors/customerDataSelectors';
import InputEmailWithSuggestion from 'src/components/common/InputEmailWithSuggestion';
import { CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL } from 'utils/constants';
import { REG_EXP_EMAIL } from 'utils/validate/regExps';

function QuickBuyDeliveryMethod({ control }) {
  const dispatch = useDispatch();
  const email = useSelector(selectRecipientInfoEmail);
  const currentShoppingExperienceId = useSelector(
    selectCurrentShoppingExperienceId,
  );

  const emailController = useController({
    control,
    defaultValue: email,
    name: CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL,
    rules: {
      pattern: {
        message:
              'Enter the email address in the correct format, like name@example.com',
        value: REG_EXP_EMAIL,
      },
      required: "Enter the recipient's email address",
    },
  });

  const handleSetEmail = useCallback(
    (value) => {
      dispatch(
        setCustomerDataByKey(currentShoppingExperienceId, CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL, value),
      );
    },
    [currentShoppingExperienceId, dispatch],
  );

  return (
    <Box className="grid gap-4">
      <Typography
        aria-label="Delivery method"
        className="!text-left !text-[20px] !font-bold"
        fontSize={20}
        fontWeight={800}
        variant="body1"
      >
        Delivery method
      </Typography>
      <InputEmailWithSuggestion
        controller={emailController}
        fullWidth
        id={CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL}
        label="Recipient email address"
        onChange={handleSetEmail}
        outerValue={email}
      />
    </Box>
  );
}

QuickBuyDeliveryMethod.propTypes = {
  control: PropTypes.object.isRequired,
};

export default QuickBuyDeliveryMethod;
