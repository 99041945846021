import { Map, List } from 'immutable';

import { SET_STEPS, CLEAR_STEPS } from 'utils/constants';

const initialState = Map({
  allIds: List([]),
  byId: Map({}),
});

/**
 * `steps` reducer keeps data of path that user's required to follow.
 * It indicates an action that should be triggered on next step iteration.
 * It doesn't represent user's currrent step index, this information
 * should be stored in some other place (e.g. `currentShoppingExperienceReducer`).
 *
 * Data stored in `allIds` and `byId` approach:
 * * `allIds` stores all incoming ids in `category/id` shape;
 * * `byId` stores different customer data by id;
 *
 * @see {@link https://redux.js.org/usage/structuring-reducers/normalizing-state-shape} for more details
 * about normalized state.
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_STEPS: {
      const { payload } = action;

      const byId = payload.reduce((result, item, index) => (item && typeof item === 'object'
        ? result.set(index, Map(item))
        : result
      ), Map({}));

      const allIds = byId.keySeq().toList();

      return state
        .set('byId', byId)
        .set('allIds', allIds);
    }
    case CLEAR_STEPS:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
