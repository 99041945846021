import { all } from 'redux-saga/effects';

import watchCreateGreetingCardDesign from './customizationPhysicalSagas/createGreetingCardDesign';
import watchGetAllCategories from './customizationPhysicalSagas/fetchAllCategoriesSaga';
import watchGetGreetingCardDetails from './customizationPhysicalSagas/fetchGreetingCardDetails';
import watchGetStandardGreetingCard from './customizationPhysicalSagas/fetchStandardGreetingCards';
import watchGetVirtualDesign from './customizationVirtualSagas/fetchVirtualDesign';
import watchGetVirtualDesignCategories from './customizationVirtualSagas/fetchVirtualDesignCategories';
import watchGetVirtualDesigns from './customizationVirtualSagas/fetchVirtualDesigns';
import watchFetchProductDetailEvents from './googleAnalyticsSagas/productDetailEvents';
import watchFetchShoppingExperience from './shoppingExperienceSagas/fetchShoppingExperience';
import watchFetchMessages from './shoppingExperienceSagas/fetchShoppingExperienceStandardMessages';
import watchSendCustomerDataUploadedImage from './shoppingExperienceSagas/sendCustomerDataUploadedImage';
import watchNextStep from './stepsSagas/nextStep';
import watchAddSubmissionItem from './submissionSagas/addSubmissionItem';
import watchApplySubmissionPromoCode from './submissionSagas/applyPromoCode';
import watchCreateSubmissionCheckout from './submissionSagas/createSubmissionCheckout';
import watchEditSubmissionItem from './submissionSagas/editSubmissionItem';
// Modules
import { watchPageTransitions,
  watchPushTracking,
  watchWorksheetClose,
  watchWorksheetOpen,
  watchUpdateCart } from '../appSagas';

export function* rootSaga() {
  yield all([
    watchPageTransitions(),
    watchPushTracking(),
    watchWorksheetOpen(),
    watchWorksheetClose(),
    watchUpdateCart(),
    // New flow
    watchFetchShoppingExperience(),
    watchFetchMessages(),
    watchSendCustomerDataUploadedImage(),
    watchAddSubmissionItem(),
    watchCreateSubmissionCheckout(),
    watchEditSubmissionItem(),
    watchFetchProductDetailEvents(),
    watchNextStep(),
    watchGetAllCategories(),
    watchGetStandardGreetingCard(),
    watchGetVirtualDesignCategories(),
    watchGetVirtualDesigns(),
    watchGetVirtualDesign(),
    watchApplySubmissionPromoCode(),
    watchCreateGreetingCardDesign(),
    watchGetGreetingCardDetails(),
  ]);
}

/**
 * Function that
 * * applies root saga to the `saga` middleware;
 * * add `injectSaga` function to the `store` object,
 * that can be invoked lately and applies additional saga to the `saga` middleware;
 *
 * Make sure one `saga` is injected only once. Otherwise a `saga` may be injected twice.
 *
 * @param {Function} runSaga - `sagaMiddleware.run`
 * @param {Generator} root - root saga
 * @returns {(key: string, saga: Generator) => void}
 */
export function createSagaInjector(runSaga, root) {
  const injectedSagas = new Set();

  /**
   * @param {string} key - saga key identificator
   * @param {Generator} saga - saga to inject
   */
  const injectSaga = (key, saga) => {
    if (injectedSagas.has(key)) return;
    runSaga(saga);

    injectedSagas.add(key);
  };

  injectSaga('root', root);

  return injectSaga;
}
