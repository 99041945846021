import { Map, List } from 'immutable';

import { ADD_SHOPPING_EXPERIENCE,
  CLEAR_SHOPPING_EXPERIENCES,
  REMOVE_SHOPPING_EXPERIENCE,
  SET_SHOPPING_EXPERIENCE_DISPLAY_IMAGE } from '../../utils/constants';

const initialState = Map({
  allIds: List([]),
  byId: Map({}),
});

/**
 * `shoppingExperiences` reducer stores data of shopping experience (product).
 *
 * Data stored in `allIds` and `byId` approach:
 * * `allIds` stores all incoming ids in `category/id` shape;
 * * `byId` stores different shopping experience by id;
 *
 * @see {@link https://redux.js.org/usage/structuring-reducers/normalizing-state-shape} for more details
 * about normalized state.
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SHOPPING_EXPERIENCE: {
      const { payload, shoppingExperienceId } = action;

      const allIds = state.get('allIds').toSet().add(shoppingExperienceId);

      return state
        .setIn(['byId', shoppingExperienceId], Map(payload))
        .set('allIds', allIds.toList());
    }
    case REMOVE_SHOPPING_EXPERIENCE: {
      const { shoppingExperienceId } = action;
      const { byId } = state;

      delete byId[shoppingExperienceId];

      return state
        .removeIn(['byId', shoppingExperienceId])
        .update('allIds', (items) => items
          .filter((item) => item !== shoppingExperienceId));
    }
    case SET_SHOPPING_EXPERIENCE_DISPLAY_IMAGE: {
      const { displayImage, shoppingExperienceId } = action;

      return state
        .setIn(['byId', shoppingExperienceId, 'display_image'], displayImage);
    }
    case CLEAR_SHOPPING_EXPERIENCES:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
