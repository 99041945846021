import axiosService from './axiosService';

/**
 * Object of the deliveries item
 * @typedef Delivery
 * @property {string[]} ids
 * @property {string} method
 * @property {Object} options
 */

/**
 * A common object of a submission.
 * It varies depending on experience category
 * @typedef Submission
 * @property {Delivery[]} deliveries
 * @property {Object[]} items
 * @property {Object} items[].customer_data
 * @property {Object} items[].experience
 * @property {string} items[].experience.category
 * @property {string} items[].experience.id
 * @property {string} items[].external_id
 * @property {number} items[].quantity
 * @property {string} riskified_beacon_token
 */

/**
 * Api call to create a new submission.
 * @param {Submission} data - submission data
 * @returns {Promise}
 */
export const createSubmission = (data) => axiosService.post('/api/submissions', data);

/**
 * Api call to add a new item to the existed submission.
 * This call doesn't require existed submission items to be in `data` property.
 * @param {string} submissionId
 * @param {Submission} data - submission data
 * @returns {Promise}
 */
export const addSubmissionItem = (submissionId, data) => (
  axiosService.put(`/api/submissions/${submissionId}/addItems`, data)
);

/**
 * Api call to update an item data of the existed submission.
 * This call doesn't require all submission items to be in `data` property.
 * @param {string} submissionId
 * @param {Submission} data - submission data
 * @returns {Promise}
 */
export const updateSubmissionItem = (submissionId, data) => (
  axiosService.put(`/api/submissions/${submissionId}/updateItems`, data)
);

/**
 * Api call to delete an item from the existed submission.
 * This call requires the same data structure, as others, but with the items to be deleted from the submission.
 * @param {string} submissionId
 * @param {Submission} data - submission data
 * @returns {Promise}
 */
export const removeSubmissionItem = (submissionId, data) => (
  axiosService.delete(`/api/submissions/${submissionId}/removeItems`, { data })
);

/**
 * Returns plenty object of a submission.
 * @param {string} submissionId - id of a submission that's required to retreive
 * @returns {Promise}
 */
export const fetchSubmission = (submissionId) => (
  axiosService.get(`/api/submissions/${submissionId}`)
);

/**
 * Creates a checkout for a current submission and returns checkout information.
 * @param {string} submissionId - id of a submission that's required to retreive
 * @returns {Promise}
 */
export const createSubmissionCheckout = (submissionId) => (
  axiosService.post(`/api/submissions/${submissionId}/checkout`)
);

/**
 * Updates checkout data.
 * @param {string} submissionId
 * @param {string} checkoutId
 * @param {Object} data
 * @returns {Promise}
 */
export const updateSubmissionCheckout = (submissionId, checkoutId, data) => (
  axiosService.post(`/api/submissions/${submissionId}/update/${checkoutId}`, data)
);

/**
 * Apply a promo code for a current submission.
 * After promo code applied, submission should be refetched once more.
 * @param {string} submissionId
 * @param {string} code
 * @returns {Promise}
 */
export const applySubmissionPromoCode = (submissionId, code) => (
  axiosService.put(`/api/submissions/${submissionId}/promotion`, { code })
);

/**
 * Get physical delivery methods.
 * @param {string} submissionId
 * @returns {Promise}
 */
export const fetchPhysicalDeliveryMethods = (submissionId) => (
  axiosService.get(`/api/submissions/${submissionId}/physical-delivery-methods`)
);

/**
 * Update the current submission deliveries.
 * @param {string} submissionId
 * @param {Delivery[]} data
 * @returns {Promise}
 */
export const updateSubmissionDeliveries = (submissionId, data) => (
  axiosService.put(`/api/submissions/${submissionId}/deliveries`, data)
);

/**
 * Validate address on submit
 * @param {object} address
 * @returns {Promise}
 */
export const validateDeliveryAddress = (address) => (
  axiosService.post('api/delivery-address-validations', address)
);

/**
 * Address autocomplete API
 * @param {string} address
 * @returns {Promise}
  */
export const fetchAddressSuggestions = (address) => (
  axiosService.get(`https://us-autocomplete-pro.api.smartystreets.com/lookup?key=1582900916163630&search=${address}`)
);

/**
 * Fetch product terms and conditions
 * @param {string} submissionId
 * @returns {Promise}
 */
export const getProductTerms = (submissionId) => axiosService.get(`/api/submissions/${submissionId}/terms`);

/**
 * Fetch submission potential reward points
 * @param {string} submissionId
 * @param {integer} orderId
 * @returns {Promise}
 */
export const getSubmissionPotentialRewards = (submissionId) => (
  axiosService.get(`/api/submissions/${submissionId}/potential-rewards`)
);
