/**
 * @deprecated
 */
export const windowGetVariable = (key) => (
  typeof window !== 'undefined' && typeof window[key] !== 'undefined' ? window[key] : undefined
);

/**
 * @deprecated
 */
export const windowSetVariable = (key, value) => {
  if (window) {
    window[key] = value;
  }
};
