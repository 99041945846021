import { createSelector } from 'reselect';

import { selectCurrentShoppingExperienceId } from './currentShoppingExperienceSelectors';

const selectShoppingExperiencesState = (state) => state.get('shoppingExperiences');

export const selectShoppingExperience = createSelector(
  [
    selectShoppingExperiencesState,
    selectCurrentShoppingExperienceId,
  ],
  (shoppingExperiences, shoppingExperienceId) => shoppingExperiences
    .getIn(['byId', shoppingExperienceId])
    ?.toObject(),
);

export const selectShoppingExperienceById = createSelector(
  [
    selectShoppingExperiencesState,
    (_, shoppingExperienceId) => shoppingExperienceId,
  ],
  (shoppingExperiences, shoppingExperienceId) => shoppingExperiences
    .getIn(['byId', shoppingExperienceId])
    ?.toObject(),
);

export const hasShoppingExperienceById = createSelector(
  [
    selectShoppingExperiencesState,
    (_, shoppingExperienceId) => shoppingExperienceId,
  ],
  (state, shoppingExperienceId) => state.get('allIds').includes(shoppingExperienceId),
);

export const selectShoppingExperienceLoadAmountImagesById = createSelector(
  [
    selectShoppingExperiencesState,
    (_, shoppingExperienceId) => shoppingExperienceId,
  ],
  (shoppingExperiences, shoppingExperienceId) => shoppingExperiences
    .getIn(['byId', shoppingExperienceId, 'load_amount_images']),
);

export const selectShoppingExperienceMinLoad = createSelector(
  [
    selectShoppingExperiencesState,
    selectCurrentShoppingExperienceId,
  ],
  (shoppingExperiences, shoppingExperienceId) => (
    shoppingExperiences.getIn(['byId', shoppingExperienceId, 'min_load'])
  ),
);

export const selectShoppingExperienceMaxLoad = createSelector(
  [
    selectShoppingExperiencesState,
    selectCurrentShoppingExperienceId,
  ],
  (shoppingExperiences, shoppingExperienceId) => (
    shoppingExperiences.getIn(['byId', shoppingExperienceId, 'max_load'])
  ),
);

export const selectShoppingExperienceLoadAmounts = createSelector(
  [
    selectShoppingExperiencesState,
    selectCurrentShoppingExperienceId,
  ],
  (shoppingExperiences, shoppingExperienceId) => (
    shoppingExperiences.getIn(['byId', shoppingExperienceId, 'load_amounts'])
  ),
);

export const selectShoppingExperienceLoadAmountImages = createSelector(
  [
    selectShoppingExperiencesState,
    selectCurrentShoppingExperienceId,
  ],
  (shoppingExperiences, shoppingExperienceId) => (
    shoppingExperiences.getIn(['byId', shoppingExperienceId, 'load_amount_images'])
  ),
);

export const selectShoppingExperienceLoadLimitType = createSelector(
  [
    selectShoppingExperiencesState,
    selectCurrentShoppingExperienceId,
  ],
  (shoppingExperiences, shoppingExperienceId) => (
    shoppingExperiences.getIn(['byId', shoppingExperienceId, 'load_limit_type'])
  ),
);
