import { Cookies } from 'react-cookie';

export const getCookieValue = (paramName) => {
  const cookies = new Cookies();

  return cookies.get(paramName);
};

export const setCookies = (key, value) => {
  const cookies = new Cookies();
  cookies.set(key, value);
};

export const deleteCookie = (key) => {
  const cookies = new Cookies();
  cookies.remove(key)
};
