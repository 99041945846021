/**
 * `Typography` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/typography/}
 */
const MuiTypography = Object.freeze({
  defaultProps: {
    color: 'secondary.main',
    variant: 'body1',
    variantMapping: {
      h7: 'h2',
    },

  },
  styleOverrides: {},
});

export default MuiTypography;
