/* eslint-disable max-len */

export const ADD_SUBMISSION_ITEM_SAGA = '@@gcfront/ADD_SUBMISSION_ITEM_SAGA';
export const EDIT_SUBMISSION_ITEM_SAGA = '@@gcfront/EDIT_SUBMISSION_ITEM_SAGA';
export const SET_SUBMISSION_ERRORS = '@@gcfront/SET_SUBMISSION_ERRORS';
export const FETCH_CURRENT_SUBMISSION_SAGA = '@@gcfront/FETCH_CURRENT_SUBMISSION_SAGA';
export const SET_CURRENT_SUBMISSION = '@@gcfront/SET_CURRENT_SUBMISSION';
export const SET_CURRENT_SUBMISSION_ID = '@@gcfront/SET_CURRENT_SUBMISSION_ID';
export const SET_CURRENT_SUBMISSION_ITEMS = '@@gcfront/SET_CURRENT_SUBMISSION_ITEMS';
export const DUPLICATE_CURRENT_SUBMISSION_ITEM_SAGA = '@@gcfront/DUPLICATE_CURRENT_SUBMISSION_ITEM_SAGA';
export const REMOVE_CURRENT_SUBMISSION_ITEM_SAGA = '@@gcfront/REMOVE_CURRENT_SUBMISSION_ITEM_SAGA';
export const EDIT_CURRENT_SUBMISSION_ITEM_SAGA = '@@gcfront/EDIT_CURRENT_SUBMISSION_ITEM_SAGA';
export const CREATE_SUBMISSION_CHECKOUT_SAGA = '@@gcfront/CREATE_SUBMISSION_CHECKOUT_SAGA';
export const SET_SUBMISSION_CHECKOUT_ID = '@@gcfront/SET_SUBMISSION_CHECKOUT_ID';
export const SET_SUBMISSION_FOCUS_ITEM_ID = '@@gcfront/SET_SUBMISSION_FOCUS_ITEM_ID';
export const CLEAR_SUBMISSION_FOCUS_ITEM_ID = '@@gcfront/CLEAR_SUBMISSION_FOCUS_ITEM_ID';

export const SET_CURRENT_SUBMISSION_DELIVERIES = '@@gcfront/SET_CURRENT_SUBMISSION_DELIVERIES';
export const SET_SUBMISSION_DELIVERY_METHOD_BY_KEY = '@@gcfront/SET_SUBMISSION_DELIVERY_METHOD_BY_KEY';
export const SET_SUBMISSION_DELIVERY_OPTIONS_DATA_BY_KEY = '@@gcfront/SET_SUBMISSION_DELIVERY_OPTIONS_DATA_BY_KEY';
export const UPDATE_SUBMISSION_DELIVERIES_SAGA = '@@gcfront/UPDATE_SUBMISSION_DELIVERIES_SAGA';
export const APPLY_SUBMISSION_PROMO_CODE_SAGA = '@@gcfront/APPLY_SUBMISSION_PROMO_CODE_SAGA';

export const FETCH_SUBMISSION_TERMS_SAGA = '@@gcfront/FETCH_SUBMISSION_TERMS_SAGA';
export const SET_SUBMISSION_TERMS = '@@gcfront/SET_SUBMISSION_TERMS';
export const FETCH_SUBMISSION_POTENTIAL_REWARDS_SAGA = '@@gcfront/FETCH_SUBMISSION_POTENTIAL_REWARDS_SAGA';
export const SET_SUBMISSION_POTENTIAL_REWARDS = '@@gcfront/SET_SUBMISSION_POTENTIAL_REWARDS';

// Submission loading Ids
export const DUPLICATE_CURRENT_SUBMISSION_ITEM_LOADING = 'DUPLICATE_CURRENT_SUBMISSION_ITEM_LOADING';
export const REMOVE_CURRENT_SUBMISSION_ITEM_LOADING = 'REMOVE_CURRENT_SUBMISSION_ITEM_LOADING';
export const FETCH_CURRENT_SUBMISSION_LOADING = 'FETCH_CURRENT_SUBMISSION_LOADING';
export const SAVE_SUBMISSION_ITEM_LOADING = 'SAVE_SUBMISSION_ITEM_LOADING';
/** @deprecated */
export const CREATE_SUBMISSION_CHECKOUT_LOADING = 'CREATE_SUBMISSION_CHECKOUT_LOADING';
export const APPLY_SUBMISSION_PROMO_CODE_LOADING = 'APPLY_SUBMISSION_PROMO_CODE_LOADING';
export const UPDATE_SUBMISSION_DELIVERIES_LOADING = 'UPDATE_SUBMISSION_DELIVERIES_LOADING';
export const FETCH_SUBMISSION_POTENTIAL_REWARDS = 'FETCH_SUBMISSION_POTENTIAL_REWARDS';

// Submission delivery option fields
export const SUBMISSION_DELIVERY_OPTION_ADDRESS1 = 'address1';
export const SUBMISSION_DELIVERY_OPTION_ADDRESS2 = 'address2';
export const SUBMISSION_DELIVERY_OPTION_CITY = 'city';
export const SUBMISSION_DELIVERY_OPTION_FULLNAME = 'fullName';
/**
 * Keep `SUBMISSION_DELIVERY_OPTION_POSTALCODE` as `postal_code` to align with `/delivery-address-validations` endpoint response
 */
export const SUBMISSION_DELIVERY_OPTION_POSTALCODE = 'postal_code';
export const SUBMISSION_DELIVERY_OPTION_REGION = 'region';
export const SUBMISSION_DELIVERY_OPTION_SIGNATUREREQUIRED = 'signatureRequired';
export const SUBMISSION_DELIVERY_OPTION_SHIPPING_METHOD = 'shippingMethod';
export const SUBMISSION_DELIVERY_FORM_ADDRESS_VALIDATION = 'formAddressValidation';
