import { call, put, select, takeLatest } from 'redux-saga/effects';

import { setCustomerDataByKey } from 'src/app/actions/customerDataActions';
import { selectCurrentShoppingExperienceId } from 'src/app/selectors/currentShoppingExperienceSelectors';
import { selectCustomerDataCarrier } from 'src/app/selectors/customerDataSelectors';
import { getGreetingCardDetails } from 'src/services/customizationApi';
import { DEFAULT_GREETING_CARD_SIZE,
  STANDARD_CARRIER } from 'src/utils/constants/customization';
import { CUSTOMER_DATA_FIELDS_CARRIER, GET_GREETING_CARD_DETAILS_SAGA } from 'utils/constants';

function* makeFetch(action) {
  try {
    const { payload } = action;

    const customerDataCarrier = yield select(selectCustomerDataCarrier);
    const currentShoppingExperienceId = yield select(selectCurrentShoppingExperienceId);

    const { data } = yield call(getGreetingCardDetails, payload);

    let updates = {
      customer_data: {
        ...customerDataCarrier.customer_data,
        size: DEFAULT_GREETING_CARD_SIZE,
      },
      experience: {
        ...customerDataCarrier.experience,
        panel1: data.panel1,
        panel3: data.panel3,
      },
    };

    if (data.id === STANDARD_CARRIER.id) {
      updates = {
        ...updates,
        experience: {
          ...updates.experience,
          panel1: STANDARD_CARRIER.panel1,
          panel3: data.panel3,
        },
      };
    }

    yield put(setCustomerDataByKey(currentShoppingExperienceId, CUSTOMER_DATA_FIELDS_CARRIER, updates));
  } catch (e) {
    // TODO: Add error handling
  } finally {
    // TODO: Add loading
  }
}

export default function* watchGetGreetingCardDetails() {
  yield takeLatest(GET_GREETING_CARD_DETAILS_SAGA, makeFetch);
}
