import { Map, List } from 'immutable';

import { SET_AUTOCOMPLETE_ADDRESSES,
  SET_PHYSICAL_DELIVERY_METHODS,
  CLEAR_PHYSICAL_DELIVERY_METHODS } from 'src/utils/constants/billing';
import { SET_SHOPPING_EXPERIENCE_STANDARD_MESSAGES,
  CLEAR_SHOPPING_EXPERIENCE_STANDARD_MESSAGES,
  SET_CATEGORIES_DATA } from 'utils/constants';

const initialState = Map({
  categories: List([]),
  physicalDeliveryMethods: List([]),
  standardMessages: List([]),
  suggestions: List([]),
});

/**
 * `dictionaries` reducer stores data of different collections.
 * * `physicalDeliveryMethods` - a list of delivery methods,
 * that User may choose on Billing - Shipping Information section;
 * * `standardMessages` - a list of possible standard messages that User may choose on OL Physical PDP;
 * * `suggestions` - a list of addresses, that User may choose on Billing - Shipping Information section;
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHOPPING_EXPERIENCE_STANDARD_MESSAGES: {
      const { payload } = action;

      return state.set('standardMessages', List([...payload]));
    }
    case CLEAR_SHOPPING_EXPERIENCE_STANDARD_MESSAGES:
      return state.set('standardMessages', List([]));
    case SET_AUTOCOMPLETE_ADDRESSES: {
      const { payload } = action;

      return state.set('suggestions', List([...payload]));
    }
    case SET_PHYSICAL_DELIVERY_METHODS: {
      const { payload } = action;
      const physicalDeliveryMethods = List(payload.map(({ estimation, method, name, rate }) => (
        Map({
          estimation: Map(estimation),
          method,
          name,
          rate: rate.description,
        })
      )));

      return state.set('physicalDeliveryMethods', physicalDeliveryMethods);
    }
    case CLEAR_PHYSICAL_DELIVERY_METHODS:
      return state.set('physicalDeliveryMethods', List([]));
    case SET_CATEGORIES_DATA: {
      const { payload } = action;

      return state.set('categories', List([...payload]));
    }
    default:
      return state;
  }
}

export default reducer;
