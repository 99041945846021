/**
 * `ToggleButtonGroup` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/button/}
 */
const MuiToggleButtonGroup = Object.freeze({
  defaultProps: {
    color: 'secondary',
    fullWidth: true,
    size: 'large',
  },
  styleOverrides: {
    grouped: {
      '&:not(:first-of-type)': {
        borderLeftColor: 'inherit',
      },
    },
    root: {
      borderRadius: 1000,
    },
  },
});

export default MuiToggleButtonGroup;
