import { SET_LOADING_BY_ID } from 'utils/constants';

/**
 * @param {string} id
 * @param {boolean} isLoading
 * @returns {{ id: string, isLoading: boolean, type: "SET_LOADING_BY_ID" }}
 */
export const setLoadingById = (id, isLoading) => ({
  id,
  isLoading,
  type: SET_LOADING_BY_ID,
});
