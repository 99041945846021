import { Map, List } from 'immutable';

import { SET_VIRTUAL_DESIGN_CATEGORIES_DATA,
  SET_VIRTUAL_DESIGNS_DATA,
  SET_VIRTUAL_DESIGN_DATA,
  SET_VIRTUAL_DESIGN_CATEGORY_DATA,
  SET_VIRTUAL_DESIGN_PAGE_DATA } from 'src/utils/constants/customization';

function getSkeltonSlides(length) {
  return Array
    .from({ length })
    .map((_, index) => ({ id: index, name: '', thumbnail: { url: '' } }));
}

// TODO: categories mist be in dictionaries reducer
const initialState = Map({
  categories: List([]),
  category: '',
  design: '',
  designs: Map({
    cards: List(getSkeltonSlides(8)),
    limit: 8,
    page: 1,
    pages: 0,
  }),
});

/**
 * `customization` reducer is about to store data for of Customization Virtual page.
 * * `designCategories` - a list of designCategories of cards;
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_VIRTUAL_DESIGN_CATEGORIES_DATA: {
      const { payload } = action;
      return state.set('categories', List([...payload]));
    }
    case SET_VIRTUAL_DESIGNS_DATA: {
      const { payload: { _embedded, limit, page, pages } } = action;
      return state.set('designs', Map({
        cards: List(_embedded.items),
        limit,
        page,
        pages,
      }));
    }
    case SET_VIRTUAL_DESIGN_DATA: {
      const { payload } = action;
      return state.setIn(['design'], payload);
    }
    case SET_VIRTUAL_DESIGN_PAGE_DATA: {
      const { payload } = action;
      return state.setIn(['designs', 'page'], payload);
    }
    case SET_VIRTUAL_DESIGN_CATEGORY_DATA: {
      const { payload } = action;
      return state.set('category', payload);
    }
    default:
      return state;
  }
}

export default reducer;
