/* eslint-disable class-methods-use-this */
import get from 'lodash-es/get';

import { CATEGORY_VIRTUAL } from 'src/brands/card_purchase/cart/item/experienceCategories';
import {
  CUSTOMER_DATA_FIELDS_DELIVERYDATE,
  CUSTOMER_DATA_FIELDS_MESSAGE,
  CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL,
  CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME,
  NEXT_STEP_ACTION_CUSTOMIZATION_VIRTUAL,
  NEXT_STEP_ACTION_ADD_CART,
  CURRENT_STEP_NAME_CREATE_PDP,
  CURRENT_STEP_NAME_CREATE_CUSTOMIZATION,
  CUSTOMER_DATA_FIELDS_CARRIER,
  CUSTOMER_DATA_FIELDS_DENOMINATION,
  CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE,
} from 'utils/constants';

import BaseInterface from '../Base';

class VirtualCardInterface extends BaseInterface {
  constructor(category, product, additionalDescription) {
    super(category, product, additionalDescription);

    this.isMultipleCategoryExperience = Boolean(this.product?._embedded?.merchant?._links?.brand_experience);
  }

  validateFieldsOrder = [
    CUSTOMER_DATA_FIELDS_DENOMINATION,
    CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME,
    CUSTOMER_DATA_FIELDS_MESSAGE,
    CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL,
    CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE,
  ];

  backendValidationConfig = {
    buildPayload: (formData) => ({
      items: [{
        customer_data: {
          denomination: formData[CUSTOMER_DATA_FIELDS_DENOMINATION],
          message: formData[CUSTOMER_DATA_FIELDS_MESSAGE],
          name: formData[CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME],
          ...(formData[CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL] && {
            recipient_email:
              formData[CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL],
          }),
          ...(formData[CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE] && {
            recipient_phone_number:
              formData[CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE],
          }),
        },
        experience: {
          category: CATEGORY_VIRTUAL,
          id: this.product.id,
        },
      }],
    }),
    parseError: (error) => ({
      [CUSTOMER_DATA_FIELDS_DENOMINATION]:
                  get(error, 'items.children[0].children.customer_data.children.denomination.errors[0]'),
      [CUSTOMER_DATA_FIELDS_MESSAGE]:
                  get(error, 'items.children[0].children.customer_data.children.message.errors[0]'),
      [CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL]:
                  get(error, 'items.children[0].children.customer_data.children.recipient_email.errors[0]'),
      [CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME]:
                  get(error, 'items.children[0].children.customer_data.children.name.errors[0]'),
      [CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE]:
                  get(error, 'items.children[0].children.customer_data.children.recipient_phone_number.errors[0]'),
    }),
  };

  /**
   * Method to generate an object of cart item to create new submission.
   * Current method is valid for `brand` category.
   * @param {Object} customerData - customer data object should include denomination, quantity, etc.
   * @param {string} externalId - unique id of a currently proceeded shopping experience and customer data
   * @returns {CartItem[]}
   */
  getCartItemData(customerData, externalId) {
    const { denomination, design, message, name, quantity } = customerData;
    const id = this.getProductId();
    const category = this.getProductCategory();

    return {
      customer_data: {
        denomination,
        design,
        message,
        name,
        ...customerData.recipient_email && { recipient_email: customerData.recipient_email },
        ...customerData.recipient_phone_number && { recipient_phone_number: customerData.recipient_phone_number },
        ...customerData.design_type && { design_type: customerData.design_type },
        ...customerData.delivery_date && { delivery_date: customerData.delivery_date },
      },
      experience: { category, id },
      external_id: externalId,
      quantity,
    };
  }

  getRawCustomerData() {
    const baseData = this.rawCustomerData;
    return {
      ...baseData,
      [CUSTOMER_DATA_FIELDS_CARRIER]: {},
      [CUSTOMER_DATA_FIELDS_DELIVERYDATE]: null,
      [CUSTOMER_DATA_FIELDS_MESSAGE]: '', // recipient massage
      [CUSTOMER_DATA_FIELDS_RECIPIENT_EMAIL]: '',
      [CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME]: '', // recipient name
      [CUSTOMER_DATA_FIELDS_RECIPIENT_PHONE]: '',
    };
  }

  getSteps() {
    return [
      {
        name: CURRENT_STEP_NAME_CREATE_PDP,
        nextStepAction: NEXT_STEP_ACTION_CUSTOMIZATION_VIRTUAL,
        nextStepActionTitle: 'Next step',
      },
      {
        name: CURRENT_STEP_NAME_CREATE_CUSTOMIZATION,
        nextStepAction: NEXT_STEP_ACTION_ADD_CART,
        nextStepActionTitle: 'Add to cart',
      },
    ];
  }
}

export default VirtualCardInterface;
