import { all, call, put, takeEvery } from 'redux-saga/effects';

import { addCustomerData } from 'src/app/actions/customerDataActions';
import { setLoadingById } from 'src/app/actions/loadingActions';
import { addShoppingExperience } from 'src/app/actions/shoppingExperiencesActions';
import { setCurrentSubmission } from 'src/app/actions/submissionActions';
import { setCurrentSubmissionDeliveries } from 'src/app/actions/submissionDeliveriesActions';
import { setCurrentSubmissionItems } from 'src/app/actions/submissionItemsActions';
import { FEATURED_PRODUCTS_ROUTE } from 'src/routing/routes';
import { fetchSubmission } from 'src/services/submissionsApi';
import { FETCH_CURRENT_SUBMISSION_LOADING, FETCH_CURRENT_SUBMISSION_SAGA } from 'utils/constants/submission';

/**
 * Helper function generator to set up incoming submission items.
 * This one works differently then `addShoppingExperienceItem` helper,
 * due to each submission item should be stored by unique id - `externalId`.
 * `addShoppingExperienceItem` stores data by combination of `experienceId` and `experienceCategory`.
 */
export function* addSubmissionItemToStore(data) {
  const {
    _embedded,
    customer_data: customerData,
    external_id: externalId,
    quantity,
  } = data;

  yield put(addCustomerData(externalId, { ...customerData, quantity }));
  yield put(addShoppingExperience(externalId, _embedded.shopping_experience));
}

/**
 * Saga to fetch current submission.
 * @param {Object} action - redux action
 * @param {string} action.type - FETCH_CURRENT_SUBMISSION_SAGA
 * @param {string} action.submissionId - submission id
 */
export function* makeFetch(action) {
  try {
    yield put(setLoadingById(FETCH_CURRENT_SUBMISSION_LOADING, true));
    const { submissionId } = action;
    const { data } = yield call(fetchSubmission, submissionId);

    yield put(setCurrentSubmission(data));
    yield put(setCurrentSubmissionItems(data));
    yield put(setCurrentSubmissionDeliveries(data));

    const { items } = data._embedded;

    // Redirect is made on BE side. For safety reason it should be here.
    if (items.length === 0) {
      window.location = FEATURED_PRODUCTS_ROUTE;
    }

    yield all(items.map((item) => call(addSubmissionItemToStore, item)));
  } catch (error) {
    // TODO: add error handling
  } finally {
    yield put(setLoadingById(FETCH_CURRENT_SUBMISSION_LOADING, false));
  }
}

export default function* watchFetch() {
  yield takeEvery(FETCH_CURRENT_SUBMISSION_SAGA, makeFetch);
}
