import { CATEGORY_BRAND,
  CATEGORY_CAC,
  CATEGORY_PREDESIGN,
  CATEGORY_VIRTUAL } from 'src/brands/card_purchase/cart/item/experienceCategories';

import BaseInterface from './Base';
import BrandCardInterface from './interfaces/BrandCardInterface';
import CACInterface from './interfaces/CACInterface';
import PredesignInterface from './interfaces/PredesignInterface';
import VirtualCardInterface from './interfaces/VirtualCardInterface';

const productFactory = (category, product, network, additionalDescription) => {
  switch (category) {
    case CATEGORY_VIRTUAL:
      return new VirtualCardInterface(category, product, additionalDescription);
    case CATEGORY_BRAND:
      return new BrandCardInterface(category, product, additionalDescription);
    case CATEGORY_PREDESIGN:
      return new PredesignInterface(category, product, network, additionalDescription);
    case CATEGORY_CAC:
      return new CACInterface(category, product, network, additionalDescription);
    default:
      return new BaseInterface(category, product, additionalDescription);
  }
};

export default productFactory;
