export const REG_EXP_MUST_CONTAIN_SPACE = /^(.*\s+.*)+$/;

export const REG_EXP_EMAIL = /\S+@\S+\.\S+/;
export const REG_EXP_PHONE = /^\d{10}$/;

export const REG_EXP_NO_SPECIAL_CHARACTERS = /^[A-Za-z0-9 ]*$/;

export const REG_EXP_FULL_NAME = /^[A-Za-z0-9\.'-]+\s+[A-Za-z0-9\s\.'-]+$/;

export const REG_EXP_EMAIL_CHARACTERS = /^[A-Za-z0-9 !@#$%&'*+-/=?^_`{|}~]*$/;

export const REG_EXP_CARRIER_MESSAGE = /[\x09-~]+/;

export const REG_EXP_CARRIER_FROM = /[A-Za-z0-9\s.\-]+/;

export const REG_EXP_DECIMAL = /^[0-9]*(\.[0-9]{0,2})?$/;

export const REG_EXP_CVV = /^[0-9]{3,4}$/;

export const US_ZIP_CODE = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const REG_EXP_QUANTITY = /(^[0-9]+$|^$)|Backspace|ArrowUp|ArrowDown|ArrowLeft|ArrowRight|Tab/;

export const REG_EXP_SHIPPING_ADDRESS = /^.{0,50}$/;
