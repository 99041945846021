import { List, Map } from 'immutable';

import { SET_CURRENT_SUBMISSION_ITEMS } from 'utils/constants/submission';

/**
 * Submission item object stored in redux store.
 * @typedef {Object} SubmissionItem
 * @property {string} experienceId
 * @property {string} experienceCategory
 * @property {string} itemId
 */

const initialState = Map({
  allIds: List([]),
  byId: Map({}),
});

/**
 * `submissionItems` reducer stores a list of items for current submission.
 *
 * Data stored in `allIds` and `byId` approach:
 * * `allIds` stores all incoming ids in `category/id` shape;
 * * `byId` stores different shopping experience by id;
 *
 * @see {@link https://redux.js.org/usage/structuring-reducers/normalizing-state-shape} for more details
 * about normalized state.
 */
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_SUBMISSION_ITEMS: {
      const { payload } = action;
      const { items } = payload._embedded;

      const byId = items.reduce((result, { experience, external_id: eId, id }) => result
        .setIn([eId, 'experienceId'], experience?.id)
        .setIn([eId, 'experienceCategory'], experience?.category)
        .setIn([eId, 'itemId'], id), Map({}));

      const allIds = byId.keySeq().toList();

      return state
        .set('byId', byId)
        .set('allIds', allIds);
    }
    default:
      return state;
  }
}

export default reducer;
