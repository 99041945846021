import Colors from '../../palette/Colors';

/**
 * `InputLabel` component theme overrides. `InputLabel` is a part of `TextField` composition.
 *
 * @see {@link https://mui.com/material-ui/api/input-label/}
 */
const MuiInputLabel = Object.freeze({
  styleOverrides: {
    outlined: {
      '&.MuiFormLabel-filled.MuiFormLabel-colorPrimary': {
        color: Colors.PurpleMain,
      },
      '&.MuiFormLabel-filled.MuiFormLabel-colorSecondary': {
        color: Colors.CyanBlueDark,
      },
      '&.MuiInputLabel-shrink': {
        maxWidth: 'calc(133% - 76px)',
        transform: 'translate(28px, -10px) scale(0.75)',
      },
      backgroundColor: 'white',
      color: Colors.CyanBlueMain,
      maxWidth: 'calc(100% - 48px)',
      transform: 'translate(24px, 20px) scale(1)',
    },
  },
});

export default MuiInputLabel;
