import axiosService from './axiosService';

const validateApi = (data) => axiosService.post(
  '/api/carts/validations',
  data,
);

export function setBackendValidationErrors(errorsMap = {}, form, validateFieldsOrder) {
  const sortedArray = validateFieldsOrder.map((key) => [key, errorsMap[key]]);
  let isFirstError = false;
  sortedArray.forEach(([field, error]) => {
    if (error) {
      if (!isFirstError) {
        isFirstError = true;
        form.setError(field, { message: error, type: 'backendValidation' }, { shouldFocus: isFirstError });
      }
      form.setError(field, { message: error, type: 'backendValidation' });
    }
  });
}

export default function useBackendValidation({ form, productInterface }) {
  const handleBackendValidation = (formData) => (
    validateApi(productInterface?.backendValidationConfig.buildPayload(formData))
      .catch((error) => {
        const { errors } = error.response.data;

        setBackendValidationErrors(
          productInterface?.backendValidationConfig.parseError(errors.children),
          form,
          productInterface?.validateFieldsOrder,
        );

        return Promise.reject(errors);
      })
  );

  return {
    handleBackendValidation,
  };
}
