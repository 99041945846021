import { createSelector } from 'reselect';

export const selectVirtualDesignCategories = createSelector(
  (state) => state
    .getIn(['customizationVirtual', 'categories'])
    .toArray(),
  (categories) => categories,
);

export const selectVirtualDesignCategory = createSelector(
  (state) => state.getIn(['customizationVirtual', 'category']),
  (state) => state,
);

export const selectVirtualDesigns = createSelector(
  [
    (state) => state.getIn(['customizationVirtual', 'designs', 'cards']),
    (_, isLoggedIn) => isLoggedIn,
  ],
  (cards, isLoggedIn) => cards
    .unshift(...(!isLoggedIn ? [{ isPremium: true }] : []))
    .unshift({ isUpload: true })
    .toArray(),
);

export const selectVirtualDesign = createSelector(
  (state) => state
    .getIn(['customizationVirtual', 'design']),
  (state) => state,
);

export const selectVirtualDesignsPages = createSelector(
  (state) => state.getIn(['customizationVirtual', 'designs', 'pages']),
  (state) => state,
);

export const selectVirtualDesignsLimit = createSelector(
  (state) => state.getIn(['customizationVirtual', 'designs', 'limit']),
  (state) => state,
);
