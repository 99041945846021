import { Map } from 'immutable';

const initialState = Map({
  action: '',
  timestamp: Date.now(),
});

/**
 * Small reducer to track when the last action the user performed was.
 * For every action that happens in the app, refresh the `timestamp` and `action` type.
 * It serves such purposes:
 * * When we rehydrate from `localstorage` we can diff it with the current time
 * to see how much time has elapsed since the last action the user performed.
 * * Check the last action type to decide whether store should be persisted in `localstorage`
 */
function reducer(state = initialState, action) {
  return state
    .set('action', action.type)
    .set('timestamp', Date.now());
}

export default reducer;
