/**
 * `Stepper` component theme overrides/
 *
 * @see {@link https://mui.com/material-ui/api/stepper/}
 */
const MuiStepper = Object.freeze({
  styleOverrides: {
    root: {
      columnGap: 20,
    },
  },
});

export default MuiStepper;
