import { useMemo } from 'react';

import { useController, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { addSubmissionItemSaga, editSubmissionItemSaga } from 'src/app/actions/submissionActions';
import { BILLING_ROUTE } from 'src/routing/routes';
import productFactory from 'src/services/productFactory';
import useBackendValidation from 'src/services/useBackendValidation';
import { CUSTOMER_DATA_FIELDS_MESSAGE,
  CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME } from 'utils/constants';
import { getFormControllerError, getFirstError } from 'utils/form';
import fullNameValidator from 'utils/validate/fullNameValidator';

export default function useQuickBuyForm({
  cartQuantity,
  denomination,
  experienceCategory,
  experienceNetwork,
  shoppingExperience,
}) {
  const dispatch = useDispatch();
  const productInterface = useMemo(() => productFactory(
    experienceCategory,
    shoppingExperience,
    experienceNetwork,
  ), [experienceCategory, shoppingExperience, experienceNetwork]);

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const { clearErrors, control, handleSubmit, setFocus } = form;

  const fullNameController = useController({
    control,
    name: CUSTOMER_DATA_FIELDS_RECIPIENT_FULL_NAME,
    rules: {
      required: "Enter the recipient's full name",
      validate: {
        fullNameValidator,
      },
    },
  });

  const messageController = useController({
    control,
    name: CUSTOMER_DATA_FIELDS_MESSAGE,
  });

  const messageError = getFormControllerError(messageController);

  const { handleBackendValidation } = useBackendValidation({ form, productInterface });

  const resetFormAndRedirect = () => {
    clearErrors();
    window.location = BILLING_ROUTE;
    window.closeQuickBuyModal(); // closeQuickBuyModal is created in WordPress
  };

  const onSubmitValid = async (data) => {
    try {
      const formData = { ...data, denomination };

      if (productInterface.backendValidationConfig) {
        await handleBackendValidation(formData);
      }

      if (cartQuantity && Number(cartQuantity) > 0) {
        dispatch(editSubmissionItemSaga(resetFormAndRedirect));
      } else {
        dispatch(addSubmissionItemSaga(resetFormAndRedirect));
      }
    } catch (error) {
      // Do nothing. The backend validation error is handled inside their dedicated helper
    }
  };

  const onSubmitInvalid = (errors) => {
    const firstError = getFirstError(errors, productInterface.validateFieldsOrder);

    if (firstError) {
      setFocus(firstError);
    }
  };

  const onSubmit = handleSubmit(
    onSubmitValid,
    onSubmitInvalid,
  );

  return {
    form,
    fullNameController,
    messageController,
    messageError,
    onSubmit,
  };
}
