import Colors from '../../palette/Colors';
import fonts from '../../typography/fonts';

/**
 * `StepIcon` component theme overrides/
 *
 * @see {@link https://mui.com/material-ui/api/step-icon/}
 */
const MuiStepIcon = Object.freeze({
  styleOverrides: {
    root: {
      '&.Mui-active, &.Mui-completed': {
        '&>.MuiStepIcon-text': {
          fill: Colors.White,
        },
        color: Colors.PurpleDark,
      },
      color: Colors.GreyMain,
      fontSize: 32,

    },
    text: {
      ...fonts.h3,
      fill: Colors.GreyDark,
      fontSize: 24,
      transform: 'translateY(-3px)',
    },
  },
});

export default MuiStepIcon;
