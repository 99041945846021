import { fromJS, Map } from 'immutable';

import ls from 'src/services/localStorage';

import {
  ADD_CUSTOMER_DATA,
  ADD_CUSTOMIZATION_PHYSICAL_OPTIONS,
  REMOVE_CUSTOMER_DATA,
  CLEAR_CUSTOMER_DATA,
  SET_CUSTOMER_DATA_BY_KEY,
  SET_CUSTOMER_DATA_UPLOADED_IMAGE,
  SET_CURRENT_SHOPPING_EXPERIENCE_PRODUCT_ID,
  SET_CURRENT_SHOPPING_EXPERIENCE_CATEGORY,
  SET_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY,
  SET_CURRENT_SHOPPING_EXPERIENCE_CURRENT_STEP,
  CLEAR_CUSTOMIZATION_PHYSICAL_OPTIONS_DATA,
  LS_KEY_STORE,
} from './constants';
import { SET_CURRENT_SUBMISSION, SET_CURRENT_SUBMISSION_ITEMS } from './constants/submission';

const persistDataActions = [
  ADD_CUSTOMER_DATA,
  ADD_CUSTOMIZATION_PHYSICAL_OPTIONS,
  REMOVE_CUSTOMER_DATA,
  CLEAR_CUSTOMER_DATA,
  CLEAR_CUSTOMIZATION_PHYSICAL_OPTIONS_DATA,
  SET_CUSTOMER_DATA_BY_KEY,
  SET_CUSTOMER_DATA_UPLOADED_IMAGE,
  SET_CURRENT_SHOPPING_EXPERIENCE_PRODUCT_ID,
  SET_CURRENT_SHOPPING_EXPERIENCE_CATEGORY,
  SET_CUSTOMIZATION_PHYSICAL_OPTIONS_BY_KEY,
  SET_CURRENT_SHOPPING_EXPERIENCE_CURRENT_STEP,
];

export const saveState = (store) => {
  const currentState = store.getState();
  const lastAction = currentState.getIn(['lastAction', 'action']);

  if (persistDataActions.includes(lastAction)) {
    const currentShoppingExperience = currentState.get('currentShoppingExperience').toJS();
    const customerData = currentState.get('customerData').toJS();
    const customizationPhysicalOptions = currentState.get('customizationPhysicalOptions').toJS();

    ls.add([LS_KEY_STORE, 'currentShoppingExperience'], currentShoppingExperience);
    ls.add([LS_KEY_STORE, 'customerData'], customerData);
    ls.add([LS_KEY_STORE, 'customizationPhysicalOptions'], customizationPhysicalOptions);
  }

  if (lastAction === SET_CURRENT_SUBMISSION) {
    const submission = currentState.get('submission').toJS();

    ls.add([LS_KEY_STORE, 'submission'], submission);
  }

  if (lastAction === SET_CURRENT_SUBMISSION_ITEMS) {
    const submissionItems = currentState.get('submissionItems').toJS();

    ls.add([LS_KEY_STORE, 'submissionItems'], submissionItems);
  }
};

export const loadState = () => {
  const storeFromLS = ls.get(LS_KEY_STORE);

  if (!storeFromLS) return undefined;

  const { currentShoppingExperience, customerData, customizationPhysicalOptions } = storeFromLS;

  return Map({
    currentShoppingExperience: fromJS(currentShoppingExperience),
    customerData: fromJS(customerData),
    customizationPhysicalOptions: fromJS(customizationPhysicalOptions),
  });
};
