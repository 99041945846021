/* eslint-disable class-methods-use-this */
import { GIFT_CARD_ROUTE } from 'src/routing/routes';
import { CURRENT_STEP_NAME_CREATE_PDP,
  CUSTOMER_DATA_FIELDS_CARRIER,
  CUSTOMER_DATA_FIELDS_DENOMINATION,
  CUSTOMER_DATA_FIELDS_QUANTITY,
  NEXT_STEP_ACTION_ADD_CART } from 'utils/constants';

/**
 * Cart item object schema, that's required to create a new submission
 * @typedef {Object} CartItem
 * @property {Object} customer_data - object includes customer data fields, except quantity
 * @property {Object} experience - object includes shopping experience id and category only
 * @property {number} quantity - number of products
 * @property {string} external_id - unique id of a currently proceeded shopping experience and customer data
 */

/**
 * Base interface with common fields for products of different categories.
 */
class BaseInterface {
  backendValidationConfig = null;

  isMultipleCategoryExperience = false;

  isMultipleNetworkExperience = false;

  validateFieldsOrder = [];

  rawCustomerData = {
    [CUSTOMER_DATA_FIELDS_CARRIER]: {},
    [CUSTOMER_DATA_FIELDS_DENOMINATION]: 0,
    [CUSTOMER_DATA_FIELDS_QUANTITY]: 1,
  };

  showBreadcrumbs = true;

  constructor(category, product, additionalDescription) {
    this.product = product;
    this.category = category;
    this.additionalDescription = additionalDescription;
  }

  getProductImageSrc() {
    return this.product.display_image?.url;
  }

  getProductId() {
    return this.product.id;
  }

  getProductCategory() {
    return this.category;
  }

  getProductName() {
    return this.product.display_name;
  }

  isOLVirtualCard() {
    const regex = /(visa|mastercard)/i;
    return regex.test(this.getProductName());
  }

  getProductDisplayTitle() {
    if (!this.isOLVirtualCard()) {
      return `${this.getProductName()} Gift Card`;
    }
    return this.getProductName();
  }

  getPotentialRewards() {
    return parseFloat(this.product.potential_rewards);
  }

  getProductDisclaimer() {
    return this.product.disclaimer;
  }

  getProductAltText() {
    return this.product?.alt_text;
  }

  getIsOutOfStock() {
    return this.product?.is_out_of_stock ?? false;
  }

  getAdditionalDescription() {
    return this.additionalDescription;
  }

  getRawCustomerData() {
    return this.rawCustomerData;
  }

  /**
   * Method to generate an object of cart item to create new submission.
   * Current method is valid for `brand` category.
   * @param {Object} customerData - customer data object should include denomination, quantity, etc.
   * @param {string} externalId - unique id of a currently proceeded shopping experience and customer data
   * @returns {CartItem[]}
   */
  getCartItemData({ denomination, quantity }, externalId) {
    const id = this.getProductId();
    const category = this.getProductCategory();

    return {
      customer_data: { denomination },
      experience: { category, id },
      external_id: externalId,
      quantity,
    };
  }

  /**
   * Method to generate an object of pathname and search params to open a product URL.
   * Current method is valid for `brand` and `virtual` categories.
   * @param {string} page - if {page} has data it will produce the URL like {slug}/{page}
   * @returns {Object}
   */
  getProductUrl(page) {
    const experienceMerchantSlug = this.product._embedded?.merchant?.slug;
    if (!experienceMerchantSlug) {
      throw new Error('Invalid experience name value.');
    }
    const slug = `/${experienceMerchantSlug}${GIFT_CARD_ROUTE}`;
    const pathname = page ? `${slug}/${page}` : slug;
    return { pathname };
  }

  /**
   * Method to generate an array of objects with information about shopping experience steps
   * @example <caption>Example for the `brand` catogory</caption>
   * [{ name: "PDP", nextStepAction: "NEXT_STEP_ACTION_ADD_CART", nextStepActionTitle: "Add to cart"}]
   * @example <caption>Example for the `virtual` catogory</caption>
   * [{ name: "PDP", nextStepAction: "NEXT_STEP_ACTION_CUSTOMIZATION_VIRTUAL", nextStepActionTitle: "Next step"},
   *   { name: "Customization", nextStepAction: "NEXT_STEP_ACTION_ADD_CART", nextStepActionTitle: "Add to cart"}]
   * @returns {Object[]}
   */
  getSteps() {
    return [
      {
        name: CURRENT_STEP_NAME_CREATE_PDP,
        nextStepAction: NEXT_STEP_ACTION_ADD_CART,
        nextStepActionTitle: 'Add to cart',
      },
    ];
  }
}

export default BaseInterface;
