/**
 * `FormLabel` component theme overrides.
 *
 * @see {@link https://mui.com/material-ui/api/form-control-label//}
 */
const MuiFormControlLabel = Object.freeze({
  styleOverrides: {
    asterisk: {
      display: 'none',
    },
  },
});

export default MuiFormControlLabel;
