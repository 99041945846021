import { ADD_CUSTOMER_DATA,
  REMOVE_CUSTOMER_DATA,
  CLEAR_CUSTOMER_DATA,
  SET_CUSTOMER_DATA_BY_KEY,
  SEND_CUSTOMER_DATA_UPLOADED_IMAGE } from '../../utils/constants';

export const addCustomerData = (customerDataId, customerData) => ({
  customerData,
  customerDataId,
  type: ADD_CUSTOMER_DATA,
});

/**
 * TODO: remove if not required.
 */
export const removeCustomerData = (customerDataId, key) => ({
  customerDataId,
  key,
  type: REMOVE_CUSTOMER_DATA,
});

export const clearCustomerData = () => ({
  type: CLEAR_CUSTOMER_DATA,
});

export const setCustomerDataByKey = (customerDataId, key, value) => ({
  customerDataId,
  key,
  type: SET_CUSTOMER_DATA_BY_KEY,
  value,
});

export const sendCustomerDataUploadedImage = (source) => ({
  source,
  type: SEND_CUSTOMER_DATA_UPLOADED_IMAGE,
});
