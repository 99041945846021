/* eslint-disable sort-keys */
import { themeWithBreakpoints } from '../breakpoints';

/**
 * Object of the `TypographyOptions` (specifically fonts), used by MUI theme.
 *
 * Depending on the breakpoint (media query):
 * - `h2`, `subtitle1` and `caption2` have different css properties;
 * - `h5`and `body1` shouldn't have different css properties;
 * @type TypographyOptions
 */
const fontFamily = '"SofiaPro", "sofia-pro", "Arial", "sans-serif"';
const fonts = {
  body1: {
    fontFamily,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '18.4px',
    paddingBottom: 0,
    paddingTop: 0,
  },
  button: {
    fontFamily,
  },
  caption1: {
    fontFamily,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '19.73px',
  },
  caption2: {
    fontFamily,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: -0.5,
    lineHeight: '10px',
    [themeWithBreakpoints.breakpoints.down('lg')]: {
      letterSpacing: 0.495,
      lineHeight: '14px',
    },
  },
  h1: {
    fontFamily,
    fontSize: 64,
    fontWeight: 800,
    letterSpacing: -1.4,
    lineHeight: '74px',
    paddingBottom: 0,
    paddingTop: 0,
  },
  h2: {
    fontFamily,
    fontSize: 60,
    fontWeight: 900,
    letterSpacing: -0.75,
    lineHeight: '60px',
    paddingBottom: 0,
    paddingTop: 0,
    [themeWithBreakpoints.breakpoints.down('lg')]: {
      fontSize: 32,
      fontWeight: 900,
      letterSpacing: -0.43,
      lineHeight: '32px',
    },
  },
  h3: {
    fontFamily,
    fontSize: 30,
    fontWeight: 900,
    lineHeight: '34.5px',
    paddingBottom: 0,
    paddingTop: 0,
  },
  h4: {
    fontFamily,
    fontSize: 26,
    fontWeight: 800,
    lineHeight: '30px',
    paddingBottom: 0,
    paddingTop: 0,
  },
  h5: {
    fontFamily,
    fontSize: 24,
    fontWeight: 900,
    letterSpacing: -0.75,
    lineHeight: '26px',
  },
  h6: {
    fontFamily,
    fontSize: 22,
    fontWeight: 800,
    letterSpacing: -0.5,
    lineHeight: '22px',
  },
  h7: {
    fontFamily,
    fontSize: 20,
    fontWeight: 800,
    letterSpacing: -0.75,
    lineHeight: '16px',
    paddingBottom: 0,
    paddingTop: 0,
  },
  subtitle1: {
    fontFamily,
    fontSize: 18,
    fontWeight: 900,
    letterSpacing: -0.25,
    lineHeight: '22px',
    [themeWithBreakpoints.breakpoints.down('lg')]: {
      fontSize: 14,
      fontWeight: 800,
      lineHeight: '16px',
    },
  },
  subtitle2: {
    fontFamily,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '16.1px',
  },
  subtitle3: {
    fontFamily,
    fontSize: 20,
    fontWeight: 800,
    lineHeight: '24px',
  },
};

export default fonts;
